import React from "react";
import "./ContentLoader.css";

const ContentLoader = () => {
  return (
    <div className="ContentLoader">
      <svg
        width="109"
        height="109"
        viewBox="0 0 109 109"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M54.5 109C47.1446 109 40.0022 107.563 33.2855 104.721C26.7923 101.975 20.9697 98.0361 15.9668 93.0332C10.9639 88.0303 7.02539 82.2077 4.2791 75.7145C1.43701 68.9979 0 61.8554 0 54.5C0 52.3817 1.71377 50.668 3.83203 50.668C5.95029 50.668 7.66406 52.3817 7.66406 54.5C7.66406 60.8229 8.89883 66.9541 11.3471 72.7341C13.7102 78.3118 17.0845 83.3254 21.3849 87.6258C25.6853 91.9262 30.6988 95.3111 36.2766 97.6636C42.0459 100.101 48.1772 101.336 54.5 101.336C60.8229 101.336 66.9541 100.101 72.7341 97.6529C78.3118 95.2898 83.3254 91.9155 87.6258 87.6151C91.9262 83.3147 95.3111 78.3012 97.6636 72.7234C100.101 66.9541 101.336 60.8229 101.336 54.5C101.336 48.1771 100.101 42.0459 97.6529 36.2659C95.2979 30.7016 91.8894 25.6449 87.6151 21.3742C83.3492 17.0942 78.2913 13.685 72.7234 11.3364C66.9541 8.89883 60.8229 7.66406 54.5 7.66406C52.3817 7.66406 50.668 5.95029 50.668 3.83203C50.668 1.71377 52.3817 0 54.5 0C61.8554 0 68.9979 1.43701 75.7146 4.2791C82.2077 7.02539 88.0303 10.9639 93.0332 15.9668C98.0361 20.9697 101.964 26.8029 104.71 33.2855C107.552 40.0021 108.989 47.1446 108.989 54.5C108.989 61.8554 107.552 68.9979 104.71 75.7145C101.975 82.2077 98.0361 88.0303 93.0332 93.0332C88.0303 98.0361 82.1971 101.964 75.7146 104.71C68.9979 107.563 61.8554 109 54.5 109V109Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default ContentLoader;
