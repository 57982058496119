import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { postAnnouncement } from "store/actions/announcement";
import { errorsNotification } from "store/actions/notification";

export default function Announcement() {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    if (message.length > 4) {
      dispatch(postAnnouncement(message, () => setMessage("")));
    } else {
      dispatch(errorsNotification("Message must have at least 4 letters"));
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Page header */}
      <div className="">
        {/* Left: Title */}
        <div className="mb-4 sm:mb-0">
          <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
            Create new Announcement ✨
          </h1>
          <section className="p-5 bg-white mt-10 w-full">
            <h3 className="text-xl leading-snug text-gray-800 font-bold mb-5">
              Announcement message
            </h3>
            {/* Form */}
            <textarea
              id="feedback"
              className="form-textarea w-full focus:border-gray-300"
              rows="7"
              placeholder="New announcement..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <div className="flex justify-end mt-6">
              <button
                onClick={handleSubmit}
                className="btn bg-blue-500 hover:bg-blue-600 text-white ml-3"
              >
                Publish announcement
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
