import React, { useMemo, useState } from "react";
import "./ConnectedPlatform.scss";
import deleteIcon from "icons/deleteIcon.svg";

import { useDispatch } from "react-redux";
import { deleteRatingPlatform } from "store/actions/auth";
import { typeObject } from "helpers/getObjectByType";
import { DeletePopup } from "components/DeletePopup/DeletePopup";

const ConnectedPlatform = ({ data, isPopular }) => {
  const dispatch = useDispatch();
  const [deletePopup, setDeletePopup] = useState(false);

  const handleDelete = () => {
    dispatch(deleteRatingPlatform(data?.type, () => setDeletePopup(false)));
  };

  const formatRating = useMemo(() => {
    if (!data || !data?.rating) {
      return "N/A";
    }

    if (data.type === "booking") {
      return data.rating.toFixed(1) * 2;
    }

    return data.rating.toFixed(1);
  }, [data]);

  if (data.type === "trustpilot") return null;

  return (
    <>
      <DeletePopup
        deletePopup={deletePopup}
        setDeletePopup={setDeletePopup}
        onSubmit={handleDelete}
        title={`Disconnect ${typeObject[data?.type]?.title} platform`}
        description={`Are you sure you want to disconnect ${
          typeObject[data?.type]?.title
        } platform from your
        account?`}
      />
      <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
        <div className="px-5 py-5">
          <header className="flex justify-between items-start mb-2"></header>
          <div className="flex items-center mb-4">
            <img
              src={typeObject[data?.type]?.image}
              className="max-h-10"
              alt="Platform"
              style={{ maxWidth: 110 }}
            />
            <div>
              <h2 className="text-xl font-semibold text-gray-800 ml-3">
                {typeObject[data?.type]?.title}
              </h2>
              <div
                className="text-sm ml-3 platform-name h-4"
                style={{ lineHeight: 1.2 }}
              >
                {data?.name}
              </div>
            </div>
            {isPopular && (
              <div className="font-bold ml-auto rounded-full text-xs text-center px-2.5 py-0.5 bg-green-100 text-green-600">
                Popular
              </div>
            )}
          </div>
          <div className="font-medium mb-2">{data?.title}</div>
          <div className="flex items-start">
            <div className="text-sm text-gray-600 mr-2">
              <span className="font-bold text-xl">{formatRating}</span> /{" "}
              {data?.type === "booking" ? "10" : "5"} based on{" "}
              <span className="font-bold">{data?.ratingCount}</span> reviews
            </div>
          </div>
          <div className="flex mt-5 justify-between">
            {data?.downloading ? (
              <a
                href={data?.url}
                target="_blank"
                rel="noreferrer"
                className="flex items-center mr-1 justify-center text-center cursor-pointer text-green-500 bg-white transition-all hover:border-green-500 border font-medium w-full border-gray-200 border-solid rounded py-1.5 px-5"
              >
                <span className="w-3 h-3 bg-green-500 block rounded-full animate-pulse mr-2"></span>
                <p>Downloading reviews...</p>
              </a>
            ) : (
              <a
                href={data?.url}
                target="_blank"
                rel="noreferrer"
                className={`text-center cursor-pointer text-green-500 bg-white transition-all hover:bg-green-500 hover:text-white border font-medium block ${
                  data.type !== "trustbucket" ? "w-10/12" : "w-full"
                } mr-1 border-gray-200 border-solid rounded py-1.5 px-5`}
              >
                ✓ Connected
              </a>
            )}
            {data.type !== "trustbucket" && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setDeletePopup(true);
                }}
                aria-controls="delete-platform-modal"
                className="border flex justify-center items-center transition-all font-medium w-2/12 border-gray-200 hover:border-red-700 border-solid rounded sm:px-1.5 py-1.5 lg:px-3"
              >
                <img src={deleteIcon} alt="delete" />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectedPlatform;
