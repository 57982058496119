import AddTagModal from "components/AddTagModal/AddTagModal";
import { typeObject } from "helpers/getObjectByType";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteTag } from "store/actions/tags";
import "./ManageFilters.scss";

export default function ManageFilters({
  user,
  type,
  allTypes,
  changeType,
  toggleRatingsStars,
  activeRatings,
  filtersActive,
  setFiltersActive,
  activeTags,
  toggleTag,
  businessId,
  tags,
}) {
  const dispatch = useDispatch();

  const [addTagModal, setAddTagModal] = useState(false);

  return (
    <>
      <AddTagModal
        tagModalOpen={addTagModal}
        setTagModalOpen={setAddTagModal}
        businessId={businessId}
      />
      <div
        className={
          "sm:w-3/12 h-max sticky top-20 lg:block ManageFilters" +
          (filtersActive ? " active" : "")
        }
        onClick={() => setFiltersActive(false)}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="bg-white shadow-lg lg:rounded-lg lg:border py-6 pt-6 px-9 lg:pt-4 lg:py-4 lg:px-5 border-gray-200 h-full transition-all manage-content"
        >
          <div
            className="lg:hidden block manage-line"
            onClick={() => setFiltersActive(false)}
          ></div>
          <div className="lg:hidden block text-black font-bold text-center text-lg mb-9">
            Filter reviews
          </div>
          <div className="lg:block flex justify-around flex-wrap">
            <div className="lg:mr-0 mr-12">
              <div className="font-semibold mb-3 text-black">
                Select source(s)
              </div>

              <ul className="mb-5 block">
                <li className="py-1 mb-1">
                  <label className="flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="form-checkbox w-6 h-6"
                      readOnly
                      checked={type?.length === allTypes?.length}
                      onChange={() => changeType(allTypes)}
                    />
                    <span className="text-sm font-medium ml-2">
                      All sources
                    </span>
                  </label>
                </li>
                {user?.selectedCompany?.ratings?.map(
                  (item, index) =>
                    item.type !== "overall" &&
                    item.type !== "trustpilot" && (
                      <li className="py-1 mb-1" key={index}>
                        <label className="flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            className="form-checkbox w-6 h-6"
                            readOnly
                            checked={
                              type && type.some((el) => el === item.type)
                            }
                            onChange={() => changeType(item.type)}
                          />
                          <span className="text-sm font-medium ml-2">
                            {typeObject[item.type]?.title}
                          </span>
                        </label>
                      </li>
                    )
                )}
              </ul>
            </div>
            <div>
              <div className="font-semibold mb-3 text-black">
                Sort By Rating
              </div>
              <ul>
                {[1, 2, 3, 4, 5].map((item, index) => (
                  <li className="py-1 mb-1" key={index}>
                    <label
                      className="flex items-center cursor-pointer"
                      key={index}
                    >
                      <input
                        type="checkbox"
                        className="form-checkbox w-6 h-6"
                        readOnly
                        checked={activeRatings?.find((el) => el === item)}
                        onClick={() => toggleRatingsStars(item)}
                      />
                      <span className="text-sm font-medium ml-2">
                        {item}-star{item > 1 && "s"}
                      </span>
                      {/* <RatingStars rating={item} /> */}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-5 w-full pb-3">
              <div className="font-semibold mb-3 text-black">Filter by Tag</div>
              <ul>
                {tags &&
                  tags.length > 0 &&
                  tags.map((item, index) => (
                    <li
                      className="py-1 mb-1 flex items-center justify-between"
                      key={index}
                    >
                      <label
                        className="flex items-center cursor-pointer"
                        key={index}
                      >
                        <input
                          type="checkbox"
                          className="form-checkbox w-6 h-6"
                          readOnly
                          checked={activeTags?.find((el) => el === item.id)}
                          onClick={() => toggleTag(item.id)}
                        />
                        <span className="text-sm font-medium ml-2">
                          {item.name}
                        </span>
                      </label>
                      <div className="flex items-center">
                        <div
                          className="text-red-600 text-xs underline cursor-pointer"
                          onClick={() => dispatch(deleteTag(item?.id))}
                        >
                          Delete
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setAddTagModal(true);
                }}
                className="text-blue-600 underline mt-4 text-sm cursor-pointer"
              >
                Add new tag
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
