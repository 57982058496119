import IframeModal from "components/IframeModal/IframeModal";
import ConfiguratorHeader from "components/WidgetConfigurator/ConfiguratorHeader";
import ConfiguratorSidebar from "components/WidgetConfigurator/ConfiguratorSidebar";
import WidgetHtmlBadge from "components/WidgetConfigurator/WidgetTypes/Badge/WidgetHtmlBadge/WidgetHtmlBagde";
import WidgetButton from "components/WidgetConfigurator/WidgetTypes/Button/WidgetButton";
import WidgetGrid from "components/WidgetConfigurator/WidgetTypes/Grid/WidgetGrid";
import WidgetList from "components/WidgetConfigurator/WidgetTypes/List/WidgetList";
// import WidgetCard from "components/WidgetConfigurator/WidgetCard";
import WidgetSlider from "components/WidgetConfigurator/WidgetTypes/Slider/WidgetSlider";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { postWidget } from "store/actions/widget";

const WidgetConfigurator = () => {
  const dispatch = useDispatch();

  const { type, id } = useParams();
  const allPredefined = useSelector((state) => state.widget.predefinedWidgets);
  const widgetIframe = useSelector((state) => state.widget.widgetIframe);

  const [widgetStyle, setWidgetStyle] = useState(
    allPredefined[type]?.find((el) => el.widgetType === id)
  );
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    setWidgetStyle(allPredefined[type].find((el) => el.widgetType === id));
    // eslint-disable-next-line
  }, [type, id]);

  useEffect(() => {
    if (widgetStyle?.colorSchema === "drop-shadow") {
      setWidgetStyle((p) => ({
        ...p,
        backgroundColor: "#fffffa",
        dropShadow: "show",
        borderColor: "#efefef",
        borderWeight: 0,
        borderRadius: 4,
        nameColor: "#000009",
        reviewColor: "#00000a",
        outerBackgroundColor: "#fdffff",
        outerTextColor: "#000009",
      }));
    } else if (widgetStyle?.colorSchema === "trustbucket") {
      setWidgetStyle((p) => ({
        ...p,
        nameColor: "#1E293B",
        nameSize: 24,
        reviewColor: "#1F2937",
        reviewSize: 15,
        backgroundColor: "#fffffe",
        borderColor: "#D6DAE4",
        borderWeight: 1,
        padding: 24,
        borderRadius: 8,
        dropShadow: "hide",
        backgroundOpacity: 1,
        mouseAnimation: "yes",
        outerBackgroundColor: "#fffffe",
        outerTextColor: "#000009",
      }));
    } else if (widgetStyle?.colorSchema === "trustbucket-testimonial") {
      setWidgetStyle((p) => ({
        ...p,
        textAlign: "left",
        nameColor: "#1E293B",
        nameSize: 16,
        reviewColor: "#1F2937",
        reviewSize: 15,
        backgroundColor: "#fffffe",
        borderColor: "#D6DAE4",
        borderWeight: 1,
        padding: 24,
        borderRadius: 8,
        dropShadow: "hide",
        backgroundOpacity: 1,
        mouseAnimation: "yes",
        outerBackgroundColor: "#fffffe",
        outerTextColor: "#000009",
      }));
    } else if (widgetStyle?.colorSchema === "light-background") {
      setWidgetStyle((p) => ({
        ...p,
        backgroundColor: "#efefef",
        borderColor: "#efefef",
        borderWeight: 0,
        dropShadow: "hide",
        borderRadius: 4,
        nameColor: "#000009",
        reviewColor: "#00000a",
        outerBackgroundColor: "#fdffff",
        outerTextColor: "#000009",
      }));
    } else if (widgetStyle?.colorSchema === "light-background-border") {
      setWidgetStyle((p) => ({
        ...p,
        backgroundColor: "#efefef",
        borderColor: "#ccc",
        borderWeight: 2,
        dropShadow: "hide",
        borderRadius: 4,
        nameColor: "#000009",
        reviewColor: "#00000a",
        outerBackgroundColor: "#fdffff",
        outerTextColor: "#000009",
      }));
    } else if (widgetStyle?.colorSchema === "light-border") {
      setWidgetStyle((p) => ({
        ...p,
        backgroundColor: "#fffffa",
        borderColor: "#ccc",
        borderWeight: 2,
        borderRadius: 4,
        dropShadow: "hide",
        nameColor: "#000009",
        reviewColor: "#00000a",
        outerBackgroundColor: "#fdffff",
        outerTextColor: "#000009",
      }));
    } else if (widgetStyle?.colorSchema === "light-clean") {
      setWidgetStyle((p) => ({
        ...p,
        backgroundColor: "#fafafa",
        borderColor: "#d6dae4",
        borderWeight: 1,
        dropShadow: "hide",
        borderRadius: 0,
        nameColor: "#000009",
        reviewColor: "#00000a",
        outerBackgroundColor: "#fdffff",
        outerTextColor: "#000009",
      }));
    } else if (widgetStyle?.colorSchema === "light-contrast") {
      setWidgetStyle((p) => ({
        ...p,
        backgroundColor: "#222",
        borderColor: "#222",
        borderWeight: 0,
        dropShadow: "hide",
        borderRadius: 4,
        nameColor: "#fffffa",
        reviewColor: "#fffffa",
        outerBackgroundColor: "#fdffff",
        outerTextColor: "#000009",
      }));
    } else if (widgetStyle?.colorSchema === "minimal") {
      setWidgetStyle((p) => ({
        ...p,
        backgroundColor: "#fffffa",
        borderColor: "#efefef",
        borderWeight: 0,
        dropShadow: "hide",
        borderRadius: 4,
        nameColor: "#000009",
        reviewColor: "#000009",
        outerBackgroundColor: "#fdffff",
        outerTextColor: "#000009",
      }));
    } else if (widgetStyle?.colorSchema === "minimal-dark") {
      setWidgetStyle((p) => ({
        ...p,
        backgroundColor: "#00000a",
        borderColor: "#00000a",
        borderWeight: 0,
        dropShadow: "hide",
        borderRadius: 4,
        nameColor: "#fffffa",
        reviewColor: "#fffffa",
        outerBackgroundColor: "#000009",
        outerTextColor: "#fdffff",
      }));
    } else if (widgetStyle?.colorSchema === "dark-background") {
      setWidgetStyle((p) => ({
        ...p,
        backgroundColor: "#303030",
        borderColor: "#303030",
        borderWeight: 0,
        dropShadow: "hide",
        borderRadius: 4,
        nameColor: "#fffffa",
        reviewColor: "#fffffa",
        outerBackgroundColor: "#222",
        outerTextColor: "#fdffff",
      }));
    } else if (widgetStyle?.colorSchema === "dark-border") {
      setWidgetStyle((p) => ({
        ...p,
        backgroundColor: "#303030",
        borderColor: "#444",
        borderWeight: 2,
        dropShadow: "hide",
        borderRadius: 4,
        nameColor: "#fffffa",
        reviewColor: "#fffffa",
        outerBackgroundColor: "#222",
        outerTextColor: "#fdffff",
      }));
    } else if (widgetStyle?.colorSchema === "dark-contrast") {
      setWidgetStyle((p) => ({
        ...p,
        backgroundColor: "#fff",
        borderColor: "#303030",
        borderWeight: 2,
        dropShadow: "hide",
        borderRadius: 4,
        nameColor: "#00000a",
        reviewColor: "#00000a",
        outerBackgroundColor: "#222",
        outerTextColor: "#fdffff",
      }));
    } else if (widgetStyle?.colorSchema === "soft") {
      setWidgetStyle((p) => ({
        ...p,
        backgroundColor: "#efefef",
        borderColor: "#efefef",
        borderWeight: 0,
        dropShadow: "hide",
        borderRadius: 4,
        nameColor: "#000009",
        reviewColor: "#475569",
        outerBackgroundColor: "#e4e4e4",
        outerTextColor: "#000009",
      }));
    }
  }, [widgetStyle?.colorSchema]);

  const handlePostWidget = () => {
    dispatch(
      postWidget(
        widgetStyle,
        {
          title: `Trustbucket Widget - ${widgetStyle?.widgetName}`,
          width: "100%",
          id: `trustbucket-${widgetStyle?.widgetType}`,
          scrolling: "no",
          frameborder: "0",
          // onload:
          //   // eslint-disable-next-line
          //   "javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+'px';}(this));",
          // style: "height:200px;width:100%;border:none;overflow:hidden;",
        },
        () => setPopup(true)
      )
    );
  };

  return (
    <>
      <IframeModal
        popup={popup}
        setPopup={setPopup}
        title={widgetStyle?.widgetName}
        iframeCode={widgetIframe}
        id={widgetStyle?.widgetType}
      />
      <div className="flex h-screen overflow-y-hidden">
        <ConfiguratorSidebar
          widgetStyle={widgetStyle}
          setWidgetStyle={setWidgetStyle}
          handlePostWidget={handlePostWidget}
        />
        <div className="w-10/12 relative">
          <ConfiguratorHeader saveCode={handlePostWidget} />
          <div className="flex pt-20 h-screen bg-white items-start overflow-y-scroll justify-center relative">
            <div className="w-full">
              {type === "slider" && widgetStyle && (
                <WidgetSlider widgetStyle={widgetStyle} />
              )}
              {type === "badge" && widgetStyle && (
                <WidgetHtmlBadge widgetStyle={widgetStyle} />
              )}
              {type === "grid" && widgetStyle && (
                <WidgetGrid widgetStyle={widgetStyle} />
              )}
              {type === "list" && widgetStyle && (
                <WidgetList widgetStyle={widgetStyle} />
              )}
              {type === "button" && widgetStyle && (
                <WidgetButton widgetStyle={widgetStyle} />
              )}
              {type === "collectReviewsButton" && widgetStyle && (
                <WidgetButton widgetStyle={widgetStyle} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WidgetConfigurator;
