import React from "react";
// redux
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
// reducers
import { rootReducer } from "store/reducers";
import { Routes } from "./Routes";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default function RoutesHolder() {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
}
