import * as actionTypes from "./actionTypes";
import axios from "config/axios";

import { errorsNotification } from "./notification";

export const setLogs = (payload) => {
  return {
    type: actionTypes.SET_LOGS,
    payload,
  };
};

export const setLogsTotal = (payload) => {
  return {
    type: actionTypes.SET_LOGS_TOTAL,
    payload,
  };
};

export const setLogsLastPage = (payload) => {
  return {
    type: actionTypes.SET_LOGS_LAST_PAGE,
    payload,
  };
};

export const getLogsFilter = (queryParams, callback) => {
  return (dispatch) => {
    axios
      .post("log/filter", { queryParams })
      .then(({ data }) => {
        dispatch(setLogs(data.data));
        dispatch(setLogsTotal(data.total));
        dispatch(setLogsLastPage(Math.ceil(data.total / queryParams.pageSize)));

        callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        callback();
      });
  };
};
