import React, { useState } from "react";
import logo from "icons/logo-dark.svg";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { login } from "store/actions/auth";
import clsx from "clsx";
import { useFormik } from "formik";
import * as Yup from "yup";
import { forgotPassword } from "store/actions/auth";

const initialValues = {
  companyEmail: "",
};

const registrationSchema = Yup.object().shape({
  companyEmail: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
});

function ForgotPassword() {
  const [sent, setSent] = useState(false);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      dispatch(
        forgotPassword(values.companyEmail, () => {
          // history.push("/login")
          setSent(true);
        })
      );
    },
  });

  return (
    <div className="bg-white min-h-screen">
      <div className="px-5 pt-8 pb-6 mb-10 md:p-10 md:mb-0 flex items-center justify-between">
        <a href="https://trustbucket.io/">
          <img
            className="w-auto h-5 object-contain"
            src={logo}
            alt="Workflow"
          />
        </a>
        <div className="text-gray-600 text-sm">
          <Link to="/auth/login" className="text-blue-600 underline">
            Back to login
          </Link>
        </div>
      </div>
      <div className="h-full flex flex-col max-w-full sm:max-w-sm mx-auto justify-center px-5">
        <div className="font-medium text-2xl mb-4 md:text-center text-black">
          Forgot your password?
        </div>
        <div className="text-gray-600 md:text-center text-sm mb-10">
          Enter your email address and we'll send you instructions on how to
          reset your password.
        </div>
        <div>
          <form className="space-y-4" noValidate onSubmit={formik.handleSubmit}>
            {!sent && (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Email adress
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="eg. jane.jackson@nasa.com"
                    {...formik.getFieldProps("companyEmail")}
                    className={clsx(
                      "appearance-none block w-full px-4 py-3 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm",
                      {
                        "is-invalid":
                          formik.touched.companyEmail &&
                          formik.errors.companyEmail,
                      },
                      {
                        "is-valid":
                          formik.touched.companyEmail &&
                          !formik.errors.companyEmail,
                      }
                    )}
                  />
                  {formik.touched.companyEmail &&
                    formik.errors.companyEmail && (
                      <span className="text-red-600 text-xs">
                        {formik.errors.companyEmail}
                      </span>
                    )}
                </div>
              </div>
            )}
            {sent && (
              <div className="p-4 my-2 bg-green-100 text-green-800 rounded-md">
                Check your email for instructions on how to reset your password.
                If it doesn’t appear within a few minutes, check your spam or
                promotions folder.
              </div>
            )}

            <div>
              {!sent && (
                <button
                  type="submit"
                  className="w-full flex justify-center py-3 px-4 border border-transparent rounded shadow-sm transition-colors font-medium text-white bg-blue-600 hover:bg-blue-700"
                >
                  Send email
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
