import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getInvitationSettings,
  updateInvitationSettings,
} from "store/actions/campaign";

import logoPlaceholder from "icons/campaign-logo-placeholder.svg";
import useDragAndDrop from "helpers/useDragAndDrop";

const registrationSchema = Yup.object().shape({
  senderName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(30, "Maximum 30 symbols")
    .required("Sender name is required"),
  replyTo: Yup.string().required("Reply email is required"),
});

const InvitationSettings = () => {
  const dispatch = useDispatch();

  const { drop, handleFile, image, onUpload, setImage } = useDragAndDrop();

  const invitationSettings = useSelector(
    (state) => state.campaign.invitationSettings
  );

  // const adminId = localStorage.getItem("id");
  const user = useSelector((state) => state.auth.account);

  const companies = useSelector((state) => state.auth.selectedCompany);
  const businessId = companies?.id;

  // console.log("businessId:" + businessId);

  const initialValues = {
    senderName: invitationSettings?.senderName || "",
    replyTo: invitationSettings?.replyTo || "",
    color: invitationSettings?.color || "#2563eb",
  };

  const formik = useFormik({
    validationSchema: registrationSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const bodyFormData = new FormData();
      bodyFormData.append("senderName", values.senderName);
      bodyFormData.append("replyTo", values.replyTo);
      bodyFormData.append("color", values.color);
      bodyFormData.append(
        "file",
        onUpload ? onUpload : user?.invitationSettings?.logo
      );

      dispatch(updateInvitationSettings(businessId, bodyFormData));
    },
  });

  useEffect(() => {
    if (businessId) {
      dispatch(getInvitationSettings(businessId));
      // eslint-disable-next-line
    }
  }, [businessId]);

  useEffect(() => {
    setImage(invitationSettings?.logo);
    // eslint-disable-next-line
  }, [invitationSettings]);

  // console.log(image);

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full md:max-w-9xl mx-auto">
      <div className="text-black font-bold text-2xl mb-4">
        Invitation settings
      </div>
      <form noValidate onSubmit={formik.handleSubmit}>
        <div className="md:w-9/12 bg-white border border-gray-200">
          <div className="md:py-11 md:px-12 px-6 py-6">
            <div className="text-black font-bold text-2xl mb-4">
              Email settings
            </div>
            <div className="mb-5">
              <label className="block text-sm font-medium mb-5">
                Upload logo for branding of emails
              </label>

              <label
                className="w-64 h-24 p-5 cursor-pointer flex justify-center items-center border-blue-500 border-2 border-dotted rounded"
                htmlFor="logo"
                ref={drop}
              >
                {image ? (
                  <div>
                    <img
                      src={image}
                      alt="user avatar"
                      className="object-contain"
                      style={{ width: 124, height: 32 }}
                    />
                  </div>
                ) : (
                  <img src={logoPlaceholder} alt="Logo" />
                )}
              </label>
              <input
                id="logo"
                type="file"
                className="hidden"
                accept="image/*"
                onChange={handleFile}
              />
              <div className="text-xs text-gray-600 mt-3 font-medium">
                Recommended size: 124x32 px
              </div>
            </div>
            <div className="mb-5">
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="senderName"
              >
                Sender name
              </label>
              <input
                id="senderName"
                type="text"
                name="senderName"
                value={formik.initialValues?.senderName}
                {...formik.getFieldProps("senderName")}
                className={clsx(
                  "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm",
                  {
                    "is-invalid":
                      formik.touched.senderName && formik.errors.senderName,
                  },
                  {
                    "is-valid":
                      formik.touched.senderName && !formik.errors.senderName,
                  }
                )}
              />
              {formik.touched.senderName && formik.errors.senderName && (
                <span className="text-red-600 text-xs">
                  {formik.errors.senderName}
                </span>
              )}
            </div>
            <div className="mb-5">
              <label className="block text-sm font-medium mb-1">
                Sender email
              </label>
              <input
                type="text"
                className="form-input w-full bg-gray-200 text-black"
                value="noreply.invitations@trustbucket.io"
                disabled
              />
            </div>
            <div className="mb-5">
              <label className="block text-sm font-medium mb-1">
                If customer replies to invitation, send reply to:
              </label>
              <input
                type="text"
                name="replyTo"
                value={formik.values?.replyTo}
                {...formik.getFieldProps("replyTo")}
                className={clsx(
                  "appearance-none block w-full px-3 py-2 border text-black border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm",
                  {
                    "is-invalid":
                      formik.touched.replyTo && formik.errors.replyTo,
                  },
                  {
                    "is-valid":
                      formik.touched.replyTo && !formik.errors.replyTo,
                  }
                )}
              />
              {formik.touched.replyTo && formik.errors.replyTo && (
                <span className="text-red-600 text-xs">
                  {formik.errors.replyTo}
                </span>
              )}
            </div>

            <div className="mb-5">
              <div className="">
                <label className="block text-sm font-medium text-gray-700 hide">
                  Button Color
                </label>
                <div className="mt-1">
                  <input
                    className="appearance-none block w-64 px-3 h-9 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm"
                    type="color"
                    name="color"
                    value={formik.values?.color}
                    {...formik.getFieldProps("color")}
                  />
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div className="py-6 px-8 flex items-center">
            <button
              type="submit"
              className="btn bg-blue-600 hover:bg-blue-700 transition-all text-white"
              // disabled={!formik.isValid}
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InvitationSettings;
