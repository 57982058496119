import * as actionTypes from "./actionTypes";
import axios from "config/axios";

import { errorsNotification } from "./notification";
import { finishLoading, startLoading } from "./loader";

export const setUsers = (payload) => {
  return {
    type: actionTypes.SET_USERS,
    payload,
  };
};

export const setUsersTotal = (payload) => {
  return {
    type: actionTypes.SET_USERS_TOTAL,
    payload,
  };
};

export const setUsersLastPage = (payload) => {
  return {
    type: actionTypes.SET_USERS_LAST_PAGE,
    payload,
  };
};

export const getUsersFilter = (queryParams, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("user/filter", { queryParams })
      .then(({ data }) => {
        dispatch(setUsers(data.data));
        dispatch(setUsersTotal(data.total));
        dispatch(
          setUsersLastPage(Math.ceil(data.total / queryParams.pageSize))
        );
        dispatch(finishLoading());
        callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        dispatch(finishLoading());
        callback();
      });
  };
};

export const setCompanies = (payload) => {
  return {
    type: actionTypes.SET_COMPANIES,
    payload,
  };
};

export const setCompaniesTotal = (payload) => {
  return {
    type: actionTypes.SET_COMPANIES_TOTAL,
    payload,
  };
};

export const setCompaniesLastPage = (payload) => {
  return {
    type: actionTypes.SET_COMPANIES_LAST_PAGE,
    payload,
  };
};

export const getCompaniesFilter = (queryParams, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("company/filter", { queryParams })
      .then(({ data }) => {
        dispatch(setCompanies(data.data));
        dispatch(setCompaniesTotal(data.total));
        dispatch(
          setCompaniesLastPage(Math.ceil(data.total / queryParams.pageSize))
        );
        dispatch(finishLoading());
        callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        dispatch(finishLoading());
        callback();
      });
  };
};
