import * as actionTypes from "../actions/actionTypes";

const initialState = {
  users: [],
  usersTotal: 0,
  usersLastPage: 1,
  companies: [],
  companiesTotal: 0,
  companiesLastPage: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case actionTypes.SET_USERS_TOTAL:
      return {
        ...state,
        usersTotal: action.payload,
      };
    case actionTypes.SET_USERS_LAST_PAGE:
      return {
        ...state,
        usersLastPage: action.payload,
      };
    case actionTypes.SET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case actionTypes.SET_COMPANIES_TOTAL:
      return {
        ...state,
        companiesTotal: action.payload,
      };
    case actionTypes.SET_COMPANIES_LAST_PAGE:
      return {
        ...state,
        companiesLastPage: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
