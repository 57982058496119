import React from "react";
import { Link } from "react-router-dom";

const Step3 = () => {
  return (
    <div className="mt-20 flex flex-col items-center">
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="64" height="64" rx="32" fill="#D1FAE5" />
        <mask
          id="mask0_181_3491"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="64"
          height="64"
        >
          <rect width="64" height="64" rx="32" fill="white" />
        </mask>
        <g mask="url(#mask0_181_3491)">
          <path
            d="M28.5 41L20.5 33L23.5 30L28.5 35L40.5 23L43.5 26L28.5 41Z"
            fill="#10B981"
          />
        </g>
      </svg>
      <div className="mt-10 text-lg">
        Review invitations have been sent to your customers.
      </div>
      <Link
        to="/collect-reviews/overview"
        className="btn bg-blue-600 hover:bg-blue-700 text-white block mt-14"
      >
        Go to overview
      </Link>
    </div>
  );
};

export default Step3;
