import axiosInstance from "config/axios";
import dayjs from "dayjs";
import { planMessage } from "helpers/calculatePlanPrice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoices, updateBillingInfo } from "store/actions/auth";
import { finishLoading, startLoading } from "store/actions/loader";

function BillingPanel() {
  const dispatch = useDispatch();
  const invoices = useSelector((state) => state.auth.invoices);
  const user = useSelector((state) => state.auth.account);

  const [yourAddress, setYourAddress] = useState(
    user?.selectedCompany?.billingInfo?.address
  );
  const [billingAddress, setBillingAddress] = useState(
    user?.selectedCompany?.billingInfo?.email
  );

  useEffect(() => {
    dispatch(getInvoices());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setYourAddress(user?.selectedCompany?.billingInfo?.address);
    setBillingAddress(user?.selectedCompany?.billingInfo?.email);
  }, [user]);

  const handleBillingUpdate = () => {
    dispatch(
      updateBillingInfo({ address: yourAddress, email: billingAddress })
    );
  };

  const changeInfoHandler = async (type, plan) => {
    dispatch(startLoading());
    let subscribeSession;
    try {
      subscribeSession = await axiosInstance.post(
        "/company/update-payment-session"
      );
    } catch {
    } finally {
      dispatch(finishLoading());
    }

    window.location.href = subscribeSession.data.url;
  };

  return (
    <div className="grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        {user?.type === "appsumo" ? (
          <>
            <div className="mb-8">
              <h2 className="text-2xl text-gray-800 font-bold mb-4">
                Billing & Invoices
              </h2>
              <div className="text-sm mb-2 text-gray-800">
                You have Pro plan available for{" "}
                <strong>{user?.availableProCompanies}</strong> companies.
              </div>
              <div className="text-sm text-gray-800 mb-4">
                Go to AppSumo to change your plan
              </div>
              <a
                href="https://appsumo.com/products/trustbucket/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn bg-blue-600 text-white transition-colors hover:bg-blue-700"
              >
                Change plan
              </a>
            </div>
          </>
        ) : (
          <>
            <div>
              <h2 className="text-2xl text-gray-800 font-bold mb-4">
                Billing & Invoices
              </h2>
              {planMessage(user)}
            </div>
            {/* Billing Information */}
            <section>
              <h3 className="text-lg lg:text-xl leading-snug text-gray-800 font-bold mb-1">
                Billing Information
              </h3>
              <ul>
                <li className="md:flex md:justify-between md:items-center py-3 border-b border-gray-200">
                  {/* Left */}
                  <div className="text-sm text-gray-800 font-medium">
                    Payment Method
                  </div>
                  {/* Right */}
                  {user?.selectedCompany?.billingInfo?.card?.provider && (
                    <div className="text-sm text-gray-800ml-4">
                      <span className="mr-3">
                        <span className="capitalize">
                          {user?.selectedCompany?.billingInfo?.card?.provider}
                        </span>{" "}
                        ending{" "}
                        {user?.selectedCompany?.billingInfo?.card?.last4digits}
                      </span>
                    </div>
                  )}
                </li>

                <li className="md:flex md:justify-between md:items-center py-3 border-b border-gray-200">
                  {/* Left */}
                  <div className="text-sm text-gray-800 font-medium">
                    Expires
                  </div>
                  {/* Right */}
                  {user?.selectedCompany?.billingInfo?.card?.expires && (
                    <div className="text-sm text-gray-800ml-4">
                      <span className="mr-3">
                        {user?.selectedCompany?.billingInfo?.card?.expires}
                      </span>
                    </div>
                  )}
                </li>

                <li className="md:flex md:justify-between md:items-center py-3 border-b border-gray-200">
                  {/* Left */}
                  <div className="text-sm text-gray-800 font-medium">
                    Billing Interval
                  </div>
                  {/* Right */}
                  {user?.selectedCompany?.billingInfo?.interval && (
                    <div className="text-sm text-gray-800ml-4">
                      <span className="mr-3 capitalize">
                        {user?.selectedCompany?.billingInfo?.interval}
                        <span className="lowercase">ly</span>
                      </span>
                    </div>
                  )}
                </li>

                <li className="md:flex md:justify-between md:items-center py-3 border-b border-gray-200">
                  {/* Left */}
                  <div className="text-sm text-gray-800 font-medium">VAT</div>
                  {/* Right */}
                  {user?.selectedCompany?.billingInfo?.vatNumber && (
                    <div className="text-sm text-gray-800ml-4">
                      <span className="mr-3">
                        {user?.selectedCompany?.billingInfo?.vatNumber}
                      </span>
                    </div>
                  )}
                </li>

                <li className="md:flex md:justify-between md:items-center py-3 border-b border-gray-200">
                  {/* Left */}
                  <div className="text-sm text-gray-800 font-medium">
                    Your Address
                  </div>
                  {/* Right */}
                  <input
                    type="text"
                    className="form-input w-64"
                    placeholder="Enter your address..."
                    value={yourAddress}
                    onChange={(e) => setYourAddress(e.target.value)}
                  />
                </li>
                <li className="md:flex md:justify-between md:items-center py-3 border-b border-gray-200">
                  {/* Left */}
                  <div className="text-sm text-gray-800 font-medium">
                    Billing Address
                  </div>
                  {/* Right */}
                  <input
                    type="email"
                    className="form-input w-64"
                    placeholder="example@gmail.com"
                    value={billingAddress}
                    onChange={(e) => setBillingAddress(e.target.value)}
                  />
                </li>
              </ul>
            </section>
            {/* Invoices */}
            <section>
              <h3 className="text-lg lg:text-xl leading-snug text-gray-800 font-bold mb-1">
                Invoices
              </h3>
              <table className="table-auto w-full">
                <thead className="text-xs uppercase text-gray-400">
                  <tr className="flex flex-wrap md:table-row md:flex-no-wrap">
                    <th className="w-full block md:w-auto md:table-cell py-2">
                      <div className="font-semibold text-left">Date</div>
                    </th>
                    <th className="w-full hidden md:w-auto md:table-cell py-2">
                      <div className="font-semibold text-left">Plan</div>
                    </th>
                    <th className="w-full hidden md:w-auto md:table-cell py-2">
                      <div className="font-semibold text-left">Amount</div>
                    </th>
                    <th className="w-full hidden md:w-auto md:table-cell py-2">
                      <div className="font-semibold text-right"></div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm">
                  {invoices.map((item, index) => (
                    <tr className="flex flex-wrap md:table-row md:flex-no-wrap border-b border-gray-200 py-2 md:py-0">
                      <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2">
                        <div className="text-left font-medium text-gray-800">
                          {dayjs(new Date(item.created)).format("DD. MM. YYYY")}
                        </div>
                      </td>
                      <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2">
                        <div className="text-left">{item.item}</div>
                      </td>
                      <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2">
                        <div className="text-left font-medium">
                          € {item.amount}
                        </div>
                      </td>
                      <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2">
                        <div className="text-right flex items-center md:justify-end">
                          <a
                            className="font-medium text-blue-500 hover:text-blue-600"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={item.pdf}
                          >
                            PDF
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>{" "}
          </>
        )}
      </div>

      {/* Panel footer */}
      {user?.type !== "appsumo" && (
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-gray-200">
            <div className="flex self-end">
              <button
                onClick={changeInfoHandler}
                className="btn border-gray-200 hover:border-gray-300 text-gray-600 cursor-pointer"
              >
                Change Credit Card
              </button>
              <button
                onClick={handleBillingUpdate}
                className="btn bg-blue-500 hover:bg-blue-600 text-white ml-3"
              >
                Save Changes
              </button>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
}

export default BillingPanel;
