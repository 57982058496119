import React from "react";
import { Link } from "react-router-dom";
import lockSVG from "icons/lock-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setUpgradePopup } from "store/actions/upgradePopup";

export default function UpgradePopup() {
  const dispatch = useDispatch();
  const upgradePopup = useSelector((state) => state.upgradePopup);

  return (
    <div
      className={
        "fixed top-0 left-0 w-full h-full transition-all bg-black bg-opacity-20" +
        (upgradePopup ? " opacity-100 z-60" : " opacity-0 -z-10")
      }
      onClick={() => dispatch(setUpgradePopup(false))}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="absolute max-w-sm left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 bg-white rounded-lg pt-14 pb-10 px-6 text-center flex flex-col items-center"
      >
        <img
          src={lockSVG}
          alt="Lock"
          className="relative"
          style={{ width: 90, height: 90, marginTop: -90 }}
        />
        <div className="mb-4 text-black font-semibold text-lg">
          Upgrade to use
        </div>
        <div className="text-black mb-7">
          Unlock this feature by upgrading to one of our paid plans.{" "}
        </div>
        <Link
          onClick={() => dispatch(setUpgradePopup(false))}
          to="/settings/plans"
          className="btn bg-blue-600 text-white transition-all hover:bg-blue-700 mb-7"
        >
          Upgrade account
        </Link>
        <div
          onClick={() => dispatch(setUpgradePopup(false))}
          className="text-gray-500 cursor-pointer"
        >
          No, thank you
        </div>
      </div>
    </div>
  );
}
