import axios from "config/axios";
import * as actionTypes from "./actionTypes";
import { getCurrentUser } from "./auth";
import { finishLoading, startLoading } from "./loader";
import { errorsNotification } from "./notification";

export const setPlatformProfile = (payload) => {
  return {
    type: actionTypes.SET_PLATFORM_PROFILE,
    payload,
  };
};

export const getPlatformProfile = (type, term, location, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(
        `rating/${type}/search`,
        location ? { q: term, location } : { q: term }
      )
      .then((response) => {
        if (response.data && response.data !== {}) {
          dispatch(setPlatformProfile(response.data));
          callback && callback();
        } else {
          dispatch(errorsNotification("Profile not found for searched terms"));
        }
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(finishLoading());
        if (error?.response?.data?.message) {
          dispatch(errorsNotification(error?.response?.data?.message));
        } else {
          dispatch(errorsNotification("Profile not found for searched terms"));
        }
      });
  };
};

export const savePlatformProfile = (type, url, placeId, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`rating/${type}`, placeId ? { placeId } : { url })
      .then((response) => {
        dispatch(finishLoading());
        dispatch(getCurrentUser());
        callback && callback();
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.message));
      });
  };
};
