import ChangePassModal from "components/Shared/ChangePassModal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Image from "../../images/user-avatar-80.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { deleteProfile, setUser, updateProfile } from "store/actions/auth";
import clsx from "clsx";
import { DeletePopup } from "components/DeletePopup/DeletePopup";

const registrationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNumber: Yup.string().required("Phone is required"),
});
// const adminId = localStorage.getItem("id");
function AccountPanel() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.account);
  const [changePassModalOpen, setChangePassModalOpen] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const adminId = localStorage.getItem("id");
  // const history = useHistory();

  const handleDelete = () => {
    // dispatch(deleteRatingPlatform(data?.type, () => setDeletePopup(false)));
    dispatch(
      deleteProfile(adminId, () => {
        localStorage.clear();
        dispatch(setUser(null));
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber,
    },
    enableReinitialize: true,
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      const user = {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
      };
      dispatch(updateProfile(adminId, user));
    },
  });

  useEffect(() => {
    formik.initialValues = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber,
    };
    // eslint-disable-next-line
  }, [user, formik]);

  return (
    <>
      <DeletePopup
        deletePopup={deletePopup}
        setDeletePopup={setDeletePopup}
        onSubmit={handleDelete}
        title={`Deactivate Account`}
        description={`<div><p>All your subscriptions will be cancelled and your profile, account and data will be deleted. This is a nonrecoverable process.</p>
        <p className="font-semibold">Your data cannot be recovered if you continue.</p></div>`}
      />
      <div className="grow">
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Panel body */}
          <div className="p-6 space-y-6">
            <h2 className="text-xl sm:text-2xl text-gray-800 font-bold mb-5">
              My Profile
            </h2>

            {/* Business Profile */}
            <section>
              <h2 className="text-lg lg:text-xl leading-snug text-gray-800 font-bold mb-1">
                Business Profile
              </h2>
              <div className="text-sm">
                Set your name and contact information, the email address entered
                here is used for your login access
              </div>
              <div className="sm:flex sm:items-start space-y-4 sm:space-y-0 sm:space-x-4 mt-5 w-full xl:w-9/12">
                <div className="sm:w-1/2">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="first-name"
                  >
                    First Name
                  </label>
                  <input
                    id="first-name"
                    type="text"
                    {...formik.getFieldProps("firstName")}
                    className={clsx(
                      "form-input w-full",
                      {
                        "is-invalid":
                          formik.touched.firstName && formik.errors.firstName,
                      },
                      {
                        "is-valid":
                          formik.touched.firstName && !formik.errors.firstName,
                      }
                    )}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <span className="text-red-600 text-xs">
                      {formik.errors.firstName}
                    </span>
                  )}
                </div>
                <div className="sm:w-1/2">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="last-name"
                  >
                    Last name
                  </label>
                  <input
                    id="last-name"
                    type="text"
                    {...formik.getFieldProps("lastName")}
                    className={clsx(
                      "form-input w-full",
                      {
                        "is-invalid":
                          formik.touched.lastName && formik.errors.lastName,
                      },
                      {
                        "is-valid":
                          formik.touched.lastName && !formik.errors.lastName,
                      }
                    )}
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <span className="text-red-600 text-xs">
                      {formik.errors.lastName}
                    </span>
                  )}
                </div>
              </div>
            </section>
            {/* Email */}
            <div className="mr-2">
              <label className="block text-sm font-medium mb-1" htmlFor="email">
                Email
              </label>
              <input
                id="email"
                className="form-input w-full xl:w-9/12 bg-gray-200 cursor-not-allowed"
                type="email"
                defaultValue={user?.email}
                disabled
              />
            </div>
            <div className="mr-2">
              <label className="block text-sm font-medium mb-1" htmlFor="phoneNumber">
                Phone number
              </label>
              <input
                id="phoneNumber"
                type="tel"
                {...formik.getFieldProps("phoneNumber")}
                className={clsx(
                  "form-input w-full xl:w-9/12 outline-none",
                  {
                    "is-invalid": formik.touched.phoneNumber && formik.errors.phoneNumber,
                  },
                  {
                    "is-valid": formik.touched.phoneNumber && !formik.errors.phoneNumber,
                  }
                )}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <span className="text-red-600 block text-xs mt-1">
                  {formik.errors.phoneNumber}
                </span>
              )}
            </div>
            {/* Password */}
            <section className="pb-8">
              <h2 className="text-lg lg:text-xl leading-snug text-gray-800 font-bold mb-1">
                Password
              </h2>
              <div className="text-sm">
                You can set a permanent password if you don't want to use
                temporary login codes.
              </div>
              <div className="mt-5">
                <div
                  className="btn border-gray-200 shadow-sm text-blue-500 cursor-pointer"
                  aria-controls="password-modal"
                  onClick={(e) => {
                    e.stopPropagation();
                    setChangePassModalOpen(true);
                  }}
                >
                  Set New Password
                </div>
              </div>
            </section>
            <section className="pb-8">
              <h2 className="text-lg lg:text-xl leading-snug text-gray-800 font-bold mb-1">
                Deactivate Account
              </h2>
              {user?.type === "appsumo" &&
              user?.availableProCompanies !== "0" ? (
                <>
                  <div className="text-sm">
                    In order to deactivate your account, you must refund product
                    on AppSumo first.
                  </div>
                  <div className="mt-5">
                    <a
                      href="https://appsumo.com/account/products"
                      className="btn border-red-200 shadow-sm text-red-500 cursor-pointer"
                    >
                      Refund
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <div className="text-sm">
                    This cannot be undone! Your account will be forever lost!
                  </div>
                  <div className="mt-5">
                    <div
                      className="btn border-red-200 shadow-sm text-red-500 cursor-pointer"
                      aria-controls="password-modal"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeletePopup(true);
                      }}
                    >
                      Deactivate account
                    </div>
                  </div>
                </>
              )}
            </section>
          </div>
          {/* Panel footer */}
          <footer>
            <div className="flex flex-col px-6 py-5 border-t border-gray-200">
              <div className="flex self-end">
                <button
                  type="submit"
                  // disabled={!formik.isValid}
                  className="btn bg-blue-500 hover:bg-blue-600 text-white ml-3"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </footer>
        </form>
        <ChangePassModal
          changePassModalOpen={changePassModalOpen}
          setChangePassModalOpen={setChangePassModalOpen}
        />
      </div>
    </>
  );
}

export default AccountPanel;
