import ModalBasic from "components/ModalBasic";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { savePlatformProfile } from "store/actions/platform";
import { getCleanUrl } from "helpers/getCleanUrl";

const PlatformModal = ({ platformModal, setPlatformModalOpen, data, type }) => {
  const dispatch = useDispatch();
  const platformData = useSelector((state) => state.platform.platformProfile);
  const history = useHistory();

  const handleConnect = () => {
    dispatch(
      savePlatformProfile(
        type,
        getCleanUrl(platformData?.link),
        platformData.placeId,
        submitCallback
      )
    );
  };

  const submitCallback = () => {
    setPlatformModalOpen(false);
    history.push("/connect-platforms");
  };

  return (
    <ModalBasic
      id="platform-modal"
      modalOpen={platformModal}
      setModalOpen={setPlatformModalOpen}
      title={platformData?.title}
    >
      <div>
        {/* Modal content */}
        {platformData?.image && (
          <img
            src={platformData?.image}
            alt="platform"
            className="max-h-32 mx-auto my-4"
          />
        )}
        {/* LOGO HERE */}

        <div className="px-4 pb-2 text-sm">
          If this is not your profile, be more precise, try entering city or
          country with business name
        </div>
        <div className="px-4 py-2 text-sm">
          By clicking “Connect”, I take full responsibility for how the data
          that is loaded are used.
        </div>
        {/* Modal footer */}
        <div className="px-5 py-4 border-t border-gray-200">
          <div className="flex flex-wrap justify-end space-x-2">
            <div
              className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setPlatformModalOpen(false);
              }}
            >
              Cancel
            </div>
            <button
              type="submit"
              className="btn-sm bg-blue-500 hover:bg-blue-600 text-white"
              onClick={handleConnect}
            >
              Connect
            </button>
          </div>
        </div>
      </div>
    </ModalBasic>
  );
};

export default PlatformModal;
