import React, { useState } from "react";
import logo from "icons/logo-dark.svg";
import googleLoginSVG from "icons/google-login.svg";
import { Link, useHistory } from "react-router-dom";
import GoogleLogin from "react-google-login";
import { useDispatch } from "react-redux";
import { register } from "store/actions/auth";
import clsx from "clsx";
import { useFormik } from "formik";
import * as Yup from "yup";
import EmailThank from "components/EmailThank/EmailThank";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};

const registrationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Minimum 8 symbols")
    .required("Password is required"),
});

export default function Register() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [registered, setRegistered] = useState(false);

  const handleGoogleLogin = async (googleData) => {
    const email = googleData.profileObj.email;
    const firstName = googleData.profileObj.givenName;
    const lastName = googleData.profileObj.familyName;
    dispatch(
      register(
        {
          email,
          firstName,
          lastName,
          password: "google",
        },
        (id) => history.push(`/auth/welcome/${id}`)
      )
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      const data = {
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
      };
      dispatch(register(data, () => setRegistered(true)));
    },
  });

  return (
    <div className="bg-white min-h-screen">
      <div className="px-5 pt-8 pb-6 mb-10 md:p-10 md:mb-0 flex items-center justify-between">
        <a href="https://trustbucket.io/">
          <img
            className="w-auto h-5 object-contain"
            src={logo}
            alt="Workflow"
          />
        </a>
        <div className="text-gray-600 text-sm">
          <span className="md:inline hidden">Already have an account? </span>
          <Link to="/auth/login" className="text-blue-600 underline">
            Log in
          </Link>
        </div>
      </div>
      {registered ? (
        <EmailThank email={formik.values.email} />
      ) : (
        <div className="h-full flex flex-col max-w-full sm:max-w-sm mx-auto justify-center px-5">
          <div className="font-medium text-2xl mb-4 md:text-center text-black">
            Create your free account
          </div>
          <div className="text-gray-600 md:text-center text-sm mb-10">
            No credit card required. No time limit on Free plan.
          </div>
          <GoogleLogin
            className="w-full justify-center"
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            buttonText="Sign up with Google"
            onSuccess={handleGoogleLogin}
            onFailure={() => {}}
            cookiePolicy={"single_host_origin"}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="flex items-center w-full mb-8 text-center transition-colors hover:bg-blue-700 rounded-md px-0 pr-6 py-0 bg-blue-600 text-white font-medium text-base shadow sm:pr-6"
              >
                <img src={googleLoginSVG} alt="Google" />
                <span className="w-full">Sign up with Google</span>
              </button>
            )}
          />
          <div className="flex items-center justify-between text-center mb-8 text-gray-400 text-sm">
            <div
              className="bg-gray-400"
              style={{ height: 1, width: 145 }}
            ></div>
            <div className="w-max mx-2">OR</div>
            <div
              className="bg-gray-400 w-1/2"
              style={{ height: 1, width: 145 }}
            ></div>
          </div>
          <div>
            <form noValidate onSubmit={formik.handleSubmit}>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  First name
                </label>
                <div className="mt-2">
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    placeholder="Enter your first name"
                    {...formik.getFieldProps("firstName")}
                    className={clsx(
                      "appearance-none block w-full px-4 py-3 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm",
                      {
                        "is-invalid":
                          formik.touched.firstName && formik.errors.firstName,
                      },
                      {
                        "is-valid":
                          formik.touched.firstName && !formik.errors.firstName,
                      }
                    )}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <span className="text-red-600 text-xs">
                      {formik.errors.firstName}
                    </span>
                  )}
                </div>
              </div>
              <div className="mt-8">
                <label className="block text-sm font-medium text-gray-700">
                  Last name
                </label>
                <div className="mt-2">
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    placeholder="Enter your last name"
                    {...formik.getFieldProps("lastName")}
                    className={clsx(
                      "appearance-none block w-full px-4 py-3 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm",
                      {
                        "is-invalid":
                          formik.touched.lastName && formik.errors.lastName,
                      },
                      {
                        "is-valid":
                          formik.touched.lastName && !formik.errors.lastName,
                      }
                    )}
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <span className="text-red-600 text-xs">
                      {formik.errors.lastName}
                    </span>
                  )}
                </div>
              </div>
              <div className="mt-8">
                <label className="block text-sm font-medium text-gray-700">
                  Email adress
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="eg. jane.jackson@nasa.com"
                    {...formik.getFieldProps("email")}
                    className={clsx(
                      "appearance-none block w-full px-4 py-3 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm",
                      {
                        "is-invalid":
                          formik.touched.email && formik.errors.email,
                      },
                      {
                        "is-valid":
                          formik.touched.email && !formik.errors.email,
                      }
                    )}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <span className="text-red-600 text-xs">
                      {formik.errors.email}
                    </span>
                  )}
                </div>
              </div>
              <div className="mt-8">
                <label className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter your password"
                    {...formik.getFieldProps("password")}
                    className={clsx(
                      "appearance-none block w-full px-4 py-3 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm",
                      {
                        "is-invalid":
                          formik.touched.password && formik.errors.password,
                      },
                      {
                        "is-valid":
                          formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <span className="text-red-600 text-xs">
                      {formik.errors.password}
                    </span>
                  )}
                </div>
              </div>
              <button
                type="submit"
                className="w-full my-8 flex justify-center py-3 px-4 border border-transparent rounded shadow-sm transition-colors font-medium text-white bg-blue-600 hover:bg-blue-700"
              >
                Continue
              </button>
              <div className="text-sm text-center text-gray-400 pb-10">
                By creating an account you agree to Trustbucket’s{" "}
                <a
                  href="https://trustbucket.io/terms-and-conditions/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://trustbucket.io/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600"
                >
                  Privacy Policy.
                </a>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
