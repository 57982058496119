export const SET_USER = "SET_USER";
export const SET_LOGIN_ERR = "SET_LOGIN_ERR";
export const SET_REGISTER_ERR = "SET_REGISTER_ERR";

export const SET_TEAM_MEMBERS = "SET_TEAM_MEMBERS";

export const START_LOADING = "START_LOADING";
export const FINISH_LOADING = "FINISH_LOADING";

export const SUCCESS_NOTIFICATION = "SUCCESS_NOTIFICATION";
export const ERROR_NOTIFICATION = "ERROR_NOTIFICATION";

export const SET_GOOGLE_PROFILE = "SET_GOOGLE_PROFILE";
export const SET_GOOGLE_LOCATIONS = "SET_GOOGLE_LOCATIONS";

export const SET_PLATFORM_PROFILE = "SET_PLATFORM_PROFILE";

export const SET_RATINGS = "SET_RATINGS";
export const SET_RATINGS_TOTAL = "SET_RATINGS_TOTAL";
export const SET_RATINGS_LAST_PAGE = "SET_RATINGS_LAST_PAGE";
export const SET_UNREPLIED_TOTAL = "SET_UNREPLIED_TOTAL";

export const SET_STATS = "SET_STATS";

export const SET_EMAIL_TEMPLATES = "SET_EMAIL_TEMPLATES";
export const SET_CAMPAIGNS_STATS = "SET_CAMPAIGNS_STATS";
export const SET_INVITATION_SETTINGS = "SET_INVITATION_SETTINGS";
export const SET_INVITATION_SENT = "SET_INVITATION_SENT";
export const SET_AUTOMATIC_COLLECTION_SETTINGS =
  "SET_AUTOMATIC_COLLECTION_SETTINGS";
export const SET_CAMPAIGN_RECIEVERS = "SET_CAMPAIGN_RECIEVERS";
export const SET_EMAIL_PREVIEW_CONTENT = "SET_EMAIL_PREVIEW_CONTENT";

export const SET_LOGS = "SET_LOGS";
export const SET_LOGS_TOTAL = "SET_LOGS_TOTAL";
export const SET_LOGS_LAST_PAGE = "SET_LOGS_LAST_PAGE";

export const SET_CHANGED_REPLY = "SET_CHANGED_REPLY";

export const SET_WIDGET_IFRAME = "SET_WIDGET_IFRAME";
export const SET_WIDGET = "SET_WIDGET";
export const SET_WIDGET_RATINGS = "SET_WIDGET_RATINGS";
export const SET_PREDEFINED_WIDGETS = "SET_PREDEFINED_WIDGETS";

export const SET_INVOICES = "SET_INVOICES";

export const SET_USERS = "SET_USERS";
export const SET_USERS_TOTAL = "SET_USERS_TOTAL";
export const SET_USERS_LAST_PAGE = "SET_USERS_LAST_PAGE";

export const SET_COMPANIES = "SET_COMPANIES";
export const SET_SELECTED_COMPANY = "SET_SELECTED_COMPANY";
export const SET_COMPANIES_TOTAL = "SET_COMPANIES_TOTAL";
export const SET_COMPANIES_LAST_PAGE = "SET_COMPANIES_LAST_PAGE";

export const SET_UPGRADE_POPUP = "SET_UPGRADE_POPUP";

export const SET_LAST_ANNOUNCEMENT = "SET_LAST_ANNOUNCEMENT";

export const SET_TAGS = "SET_TAGS";
