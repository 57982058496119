import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  filterRatings,
  getRatingsList,
  setRatings,
} from "store/actions/ratings";
import SingleReview from "components/SingleReview/SingleReview";
import ManageFilters from "components/ManageReviews/ManageFilters";
import { getTags } from "store/actions/tags";

// later from api;
// eslint-disable-next-line
const sources = ["Bokadirekt", "Recose", "Booking", "Fresha"];
const initialQueryParams = {
  pageNumber: 1,
  pageSize: 10,
  sortField: "date",
  sortOrder: "desc",
};

const ManageReviews = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.account);
  const companies = useSelector((state) => state.auth.selectedCompany);
  const tags = useSelector((state) => state.tags.tags);
  const businessId = companies?.id;
  const businessName = companies?.businessUserName;

  const [filtersActive, setFiltersActive] = useState(false);
  const ratings = useSelector((state) => state.ratings.ratings);
  const totalRatings = useSelector((state) => state.ratings.total);
  const lastPage = useSelector((state) => state.ratings.lastPage);
  const unrepliedTotal = useSelector((state) => state.ratings.unrepliedTotal);

  const [filteredRatings, setFilteredRatings] = useState([]);
  const [activeRatings, setActiveRatings] = useState([1, 2, 3, 4, 5]);
  const [activeTags, setActiveTags] = useState([]);
  const [queryParams, setQueryParams] = useState(initialQueryParams);
  const [replied, setReplied] = useState(true);
  const [type, setType] = useState(
    user?.selectedCompany?.ratings?.map((item, index) => item.type)
  );
  const [allTypes, setAllTypes] = useState(
    user?.selectedCompany?.ratings?.map((item, index) => item.type)
  );

  const toggleRatingsStars = (num) => {
    if (activeRatings.find((el) => el === num)) {
      if (activeRatings.length === 1) {
        return;
      }
      let newArr = activeRatings.filter((el) => el !== num);
      setActiveRatings(newArr);
    } else {
      setActiveRatings((p) => [...p, num]);
    }
    setQueryParams(initialQueryParams);
  };

  const toggleTag = (tag) => {
    if (activeTags.find((el) => el === tag)) {
      let newArr = activeTags.filter((el) => el !== tag);
      setActiveTags(newArr);
    } else {
      setActiveTags((p) => [...p, tag]);
    }
    setQueryParams(initialQueryParams);
  };

  const changeType = (newType) => {
    if (newType === allTypes) {
      setType(newType);
    } else {
      if (type) {
        if (type.some((el) => el === newType)) {
          let filteredTypes = type.filter((el) => el !== newType);
          if (filteredTypes.length === 0) return;
          setType(filteredTypes);
        } else {
          setType((p) => [...p, newType]);
        }
      }
    }
    setQueryParams((p) => ({ ...p, pageNumber: 1 }));
  };

  useEffect(() => {
    if (businessId) {
      dispatch(getRatingsList(businessId));
      // eslint-disable-next-line
    }
  }, []);

  useEffect(() => {
    if (businessId) {
      dispatch(filterRatings(businessId, activeRatings));
    }
  }, [activeRatings, activeTags]);

  useEffect(() => {
    if (businessId) {
      dispatch(getTags(businessId));
      // eslint-disable-next-line
    }
  }, []);

  useEffect(() => {
    if (activeTags.length === 0) {
      setFilteredRatings(ratings);
    } else {
      setFilteredRatings(
        ratings.filter((item) => {
          const tags = item?.tags;
          return tags && activeTags.includes(tags.id);
        })
      );
    }
  }, [activeTags, ratings]);

  useEffect(() => {
    setType(user?.selectedCompany?.ratings?.map((item, index) => item.type));
    setAllTypes(
      user?.selectedCompany?.ratings?.map((item, index) => item.type)
    );
  }, [user]);

  const changeReplyCallback = (id, replyText, isDelete = false) => {
    if (isDelete) {
      const ind = ratings.indexOf((el) => el.id === id);
      let newRatings = [...ratings];
      newRatings[ind] = { ...newRatings[ind], reply: null };
      dispatch(setRatings(newRatings));
    } else {
      const ind = ratings.indexOf((el) => el.id === id);
      let newRatings = [...ratings];
      newRatings[ind] = { ...newRatings[ind], reply: { text: replyText } };
      dispatch(setRatings(newRatings));
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <div className="text-black font-bold text-2xl mb-4">
        Manage reviews ✍🏻
      </div>
      <div className="mb-6 text-base text-gray-600">
        <span className="sm:block hidden">
          Reply to, like and manage your reviews easily from here. Replying to
          reviews helps build customer trust. When you reply to reviews, you
          post publicly as your business.
        </span>
        <span className="sm:hidden block">
          Show your customers that you value them, one reply at a time.
        </span>
      </div>
      <div className="flex flex-wrap">
        <ManageFilters
          type={type}
          allTypes={allTypes}
          activeRatings={activeRatings}
          changeType={changeType}
          toggleRatingsStars={toggleRatingsStars}
          user={user}
          filtersActive={filtersActive}
          setFiltersActive={setFiltersActive}
          activeTags={activeTags}
          toggleTag={toggleTag}
          businessId={businessId}
          tags={tags}
        />

        <div className="lg:w-9/12 w-full lg:pl-10">
          <div className="flex items-center">
            <button
              onClick={() => {
                setReplied(true);
                setQueryParams(initialQueryParams);
              }}
              className={
                "mr-2 sm:mr-3 rounded lg:rounded-full shadow-lg sm:text-base text-sm border border-solid py-2 px-3 bg-white border-gray-200 hover:bg-blue-600 hover:text-white transition-all " +
                (replied ? "text-white bg-blue-600" : "text-gray-400 bg-white")
              }
            >
              <span className="font-medium">All reviews</span> ({totalRatings})
            </button>
            <button
              onClick={() => {
                setReplied(false);
                setQueryParams(initialQueryParams);
              }}
              className={
                "rounded lg:rounded-full shadow-lg sm:text-base text-sm border border-solid py-2 px-3 border-gray-200 hover:bg-blue-600 hover:text-white transition-all " +
                (!replied ? "text-white bg-blue-600" : "text-gray-400 bg-white")
              }
            >
              <span className="font-medium">Not replied</span> ({unrepliedTotal}
              )
            </button>
            <button
              onClick={() => setFiltersActive(true)}
              className="rounded ml-2 lg:hidden shadow-lg border border-solid py-3 px-3 border-gray-200 text-gray-400 bg-white"
            >
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 14H7C6.448 14 6 13.552 6 13C6 12.448 6.448 12 7 12H9C9.552 12 10 12.448 10 13C10 13.552 9.552 14 9 14Z"
                  fill="#64748B"
                />
                <path
                  d="M11 10H5C4.448 10 4 9.552 4 9C4 8.448 4.448 8 5 8H11C11.552 8 12 8.448 12 9C12 9.552 11.552 10 11 10Z"
                  fill="#64748B"
                />
                <path
                  d="M13 6H3C2.448 6 2 5.552 2 5C2 4.448 2.448 4 3 4H13C13.552 4 14 4.448 14 5C14 5.552 13.552 6 13 6Z"
                  fill="#64748B"
                />
                <path
                  d="M15 2H1C0.448 2 0 1.552 0 1C0 0.448 0.448 0 1 0H15C15.552 0 16 0.448 16 1C16 1.552 15.552 2 15 2Z"
                  fill="#64748B"
                />
              </svg>
            </button>
          </div>

          <div className="mt-8 w-full xl:w-9/12">
            {filteredRatings.length > 0 &&
              filteredRatings?.map((item, index) => (
                <SingleReview
                  data={item}
                  key={index}
                  businessName={businessName}
                  businessId={businessId}
                  changeReplyCallback={changeReplyCallback}
                />
              ))}
            {ratings.length > 0 && queryParams.pageNumber < lastPage && (
              <button
                onClick={() =>
                  setQueryParams((p) => ({
                    ...p,
                    pageNumber: p.pageNumber + 1,
                  }))
                }
                className={
                  "mt-4 rounded lg:rounded-full shadow-lg border border-solid py-2 px-4 border-gray-200 transition-all hover:bg-blue-600 hover:text-white transition-alltext-gray-400 bg-white"
                }
              >
                <span className="font-medium">Load more</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageReviews;
