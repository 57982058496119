import React, { useMemo, useState } from "react";
import ReviewersTableItem from "./ReviewersTableItem";

export default function ReviewersTable({ total, loading, users }) {
  const [term, setTerm] = useState("");
  const filteredUsers = useMemo(() => {
    if (term === "") {
      return users;
    } else {
      return users.filter(
        (el) =>
          el.firstName.toLowerCase()?.startsWith(term.toLowerCase()) ||
          el.lastName.toLowerCase()?.startsWith(term.toLowerCase()) ||
          el.email.toLowerCase()?.startsWith(term.toLowerCase())
      );
    }
  }, [term, users]);
  return (
    <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
      <header className="px-5 py-4 flex items-center">
        <h2 className="font-semibold text-gray-800 mr-4 block">
          Invitations <span className="text-gray-400 font-medium">{total}</span>
        </h2>
        <input
          id="term"
          name="term"
          type="text"
          value={term}
          onChange={(e) => setTerm(e.target.value)}
          placeholder="Search invitations"
          className="appearance-none block sm:w-56 w-48 px-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm"
        />
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full divide-y divide-gray-200">
            {/* Table header */}
            <thead className="text-xs uppercase text-gray-500 bg-gray-50 border-t border-gray-200">
              <tr>
                {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                <div className="flex items-center">
                  <label className="inline-flex">
                    <span className="sr-only">Select all</span>
                    <input className="form-checkbox" type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                  </label>
                </div>
              </th> */}
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Email</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">First name</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Last name</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Status</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Last activity</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            {filteredUsers.map((user) => {
              return <ReviewersTableItem key={user.id} data={user} />;
            })}
          </table>
        </div>
      </div>
    </div>
  );
}
