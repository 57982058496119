import axios from "config/axios";
import * as actionTypes from "./actionTypes";
import { getCurrentUser } from "./auth";
import { finishLoading, startLoading } from "./loader";
import { errorsNotification } from "./notification";

export const setGoogleProfile = (payload) => {
  return {
    type: actionTypes.SET_GOOGLE_PROFILE,
    payload,
  };
};

export const getGoogleProfile = (term, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`rating/google/search`, { q: term })
      .then((response) => {
        if (response.data?.candidates?.length > 0) {
          dispatch(setGoogleProfile(response.data.candidates[0]));
          callback && callback();
        } else {
          dispatch(errorsNotification("Not Found!"));
        }
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.message));
        return false;
      });
  };
};

export const saveGoogleRating = (data, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("rating/google", data)
      .then((response) => {
        dispatch(finishLoading());
        dispatch(getCurrentUser());
        callback && callback();
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.message));
        return false;
      });
  };
};

export const setGoogleLocations = (payload) => {
  return {
    type: actionTypes.SET_GOOGLE_LOCATIONS,
    payload,
  };
};

export const getGoogleLocations = (code, callback, errorCallback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`rating/google/locations`, { code })
      .then((response) => {
        // dispatch(getCurrentUser());
        dispatch(setGoogleLocations(response.data));
        dispatch(finishLoading());
        callback && callback();
      })
      .catch((error) => {
        dispatch(finishLoading());
        errorCallback && errorCallback();
        dispatch(errorsNotification(error?.response?.data?.message));
      });
  };
};
