import ReviewersTable from "components/Reviewers/ReviewersTable";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignRecievers } from "store/actions/campaign";

const SentInvitations = () => {
  const dispatch = useDispatch();
  const adminId = localStorage.getItem("id");

  const campaignRecievers = useSelector(
    (state) => state.campaign.campaignRecievers
  );

  useEffect(() => {
    if (adminId) {
      dispatch(getCampaignRecievers(adminId));
      // eslint-disable-next-line
    }
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Title */}
        <div className="mb-4 sm:mb-0 flex">
          <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
            Sent Invitations
          </h1>
        </div>

        {/* Right: Actions */}
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"></div>
      </div>

      {/* Table */}
      <ReviewersTable
        total={campaignRecievers?.length}
        loading={false}
        users={campaignRecievers}
      />
    </div>
  );
};

export default SentInvitations;
