import React from "react";
import ModalBlank from "../../components/ModalBlank";
import parse from "html-react-parser";

export const DeletePopup = ({
  deletePopup,
  setDeletePopup,
  onSubmit,
  title,
  description,
  deleteBtnVisible = true,
}) => {
  return (
    <ModalBlank
      id="delete-platform-modal"
      modalOpen={deletePopup}
      setModalOpen={setDeletePopup}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="p-5 flex space-x-4">
          {/* Icon */}
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-red-100">
            <svg
              className="w-4 h-4 shrink-0 fill-current text-red-500"
              viewBox="0 0 16 16"
            >
              <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
            </svg>
          </div>
          {/* Content */}
          <div>
            {/* Modal header */}
            <div className="mb-2">
              <div className="text-lg font-semibold text-gray-800">{title}</div>
            </div>
            {/* Modal content */}
            <div className="text-sm mb-10">
              <div className="space-y-2">
                <div>{parse(description)}</div>
              </div>
            </div>
            {/* Modal footer */}
            <div className="flex flex-wrap justify-end space-x-2">
              <div
                className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setDeletePopup(false);
                }}
              >
                {deleteBtnVisible ? "Cancel" : "OK"}
              </div>
              {deleteBtnVisible && (
                <button
                  // onClick={onSubmit}
                  type="submit"
                  className="btn-sm bg-red-500 hover:bg-red-600 text-white"
                >
                  Yes
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </ModalBlank>
  );
};
