import React from "react";
import yelpStars1 from "icons/yelp-rate-1.png";
import yelpStars2 from "icons/yelp-rate-2.png";
import yelpStars3 from "icons/yelp-rate-3.png";
import yelpStars4 from "icons/yelp-rate-4.png";
import yelpStars5 from "icons/yelp-rate-5.png";

const YelpStars = ({ rating }) => {
  const displayStars = () => {
    switch (rating) {
      case 1:
        return yelpStars1;
      case 2:
        return yelpStars2;
      case 3:
        return yelpStars3;
      case 4:
        return yelpStars4;
      case 5:
        return yelpStars5;
      default:
        return;
    }
  };

  return (
    <div className="my-2">
      <img className="w-36" src={displayStars()} alt="rating" />
    </div>
  );
};

export default YelpStars;
