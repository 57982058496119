import ExpirationBaner from "components/ExpirationBaner/ExpirationBaner";
import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Help from "../components/DropdownHelp";
import UserMenu from "../components/DropdownProfile";
import relativeTime from "dayjs/plugin/relativeTime";
import AnnouncementBaner from "components/AnnouncementBaner/AnnouncementBaner";
dayjs.extend(relativeTime);

function Header({ sidebarOpen, setSidebarOpen }) {
  const user = useSelector((state) => state.auth.account);
  return (
    <>
      <AnnouncementBaner className="lg:block hidden" />
      <header className="sm:sticky fixed top-0 right-0 w-full bg-white border-b border-gray-200 z-30">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16 -mb-px">
            {/* Header: Left side */}
            <div className="flex">
              {/* Hamburger button */}
              <button
                className="text-gray-500 hover:text-gray-600 lg:hidden"
                aria-controls="sidebar"
                aria-expanded={sidebarOpen}
                onClick={() => setSidebarOpen(!sidebarOpen)}
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6 fill-current"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="4" y="5" width="16" height="2" />
                  <rect x="4" y="11" width="16" height="2" />
                  <rect x="4" y="17" width="16" height="2" />
                </svg>
              </button>
              <div className="lg:block hidden">
                <ExpirationBaner
                  open={user?.selectedCompany?.subscription?.plan === "trial"}
                >
                  Your free trial ends in{" "}
                  <strong>
                    {dayjs(user?.selectedCompany?.subscription?.ends).fromNow(
                      true
                    )}
                  </strong>
                  .{" "}
                  <Link className="underline" to="/settings/plans">
                    Upgrade plan
                  </Link>
                </ExpirationBaner>
              </div>
              <div className="w-3"></div>
            </div>

            {/* Header: Right side */}
            <div className="flex items-center space-x-3">
              <Help align="right" />
              {/*  Divider */}
              <hr className="w-px h-6 bg-gray-200 mx-3" />
              <UserMenu align="right" />
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
