import GooglePlatformModal from "components/ConnectPlatforms/GooglePlatformModal";
import PlatformModal from "components/ConnectPlatforms/PlatformModal";
import { DeletePopup as ErrorModal } from "components/DeletePopup/DeletePopup";
import { typeObject } from "helpers/getObjectByType";
import React, { useState } from "react";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getGoogleLocations, getGoogleProfile } from "store/actions/google";
import { errorsNotification } from "store/actions/notification";
import { getPlatformProfile } from "store/actions/platform";

// const MyFacebookButton = ({ onClick }) => (
//   <div
//     className="bg-blue-600 rounded text-sm btn text-white mt-5 cursor-pointer"
//     onClick={onClick}
//   >
//     Login with Facebook
//   </div>
// );

const ConnectSource = () => {
  const dispatch = useDispatch();
  const { type } = useParams();

  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [googlePlatformModal, setGooglePlatformModal] = useState(false);
  const [platformModal, setPlatformModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [code, setCode] = useState();

  const handleSubmit = () => {
    if (name.length < 3) {
      dispatch(errorsNotification("Profile must have minimum 3 characters"));
    } else {
      switch (type) {
        case "google":
          dispatch(getGoogleProfile(name, () => setGooglePlatformModal(true)));
          break;
        case "fresha":
        case "booking":
        case "recose":
        default:
          dispatch(
            getPlatformProfile(type, name, location, () =>
              setPlatformModal(true)
            )
          );
          break;
      }
    }
  };

  const helperSpan = () => {
    switch (type) {
      case "booking":
        return (
          <div>
            For example:{" "}
            <span className="text-blue-500">
              https://www.booking.com/hotel/se/tummens-b-amp-b.sr.html
            </span>
          </div>
        );
      case "fresha":
        return (
          <div>
            For example:{" "}
            <span className="text-blue-500">
              https://www.fresha.com/a/apple-eve-london-58-kenway-road-npcy17nn
            </span>
          </div>
        );
      // case "trustpilot":
      //   return (
      //     <div>
      //       For example:{" "}
      //       <span className="text-blue-500">
      //         https://www.trustpilot.com/review/captravelassistance.com
      //       </span>
      //     </div>
      //   );
      case "bokadirekt":
        return (
          <div>
            For example: <span className="text-blue-500">Beauty By Anna</span>
          </div>
        );
      case "airbnb":
        return (
          <div>
            For example:{" "}
            <span className="text-blue-500">
              https://www.airbnb.com/rooms/14705634
            </span>
          </div>
        );
      case "yelp":
        return (
          <div>
            For example: <span className="text-blue-500">Starbucks Pickup</span>
          </div>
        );
      case "hitta":
        return (
          <div>
            For example:{" "}
            <span className="text-blue-500">
              https://www.hitta.se/petterssons+d%C3%A4ckservice/eskilstuna/hngpxeamn
            </span>
          </div>
        );
      case "google":
      default:
        return (
          <div>
            For example:{" "}
            <span className="text-blue-500">DreamClean Sweden AB</span>
          </div>
        );
    }
  };

  const label = () => {
    switch (type) {
      case "booking":
      case "fresha":
      case "hitta":
        return "Business Profile URL";
      case "google":
      default:
        return "Business Name";
    }
  };

  const handleGoogleLogin = async (googleData) => {
    setCode(googleData?.code);

    dispatch(
      getGoogleLocations(
        googleData?.code,
        () => setGooglePlatformModal(true),
        () => setErrorModal(true)
      )
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <Link
        to="/connect-platforms"
        className="bg-blue-600 hover:bg-blue-800 transition-all text-white py-2 px-4 rounded"
      >
        {"<-"} Back
      </Link>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="bg-white shadow-lg rounded-sm border sm:p-10 p-5 sm:pb-14 pb-6 border-gray-200 mt-6">
          <div className="text-2xl font-bold text-black mb-8">
            New {typeObject[type]?.title} platform 🎉
          </div>
          <div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="type">
                Type
              </label>
              <input
                id="type"
                name="type"
                type="text"
                disabled
                defaultValue={typeObject[type]?.title}
                readOnly={true}
                style={{ background: "#eceeef" }}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm"
              />
            </div>
            {/* TODO: refactor this shit */}
            {type !== "google" ? (
              type !== "facebook" ? (
                <>
                  <div className="mt-4 mb-5">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="name"
                    >
                      {label()}
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={name}
                      placeholder={label()}
                      onChange={(e) => setName(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm"
                    />
                    <div className="mt-2 text-sm overflow-hidden">
                      {helperSpan()}
                    </div>
                    {type === "yelp" && (
                      <>
                        <input
                          id="location"
                          name="location"
                          type="text"
                          value={location}
                          placeholder="Business Location"
                          onChange={(e) => setLocation(e.target.value)}
                          className="appearance-none mt-3 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm"
                        />
                        <div className="mt-2 text-sm overflow-hidden">
                          For example:{" "}
                          <span className="text-blue-500">San Francisco</span>
                        </div>
                      </>
                    )}
                  </div>

                  <button
                    type="submit"
                    // aria-controls="google-platform-modal"
                    className="bg-blue-600 hover:bg-blue-800 transition-all w-max text-white py-2 px-8 rounded"
                  >
                    Load
                  </button>
                </>
              ) : (
                <FacebookLogin
                  returnScopes
                  scope="public_profile,user_friends,user_actions.books,business_management,email,pages_manage_metadata,pages_read_engagement,pages_read_user_content,pages_show_list"
                  appId="465629335431550"
                  callback={(response) => {
                    console.log(response);
                  }}
                  cssClass="facebook-button"
                />
              )
            ) : (
              <div className="mt-3 w-48">
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  accessType="offline"
                  responseType="code"
                  className="w-full justify-center bg-blue-600 text-white"
                  buttonText="Connect Google"
                  onSuccess={handleGoogleLogin}
                  prompt="consent"
                  onFailure={() => {}}
                  cookiePolicy={"single_host_origin"}
                  scope="https://www.googleapis.com/auth/plus.business.manage"
                />
              </div>
            )}
          </div>
        </div>
      </form>
      <GooglePlatformModal
        platformModal={googlePlatformModal}
        setPlatformModalOpen={setGooglePlatformModal}
        code={code}
      />
      <PlatformModal
        platformModal={platformModal}
        setPlatformModalOpen={setPlatformModal}
        type={type}
      />
      <ErrorModal
        deletePopup={errorModal}
        setDeletePopup={setErrorModal}
        deleteBtnVisible={false}
        title={``}
        description={`<div>
        <p>
        Google says that there is no Google Business Profile connected to the email account that you are trying to connect with. This could also be caused by your domain not allowing our app to access Google Drive.
        </p>
        <p style="margin-top: 8px;">
        Please try with another account or contact your domain Admin to request access for Trustbucket.
        </p></div>`}
      />
    </div>
  );
};

export default ConnectSource;
