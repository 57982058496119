export const findOverall = (ratings, type = "overall", isWidget = null) => {
  let typeIndex = ratings?.ratings?.findIndex((el) => el.type === type);

  return {
    rating: ratings?.averageRating,
    count: ratings?.total,
  };
};

export const getStarLabel = (rating) => {
  if (rating <= 1.5) {
    return "Bad";
  } else if (rating > 1.5 && rating <= 2.9) {
    return "Poor";
  } else if (rating > 2.9 && rating <= 4) {
    return "Average";
  } else if (rating > 3.9 && rating < 4.4) {
    return "Great";
  } else {
    return "Excellent";
  }
};
