import * as actionTypes from "../actions/actionTypes";

const initialState = {
  campaignStats: null,
  invitationSettings: null,
  invitationsSent: 0,
  automaticCollectionSettings: null,
  campaignRecievers: [],
  emailPreviewContent: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CAMPAIGNS_STATS:
      return {
        ...state,
        campaignStats: action.payload,
      };
    case actionTypes.SET_INVITATION_SETTINGS:
      return {
        ...state,
        invitationSettings: action.payload,
      };
    case actionTypes.SET_INVITATION_SENT:
      return {
        ...state,
        invitationsSent: action.payload,
      };
    case actionTypes.SET_AUTOMATIC_COLLECTION_SETTINGS:
      return {
        ...state,
        automaticCollectionSettings: action.payload,
      };
    case actionTypes.SET_CAMPAIGN_RECIEVERS:
      return {
        ...state,
        campaignRecievers: action.payload,
      };
    case actionTypes.SET_EMAIL_PREVIEW_CONTENT:
      return {
        ...state,
        emailPreviewContent: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
