import { useCallback, useEffect, useRef, useState } from "react";

const reader = new FileReader();

export default function useDragAndDrop() {
  const drop = useRef(null);

  const [onUpload, setOnUpload] = useState([]);
  const [image, setImage] = useState();
  const [percentage, setPercentage] = useState(0);

  // HELPER FUNCTION:
  const imageUpload = useCallback((file) => {
    reader.onload = (e) => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file[0]);
  }, []);

  useEffect(() => {
    const dropEl = drop.current;
    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();

      const files = [...e.dataTransfer.files];

      if (files[0].type.substr(0, 5) === "image") {
        setOnUpload(files[0]);
        imageUpload(files);
      }
    };

    const handleProgress = (e) => {
      e.preventDefault();
      e.stopPropagation();
      let percent = Math.round((e.loaded / e.total) * 100);
      setPercentage(percent);
    };
    dropEl.addEventListener("dragover", handleDragOver);
    dropEl.addEventListener("drop", handleDrop);
    reader.addEventListener("progress", handleProgress);

    return () => {
      dropEl.removeEventListener("dragover", handleDragOver);
      dropEl.removeEventListener("drop", handleDrop);
      reader.removeEventListener("progress", handleProgress);
    };
  }, [imageUpload]);

  const handleFile = (e) => {
    setOnUpload(e.target.files[0]);
    imageUpload([e.target.files[0]]);
  };

  return { drop, onUpload, handleFile, image, percentage, setImage };
}
