import React, { useEffect, useState } from "react";
import logo from "icons/logo-dark.svg";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { login } from "store/actions/auth";
import clsx from "clsx";
import { useFormik } from "formik";
import * as Yup from "yup";
import { resetPassword } from "store/actions/auth";

const initialValues = {
  password: "",
  confirmPassword: "",
};

const registrationSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Password confirmation is required")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and Confirm Password didn't match"
      ),
    }),
});

function ResetPassword() {
  const dispatch = useDispatch();

  const history = useHistory();
  const [id, setId] = useState();

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const id = params.get("id");

    if (id) {
      setId(id);
    } else {
      history.push("/auth/login");
    }
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      dispatch(
        resetPassword(id, values.password, () => history.push("/login"))
      );
    },
  });

  return (
    <div className="bg-white min-h-screen">
      <div className="px-5 py-10 flex items-center justify-center">
        <a href="https://trustbucket.io">
          <img
            className="w-auto h-5 object-contain"
            src={logo}
            alt="Workflow"
          />
        </a>
      </div>
      <div className="h-full flex flex-col max-w-full sm:max-w-sm mx-auto justify-center px-5">
        <div className="font-medium text-2xl mb-4 md:text-center text-black">
          Reset your password
        </div>
        <div className="text-gray-600 md:text-center text-sm mb-10">
          Please enter new password and confirm it.
        </div>
        <div>
          <form className="space-y-4" noValidate onSubmit={formik.handleSubmit}>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                New password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Enter new password"
                  {...formik.getFieldProps("password")}
                  className={clsx(
                    "appearance-none block w-full px-4 py-3 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm",
                    {
                      "is-invalid":
                        formik.touched.password && formik.errors.password,
                    },
                    {
                      "is-valid":
                        formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <span className="text-red-600 text-xs">
                    {formik.errors.password}
                  </span>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Repeat password
              </label>
              <div className="mt-1">
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type="password"
                  placeholder="Enter repeat password"
                  {...formik.getFieldProps("confirmPassword")}
                  className={clsx(
                    "appearance-none block w-full px-4 py-3 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm",
                    {
                      "is-invalid":
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword,
                    },
                    {
                      "is-valid":
                        formik.touched.confirmPassword &&
                        !formik.errors.confirmPassword,
                    }
                  )}
                />
                {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword && (
                    <span className="text-red-600 text-xs">
                      {formik.errors.confirmPassword}
                    </span>
                  )}
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-3 px-4 border border-transparent rounded shadow-sm transition-colors font-medium text-white bg-blue-600 hover:bg-blue-700"
              >
                Reset password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
