import ModalBasic from "components/ModalBasic";
import React, { useEffect, useState } from "react";
import checkboxGreen from "icons/checkbox-green.svg";
import { Link } from "react-router-dom";

export default function IframeModal({
  popup,
  setPopup,
  title,
  iframeCode,
  id,
}) {
  const [copied, setCopied] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      let tm = setTimeout(() => setCopied(false), 3000);

      return () => clearTimeout(tm);
    }
  }, [copied]);

  useEffect(() => {
    if (linkCopied) {
      let tm = setTimeout(() => setLinkCopied(false), 3000);

      return () => clearTimeout(tm);
    }
  }, [linkCopied]);

  return (
    <ModalBasic
      id="iframe-modal"
      modalOpen={popup}
      setModalOpen={setPopup}
      title={title}
    >
      <div>
        <div className="px-4 pt-2 text-black font-medium">
          Add this code to the HTML of your website where you’re displaying your
          Widget.
        </div>
        <div className="p-4">
          <div
          // className="border border-gray-200 rounded-sm p-5"
          >
            <textarea
              className="form-textarea w-full p-3 h-40 mb-3"
              value={
                `<script>window.addEventListener('message', (ev) => {let iframe = document.getElementById('trustbucket-${id}'); if(ev.data.type && ev.data.type === 'resize-iframe-trustbucket-${id}') {iframe.style.height = ev.data.payload.height + 'px'}}, false)</script>` +
                iframeCode
              }
              disabled
              // style={{ resize: "none" }}
            ></textarea>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <button
                  onClick={() => {
                    setCopied(true);
                    navigator.clipboard.writeText(
                      `<script>window.addEventListener('message', (ev) => {let iframe = document.getElementById('trustbucket-${id}'); if(ev.data.type && ev.data.type === 'resize-iframe-trustbucket-${id}') {iframe.style.height = ev.data.payload.height + 'px'}}, false)</script>` +
                        iframeCode
                    );
                  }}
                  className="btn bg-blue-600 text-white transition-colors hover:bg-blue-600"
                >
                  Copy code
                </button>
                {copied && (
                  <div className="flex items-center ml-3">
                    <img src={checkboxGreen} alt="checkbox" />
                    <div className="text-green-600 text-sm ml-1.5">Copied</div>
                  </div>
                )}
              </div>
              <Link
                to="/dashboard"
                className="btn bg-white border border-gray-300"
              >
                Go Home
              </Link>
            </div>
          </div>
        </div>
        {/* Modal footer */}
        {/* <div className="px-5 py-4 border-t border-gray-200">
        <div className="flex flex-wrap justify-end space-x-2">
          <div
            className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setPopup(false);
            }}
          >
            Cancel
          </div>
          <button
            type="submit"
            className="btn-sm bg-blue-500 hover:bg-blue-600 text-white"
            onClick={handleConnect}
          >
            Connect
          </button>
        </div>
      </div> */}
      </div>
    </ModalBasic>
  );
}
