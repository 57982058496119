import React from "react";

const RatingStars = ({ rating }) => {
  return (
    <div className="flex space-x-1 -mx-1">
      <span className="sr-only">1 star</span>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="0.0174255"
          width="25.965"
          height="25.965"
          rx="3.09107"
          fill={rating > 0.5 ? "#2563EB" : "#DCDCE6"}
        />
        <path
          d="M12.424 5.71658C12.535 5.35943 12.8541 5.11792 13.2133 5.11792C13.5725 5.11792 13.8904 5.35943 14.0013 5.71658L15.3927 10.1919C15.4338 10.323 15.5141 10.4378 15.6209 10.5193C15.7285 10.6009 15.8584 10.6451 15.9904 10.6451L20.4983 10.6429C20.8575 10.6429 21.1753 10.8844 21.2862 11.2417C21.3972 11.5979 21.2758 11.9883 20.9853 12.2088L17.3384 14.9735C17.2307 15.0551 17.1505 15.1698 17.1094 15.3011C17.068 15.4334 17.0691 15.5757 17.1094 15.707L18.5038 20.1809C18.6147 20.5382 18.4932 20.9286 18.2027 21.1492C17.9121 21.3697 17.518 21.3697 17.2275 21.1492L13.583 18.3823C13.4752 18.3006 13.3465 18.2564 13.2133 18.2564C13.0802 18.2564 12.9512 18.3006 12.8435 18.3823L9.19786 21.1492C8.90729 21.3697 8.51437 21.3697 8.22385 21.1492C7.93238 20.9286 7.81081 20.5382 7.92287 20.1809L9.31619 15.707C9.35734 15.5757 9.35734 15.4334 9.31619 15.3011C9.27596 15.1699 9.19576 15.0551 9.08803 14.9735L5.44139 12.2088C5.15087 11.9883 5.02835 11.5979 5.13913 11.2417C5.25118 10.8844 5.56924 10.6429 5.92839 10.6429L10.4349 10.6451C10.568 10.6451 10.6979 10.6009 10.8047 10.5193C10.9124 10.4378 10.9926 10.323 11.0328 10.1919L12.424 5.71658Z"
          fill="white"
        />
      </svg>
      <span className="sr-only">2 stars</span>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="0.0174255"
          width="25.965"
          height="25.965"
          rx="3.09107"
          fill={rating > 1.5 ? "#2563EB" : "#DCDCE6"}
        />
        <path
          d="M12.424 5.71658C12.535 5.35943 12.8541 5.11792 13.2133 5.11792C13.5725 5.11792 13.8904 5.35943 14.0013 5.71658L15.3927 10.1919C15.4338 10.323 15.5141 10.4378 15.6209 10.5193C15.7285 10.6009 15.8584 10.6451 15.9904 10.6451L20.4983 10.6429C20.8575 10.6429 21.1753 10.8844 21.2862 11.2417C21.3972 11.5979 21.2758 11.9883 20.9853 12.2088L17.3384 14.9735C17.2307 15.0551 17.1505 15.1698 17.1094 15.3011C17.068 15.4334 17.0691 15.5757 17.1094 15.707L18.5038 20.1809C18.6147 20.5382 18.4932 20.9286 18.2027 21.1492C17.9121 21.3697 17.518 21.3697 17.2275 21.1492L13.583 18.3823C13.4752 18.3006 13.3465 18.2564 13.2133 18.2564C13.0802 18.2564 12.9512 18.3006 12.8435 18.3823L9.19786 21.1492C8.90729 21.3697 8.51437 21.3697 8.22385 21.1492C7.93238 20.9286 7.81081 20.5382 7.92287 20.1809L9.31619 15.707C9.35734 15.5757 9.35734 15.4334 9.31619 15.3011C9.27596 15.1699 9.19576 15.0551 9.08803 14.9735L5.44139 12.2088C5.15087 11.9883 5.02835 11.5979 5.13913 11.2417C5.25118 10.8844 5.56924 10.6429 5.92839 10.6429L10.4349 10.6451C10.568 10.6451 10.6979 10.6009 10.8047 10.5193C10.9124 10.4378 10.9926 10.323 11.0328 10.1919L12.424 5.71658Z"
          fill="white"
        />
      </svg>
      <span className="sr-only">3 stars</span>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="0.0174255"
          width="25.965"
          height="25.965"
          rx="3.09107"
          fill={rating > 2.5 ? "#2563EB" : "#DCDCE6"}
        />
        <path
          d="M12.424 5.71658C12.535 5.35943 12.8541 5.11792 13.2133 5.11792C13.5725 5.11792 13.8904 5.35943 14.0013 5.71658L15.3927 10.1919C15.4338 10.323 15.5141 10.4378 15.6209 10.5193C15.7285 10.6009 15.8584 10.6451 15.9904 10.6451L20.4983 10.6429C20.8575 10.6429 21.1753 10.8844 21.2862 11.2417C21.3972 11.5979 21.2758 11.9883 20.9853 12.2088L17.3384 14.9735C17.2307 15.0551 17.1505 15.1698 17.1094 15.3011C17.068 15.4334 17.0691 15.5757 17.1094 15.707L18.5038 20.1809C18.6147 20.5382 18.4932 20.9286 18.2027 21.1492C17.9121 21.3697 17.518 21.3697 17.2275 21.1492L13.583 18.3823C13.4752 18.3006 13.3465 18.2564 13.2133 18.2564C13.0802 18.2564 12.9512 18.3006 12.8435 18.3823L9.19786 21.1492C8.90729 21.3697 8.51437 21.3697 8.22385 21.1492C7.93238 20.9286 7.81081 20.5382 7.92287 20.1809L9.31619 15.707C9.35734 15.5757 9.35734 15.4334 9.31619 15.3011C9.27596 15.1699 9.19576 15.0551 9.08803 14.9735L5.44139 12.2088C5.15087 11.9883 5.02835 11.5979 5.13913 11.2417C5.25118 10.8844 5.56924 10.6429 5.92839 10.6429L10.4349 10.6451C10.568 10.6451 10.6979 10.6009 10.8047 10.5193C10.9124 10.4378 10.9926 10.323 11.0328 10.1919L12.424 5.71658Z"
          fill="white"
        />
      </svg>
      <span className="sr-only">4 stars</span>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="0.0174255"
          width="25.965"
          height="25.965"
          rx="3.09107"
          fill={rating > 3.5 ? "#2563EB" : "#DCDCE6"}
        />
        <path
          d="M12.424 5.71658C12.535 5.35943 12.8541 5.11792 13.2133 5.11792C13.5725 5.11792 13.8904 5.35943 14.0013 5.71658L15.3927 10.1919C15.4338 10.323 15.5141 10.4378 15.6209 10.5193C15.7285 10.6009 15.8584 10.6451 15.9904 10.6451L20.4983 10.6429C20.8575 10.6429 21.1753 10.8844 21.2862 11.2417C21.3972 11.5979 21.2758 11.9883 20.9853 12.2088L17.3384 14.9735C17.2307 15.0551 17.1505 15.1698 17.1094 15.3011C17.068 15.4334 17.0691 15.5757 17.1094 15.707L18.5038 20.1809C18.6147 20.5382 18.4932 20.9286 18.2027 21.1492C17.9121 21.3697 17.518 21.3697 17.2275 21.1492L13.583 18.3823C13.4752 18.3006 13.3465 18.2564 13.2133 18.2564C13.0802 18.2564 12.9512 18.3006 12.8435 18.3823L9.19786 21.1492C8.90729 21.3697 8.51437 21.3697 8.22385 21.1492C7.93238 20.9286 7.81081 20.5382 7.92287 20.1809L9.31619 15.707C9.35734 15.5757 9.35734 15.4334 9.31619 15.3011C9.27596 15.1699 9.19576 15.0551 9.08803 14.9735L5.44139 12.2088C5.15087 11.9883 5.02835 11.5979 5.13913 11.2417C5.25118 10.8844 5.56924 10.6429 5.92839 10.6429L10.4349 10.6451C10.568 10.6451 10.6979 10.6009 10.8047 10.5193C10.9124 10.4378 10.9926 10.323 11.0328 10.1919L12.424 5.71658Z"
          fill="white"
        />
      </svg>
      <span className="sr-only">5 stars</span>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="0.0174255"
          width="25.965"
          height="25.965"
          rx="3.09107"
          fill={rating > 4.5 ? "#2563EB" : "#DCDCE6"}
        />
        <path
          d="M12.424 5.71658C12.535 5.35943 12.8541 5.11792 13.2133 5.11792C13.5725 5.11792 13.8904 5.35943 14.0013 5.71658L15.3927 10.1919C15.4338 10.323 15.5141 10.4378 15.6209 10.5193C15.7285 10.6009 15.8584 10.6451 15.9904 10.6451L20.4983 10.6429C20.8575 10.6429 21.1753 10.8844 21.2862 11.2417C21.3972 11.5979 21.2758 11.9883 20.9853 12.2088L17.3384 14.9735C17.2307 15.0551 17.1505 15.1698 17.1094 15.3011C17.068 15.4334 17.0691 15.5757 17.1094 15.707L18.5038 20.1809C18.6147 20.5382 18.4932 20.9286 18.2027 21.1492C17.9121 21.3697 17.518 21.3697 17.2275 21.1492L13.583 18.3823C13.4752 18.3006 13.3465 18.2564 13.2133 18.2564C13.0802 18.2564 12.9512 18.3006 12.8435 18.3823L9.19786 21.1492C8.90729 21.3697 8.51437 21.3697 8.22385 21.1492C7.93238 20.9286 7.81081 20.5382 7.92287 20.1809L9.31619 15.707C9.35734 15.5757 9.35734 15.4334 9.31619 15.3011C9.27596 15.1699 9.19576 15.0551 9.08803 14.9735L5.44139 12.2088C5.15087 11.9883 5.02835 11.5979 5.13913 11.2417C5.25118 10.8844 5.56924 10.6429 5.92839 10.6429L10.4349 10.6451C10.568 10.6451 10.6979 10.6009 10.8047 10.5193C10.9124 10.4378 10.9926 10.323 11.0328 10.1919L12.424 5.71658Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default RatingStars;
