import ConnectedPlatform from "components/ConnectPlatforms/ConnectedPlatform";
import UnconnectedPlatform from "components/ConnectPlatforms/UnconnectedPlatform";
import UpcomingPlatform from "components/ConnectPlatforms/UpcomingPlatform";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const allPlatforms = [
  "google",
  "yelp",
  // "trustpilot",
  "recose",
  "fresha",
  "booking",
  "bokadirekt",
  "hitta",
];

const upcomingPlatforms = ["facebook", "tripadvisor", "airbnb"];

const ConnectPlatforms = () => {
  const user = useSelector((state) => state.auth.account);
  const [unconnected, setUnconnected] = useState(allPlatforms);

  useEffect(() => {
    if (user && user.selectedCompany) {
      let connected = user.selectedCompany.ratings;
      let newUconnected = [];

      allPlatforms.forEach((element) => {
        if (!connected.find((el) => el.type === element)) {
          newUconnected = [...newUconnected, element];
        }
      });

      setUnconnected(newUconnected);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <div className="bg-white shadow-lg rounded-sm border p-6 sm:p-10 pb-14 border-gray-200">
        <div className="text-2xl font-bold text-black mb-8">
          Connected platforms 🎉
        </div>
        <div className="grid grid-cols-12 gap-6 pb-8">
          {/* {user?.selectedCompany?.ratings?.length <= 1 && (
            <div className="absolute italic">No connected platform yet...</div>
          )} */}
          {user?.selectedCompany?.ratings?.map((item, index) => {
            if (item.type !== "overall") {
              return (
                <ConnectedPlatform
                  key={index}
                  data={item}
                  isPopular={
                    item?.type !== "fresha" && item?.type !== "booking"
                  }
                />
              );
            } else {
              return null;
            }
          })}
        </div>
        <hr />
        <div className="text-2xl font-bold text-black my-8">Not connected</div>
        <div className="grid grid-cols-12 gap-6 pb-8">
          {unconnected.map((item, index) => (
            <UnconnectedPlatform
              key={index}
              platformType={item}
              isPopular={
                item !== "fresha" && item !== "booking" && item !== "hitta"
              }
            />
          ))}
        </div>
        <hr />
        <div className="text-2xl font-bold text-black my-8">Upcoming</div>
        <div className="grid grid-cols-12 gap-6 pb-8">
          {upcomingPlatforms.map((item, index) => (
            <UpcomingPlatform key={index} platformType={item} isPopular />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ConnectPlatforms;
