import React from "react";
// import trustbucket from "icons/mini-logo.svg";
import recoLogo from "icons/reco-logo-big.svg";
import googleLogo from "icons/google-logo-big.svg";
import bookingLogo from "icons/booking-logo-big.png";
import freshaLogo from "icons/fresha.png";
import bokadirektLogo from "icons/bokadirekt.png";
import hitta from "icons/hitta.svg";
import trustbucketWidget from "icons/trustbucketWidget.svg";

export default function WidgetPlatformLogo({ platform, size = "small" }) {
  if (platform === "recose") {
    return (
      <img
        src={recoLogo}
        alt="Reco.se"
        className={size === "small" ? "w-28" : "w-40"}
      />
    );
  } else if (platform === "google") {
    return (
      <img
        src={googleLogo}
        alt="Google"
        className={size === "small" ? "w-24" : "w-40"}
      />
    );
  } else if (platform === "booking") {
    return (
      <img
        src={bookingLogo}
        alt="Booking"
        className={size === "small" ? "w-28" : "w-40"}
      />
    );
  } else if (platform === "fresha") {
    return (
      <img
        src={freshaLogo}
        alt="Fresha"
        className={size === "small" ? "w-28" : "w-40"}
      />
    );
  } else if (platform === "bokadirekt") {
    return (
      <img
        src={bokadirektLogo}
        alt="Bokadirekt"
        className={size === "small" ? "w-28" : "w-40"}
      />
    );
  } else if (platform === "hitta") {
    return <img src={hitta} alt="Hitta" className={"h-12"} />;
  } else {
    return (
      <img src={trustbucketWidget} alt="trustbucket" />
      // <div className="flex items-center">
      //   <img
      //     src={trustbucket}
      //     className={size === "small" ? "h-5" : "h-10"}
      //     alt="Logo"
      //   />
      //   <div
      //     className="ml-1.5 font-medium text-sm"
      //     style={{ color: "#1F2937" }}
      //   >
      //     Trustbucket
      //   </div>
      // </div>
    );
  }
}
