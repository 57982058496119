import WidgetHtmlBadge from "components/WidgetConfigurator/WidgetTypes/Badge/WidgetHtmlBadge/WidgetHtmlBagde";
import WidgetButton from "components/WidgetConfigurator/WidgetTypes/Button/WidgetButton";
import WidgetGrid from "components/WidgetConfigurator/WidgetTypes/Grid/WidgetGrid";
import WidgetList from "components/WidgetConfigurator/WidgetTypes/List/WidgetList";
import WidgetSlider from "components/WidgetConfigurator/WidgetTypes/Slider/WidgetSlider";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getWidget } from "store/actions/widget";

export default function WidgetScreen() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const widget = useSelector((state) => state.widget.widget);
  const ratingsFromWidget = useSelector((state) => state.widget.widgetRatings);

  useEffect(() => {
    dispatch(getWidget(id, () => {}));
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    document.body.style.background = "transparent";
    // document.querySelector("main").style.paddingTop = "0";
    let timeout = setTimeout(() => {
      let el = document.getElementById("root");
      let elRect = el.getBoundingClientRect();

      var elHeight = el.offsetHeight;

      window.parent.postMessage(
        {
          type: `resize-iframe-${widget?.attributes?.id}`,
          payload: {
            width: elRect.width,
            height: elHeight,
          },
        },
        "*"
      );
    }, 10);

    return () => {
      clearTimeout(timeout);
    };
  }, [widget, ratingsFromWidget]);

  if (!widget) {
    return null;
  }

  return (
    <div className="flex flex-col items-center py-2.5">
      {widget.object?.type === "slider" && widget.object && (
        <WidgetSlider widgetStyle={widget.object} widget={widget} />
      )}
      {widget.object?.type === "badge" && widget.object && (
        <WidgetHtmlBadge widgetStyle={widget.object} widget={widget} />
      )}
      {widget.object?.type === "grid" && widget.object && (
        <WidgetGrid widgetStyle={widget.object} widget={widget} />
      )}
      {widget.object?.type === "list" && widget.object && (
        <WidgetList widgetStyle={widget.object} widget={widget} />
      )}
      {widget.object?.type === "button" && widget.object && (
        <WidgetButton widgetStyle={widget.object} widget={widget} />
      )}
      {widget.object?.type === "collectReviewsButton" && widget.object && (
        <WidgetButton widgetStyle={widget.object} widget={widget} />
      )}
    </div>
  );
}
