import React from "react";

import SettingsSidebar from "partials/settings/SettingsSidebar";
import FeedbackPanel from "partials/settings/FeedbackPanel";

function Feedback() {
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Page header */}
      <div className="mb-4 sm:mb-8">
        {/* Title */}
        <h1 className="text-xl md:text-3xl text-gray-800 font-bold">
          Account settings ⚙️
        </h1>
      </div>

      {/* Content */}
      <div className="bg-white shadow-lg rounded-sm mb-8">
        <div className="flex flex-col md:flex-row md:-mr-px">
          <SettingsSidebar />
          <FeedbackPanel />
        </div>
      </div>
    </div>
  );
}

export default Feedback;
