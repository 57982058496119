import { typeObject } from "helpers/getObjectByType";
import React, { useMemo } from "react";
import LineChart from "../../charts/LineChart01";

// Import utilities
import { tailwindConfig, hexToRGB } from "../../utils/Utils";
import { useSelector } from "react-redux";

function CardPlatform({ platformType = "google", rating, ratingsLength }) {
  const stats = useSelector((state) => state.stats);

  let chartData = null;
  if (stats.labels && stats.overall && stats[platformType]) {
    chartData = {
      labels: stats.labels,
      datasets: [
        // blue line
        {
          data: stats[platformType],
          fill: true,
          backgroundColor: `rgba(${hexToRGB(
            tailwindConfig().theme.colors.blue[500]
          )}, 0.08)`,
          borderColor: tailwindConfig().theme.colors.blue[500],
          borderWidth: 2,
          tension: 0,
          pointRadius: 0,
          pointHoverRadius: 3,
          pointBackgroundColor: tailwindConfig().theme.colors.blue[500],
          clip: 20,
        },
        // Gray line
        {
          data: stats.overall,
          borderColor: tailwindConfig().theme.colors.gray[300],
          borderWidth: 2,
          tension: 0,
          pointRadius: 0,
          pointHoverRadius: 3,
          pointBackgroundColor: tailwindConfig().theme.colors.gray[300],
          clip: 20,
        },
      ],
    };
  }

  const formatRating = useMemo(() => {
    if (!rating) {
      return "N/A";
    }

    if (platformType === "booking") {
      return rating.toFixed(1) * 2;
    }

    return rating.toFixed(1);
  }, [rating, platformType]);

  if (platformType === "trustpilot") return null;

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <div className="px-5 pt-5">
        <header className="flex justify-between items-start mb-2"></header>
        <div className="flex items-center mb-4">
          <img
            src={typeObject[platformType]?.image}
            alt="Platform"
            className="h-12 object-contain"
            style={{ maxWidth: 110 }}
          />
          <h2 className="text-xl font-semibold text-gray-800 ml-3">
            {typeObject[platformType]?.title}
          </h2>
        </div>
        <div className="flex items-start">
          <div className="text-xl text-gray-800 mr-2">
            <span className="text-4xl font-bold">{formatRating}</span> /{" "}
            {platformType === "booking" ? "10" : "5"} based on{" "}
            <span className="font-bold">{ratingsLength}</span> reviews
          </div>
        </div>
      </div>
      {/* Chart built with Chart.js 3 */}
      <div className="grow">
        {/* Change the height attribute to adjust the chart height */}
        {chartData ? (
          <LineChart data={chartData} width={389} height={128} />
        ) : (
          <div className="bg-white" style={{ height: "128px" }}></div>
        )}
      </div>
    </div>
  );
}

export default React.memo(CardPlatform);
