import React, { useMemo, useState } from "react";

import Sidebar from "partials/Sidebar";
import Header from "partials/Header";

import { useLocation } from "react-router-dom";

function Layout({ children }) {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const displayNavigation = useMemo(() => {
    return !location.pathname.includes("widget-configurator");
  }, [location]);

  return (
    <div className="flex min-h-screen ">
      {/* Sidebar */}
      {displayNavigation && (
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      )}

      {/* Content area */}
      <div className="scroll-top relative flex flex-col flex-1 sm:overflow-y-visible overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        {displayNavigation && (
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        )}

        <main
          className="relative sm:pt-0 pt-16"
          // style={{ height: "calc(100vh - 64px)" }}
        >
          {children}
        </main>
      </div>
    </div>
  );
}

export default Layout;
