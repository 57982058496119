import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getInvitationSettings,
  setEmailPreviewContent,
} from "store/actions/campaign";
import { getEmailTemplates } from "store/actions/emailTemplates";

const Step2 = ({
  chosenTemplate,
  setChosenTempate,
  onSubmit,
  onBack,
  reminder,
  setReminder,
  handleCreateCampaign,
}) => {
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.emailTemplates.emailTemplates);
  const adminId = localStorage.getItem("id");
  const companies = useSelector((state) => state.auth.selectedCompany);
  const businessId = companies?.id;
  // const invitationSettings = useSelector(
  //   (state) => state.campaign.invitationSettings
  // );

  useEffect(() => {
    dispatch(getEmailTemplates(adminId));
    dispatch(getInvitationSettings(businessId));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="mt-14">
      <div className="max-w-md mx-auto">
        {templates.map((item, index) => (
          <div
            key={index}
            className={
              "rounded mb-3  border text-sm font-medium flex items-center justify-between cursor-pointer " +
              (item.id === chosenTemplate
                ? " border-blue-600"
                : "border-gray-200")
            }
            onClick={() => setChosenTempate(item.id)}
          >
            <div className="flex items-center py-4 px-4">
              <span
                className={
                  "block w-4 h-4 rounded-full mr-5 " +
                  (item.id === chosenTemplate
                    ? "border-4 border-blue-500"
                    : "border-2 border-gray-500")
                }
                style={{ minWidth: 16 }}
              ></span>
              <div>{item.name}</div>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setEmailPreviewContent(item.content));
              }}
              className="text-blue-600 underline flex justify-end text-sm py-4 pr-6 pl-2"
            >
              Preview
            </div>
          </div>
        ))}
        {templates.length === 0 && (
          <div className="flex items-center">
            <div className="text-sm text-gray-600">
              No email templates added. To create campaign you must have one.
              <br />
              <Link
                to="/template-editor/new-template"
                className="text-blue-600 underline"
              >
                Create template
              </Link>
            </div>
          </div>
        )}

        {templates.length !== 0 && (
          <div className="mt-9 flex items-center justify-between">
            <div className="w-9/12">
              <div className="text-sm mb-1 text-black">
                Send reminder email after 48 hours?
              </div>
              <div className="flex items-center">
                <div className="text-xs text-gray-600">
                  The email will only be sent to your customers who have not
                  written a review. This can increase your chances to get a
                  review.
                </div>
              </div>
            </div>
            <div className="form-switch">
              <input
                type="checkbox"
                id="switch-1"
                className="sr-only"
                checked={reminder}
                onChange={() => setReminder(!reminder)}
              />
              <label className="bg-gray-400" htmlFor="switch-1">
                <span className="bg-white shadow-sm" aria-hidden="true"></span>
                <span className="sr-only">Switch label</span>
              </label>
            </div>
          </div>
        )}
        <div className="flex items-center justify-between mt-12">
          <div
            className="text-gray-600 border-b border-gray-500 cursor-pointer text-sm"
            onClick={onBack}
          >
            {"<-"} Back
          </div>

          <button
            className={
              "btn bg-blue-600 hover:bg-blue-700 transition-all text-white" +
              (!chosenTemplate ? " opacity-70 pointer-events-none" : "")
            }
            disabled={!chosenTemplate}
            onClick={onSubmit}
          >
            Send invites
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step2;
