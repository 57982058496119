import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Transition from "../utils/Transition";

function DropdownHelp({ align }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="relative inline-flex">
      {/* <button
				ref={trigger}
				className={`w-8 h-8 flex items-center justify-center bg-gray-100 hover:bg-gray-200 transition duration-150 rounded-full ${dropdownOpen && 'bg-gray-200'}`}
				aria-haspopup="true"
				onClick={() => setDropdownOpen(!dropdownOpen)}
				aria-expanded={dropdownOpen}
			>
				<span className="sr-only">Need help?</span>
				<svg width="20" height="18" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8.2002 0C3.8002 0 0.200195 3.6 0.200195 8C0.200195 12.4 3.8002 16 8.2002 16C12.6002 16 16.2002 12.4 16.2002 8C16.2002 3.6 12.6002 0 8.2002 0Z" fill="#64748B" />
					<path d="M7.84082 9.16983V9.1108C7.84496 8.7255 7.88329 8.41892 7.95579 8.19105C8.03036 7.96319 8.13601 7.77882 8.27273 7.63796C8.40945 7.4971 8.57413 7.36867 8.76678 7.25266C8.89107 7.17395 9.00293 7.08591 9.10236 6.98855C9.20179 6.89119 9.28051 6.77933 9.33851 6.65297C9.39651 6.5266 9.42552 6.38678 9.42552 6.23349C9.42552 6.04912 9.38201 5.88962 9.29501 5.75497C9.20801 5.62032 9.092 5.51675 8.947 5.44425C8.80407 5.36967 8.64456 5.33239 8.46848 5.33239C8.30898 5.33239 8.15672 5.36553 8.01172 5.43182C7.86671 5.49811 7.74657 5.60168 7.65128 5.74254C7.55599 5.88133 7.50109 6.06052 7.48659 6.2801H6.54199C6.55649 5.90723 6.65075 5.59236 6.82475 5.33549C6.99876 5.07656 7.22869 4.8808 7.51456 4.74822C7.8025 4.61565 8.12047 4.54936 8.46848 4.54936C8.84964 4.54936 9.18315 4.62083 9.46902 4.76376C9.75488 4.90462 9.97653 5.10245 10.134 5.35724C10.2935 5.60997 10.3732 5.90516 10.3732 6.24281C10.3732 6.47482 10.337 6.68404 10.2645 6.87047C10.192 7.05484 10.0884 7.21952 9.95375 7.36452C9.82117 7.50953 9.66167 7.63796 9.47523 7.74982C9.29915 7.85961 9.15622 7.97354 9.04643 8.09162C8.93871 8.20969 8.86 8.34952 8.81028 8.5111C8.76056 8.67267 8.73364 8.87257 8.72949 9.1108V9.16983H7.84082ZM8.31001 11.059C8.14015 11.059 7.99411 10.999 7.87189 10.8788C7.74967 10.7566 7.68857 10.6095 7.68857 10.4376C7.68857 10.2677 7.74967 10.1227 7.87189 10.0026C7.99411 9.88036 8.14015 9.81925 8.31001 9.81925C8.47781 9.81925 8.62281 9.88036 8.74503 10.0026C8.86932 10.1227 8.93146 10.2677 8.93146 10.4376C8.93146 10.5515 8.90246 10.6561 8.84446 10.7514C8.78853 10.8446 8.71396 10.9192 8.62074 10.9751C8.52752 11.0311 8.42395 11.059 8.31001 11.059Z" fill="white" />
				</svg>
			</button> */}

      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-44 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
          align === "right" ? "right-0" : "left-0"
        }`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="text-xs font-semibold text-gray-400 uppercase pt-1.5 pb-2 px-4">
            Need help?
          </div>
          <ul>
            <li>
              <Link
                className="font-medium text-sm text-blue-500 hover:text-blue-600 flex items-center py-1 px-3"
                to="#0"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <svg
                  className="w-3 h-3 fill-current text-blue-300 shrink-0 mr-2"
                  viewBox="0 0 12 12"
                >
                  <rect y="3" width="12" height="9" rx="1" />
                  <path d="M2 0h8v2H2z" />
                </svg>
                <span>Documentation</span>
              </Link>
            </li>
            <li>
              <Link
                className="font-medium text-sm text-blue-500 hover:text-blue-600 flex items-center py-1 px-3"
                to="#0"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <svg
                  className="w-3 h-3 fill-current text-blue-300 shrink-0 mr-2"
                  viewBox="0 0 12 12"
                >
                  <path d="M10.5 0h-9A1.5 1.5 0 000 1.5v9A1.5 1.5 0 001.5 12h9a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0010.5 0zM10 7L8.207 5.207l-3 3-1.414-1.414 3-3L5 2h5v5z" />
                </svg>
                <span>Support Site</span>
              </Link>
            </li>
            <li>
              <Link
                className="font-medium text-sm text-blue-500 hover:text-blue-600 flex items-center py-1 px-3"
                to="#0"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <svg
                  className="w-3 h-3 fill-current text-blue-300 shrink-0 mr-2"
                  viewBox="0 0 12 12"
                >
                  <path d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z" />
                </svg>
                <span>Contact us</span>
              </Link>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  );
}

export default DropdownHelp;
