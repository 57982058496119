import ModalBasic from "components/ModalBasic";
import React from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updatePassword } from "store/actions/auth";
import { useDispatch } from "react-redux";

const initialValues = {
  oldPassword: "",
  password: "",
  repeatPassword: "",
};

const registrationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  repeatPassword: Yup.string()
    .required("Password confirmation is required")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "New Password and Confirm Password didn't match"
      ),
    }),
});

const ChangePassModal = ({ changePassModalOpen, setChangePassModalOpen }) => {
  const dispatch = useDispatch();
  const adminId = localStorage.getItem("id");

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      dispatch(
        updatePassword(
          adminId,
          { newPassword: values.password, password: values.oldPassword },
          changePasswordcallback,
          () => {}
        )
      );
    },
  });

  const changePasswordcallback = () => {
    setChangePassModalOpen(false);
    formik.resetForm();
  };

  return (
    <ModalBasic
      id="password-modal"
      modalOpen={changePassModalOpen}
      setModalOpen={setChangePassModalOpen}
      title="Change Password"
    >
      <form noValidate onSubmit={formik.handleSubmit}>
        {/* Modal content */}
        <div className="px-5 py-4">
          <div className="space-y-3">
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Old password <span className="text-red-500">*</span>
              </label>
              <input
                id="oldPassword"
                name="oldPassword"
                type="password"
                {...formik.getFieldProps("oldPassword")}
                className={clsx(
                  "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm",
                  {
                    "is-invalid":
                      formik.touched.oldPassword && formik.errors.oldPassword,
                  },
                  {
                    "is-valid":
                      formik.touched.oldPassword && !formik.errors.oldPassword,
                  }
                )}
              />
              {formik.touched.oldPassword && formik.errors.oldPassword && (
                <span className="text-red-600 text-xs">
                  {formik.errors.oldPassword}
                </span>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="email">
                New password <span className="text-red-500">*</span>
              </label>
              <input
                id="password"
                name="password"
                type="password"
                {...formik.getFieldProps("password")}
                className={clsx(
                  "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm",
                  {
                    "is-invalid":
                      formik.touched.password && formik.errors.password,
                  },
                  {
                    "is-valid":
                      formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <span className="text-red-600 text-xs">
                  {formik.errors.password}
                </span>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="email">
                Repeat new password <span className="text-red-500">*</span>
              </label>
              <input
                id="repeatPassword"
                name="repeatPassword"
                type="password"
                {...formik.getFieldProps("repeatPassword")}
                className={clsx(
                  "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm",
                  {
                    "is-invalid":
                      formik.touched.repeatPassword &&
                      formik.errors.repeatPassword,
                  },
                  {
                    "is-valid":
                      formik.touched.repeatPassword &&
                      !formik.errors.repeatPassword,
                  }
                )}
              />
              {formik.touched.repeatPassword &&
                formik.errors.repeatPassword && (
                  <span className="text-red-600 text-xs">
                    {formik.errors.repeatPassword}
                  </span>
                )}
            </div>
          </div>
        </div>
        {/* Modal footer */}
        <div className="px-5 py-4 border-t border-gray-200">
          <div className="flex flex-wrap justify-end space-x-2">
            <div
              className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setChangePassModalOpen(false);
              }}
            >
              Cancel
            </div>
            <button
              type="submit"
              className="btn-sm bg-blue-500 hover:bg-blue-600 text-white"
              // disabled={!formik.isValid}
            >
              Change
            </button>
          </div>
        </div>
      </form>
    </ModalBasic>
  );
};

export default ChangePassModal;
