import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function AutomaticCollectionSidebar() {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="flex md:flex-col flex-row">
      <Link
        className={
          "block md:mb-4 md:mr-0 mr-3 text-sm " +
          (pathname === "/collect-reviews/automatic-collection"
            ? "text-blue-600"
            : "text-gray-600")
        }
        to="/collect-reviews/automatic-collection"
      >
        How it works
      </Link>
      <Link
        className={
          "block text-sm " +
          (pathname === "/collect-reviews/automatic-collection-setup"
            ? "text-blue-600"
            : "text-gray-600")
        }
        to="/collect-reviews/automatic-collection-setup"
      >
        Set up
      </Link>
    </div>
  );
}
