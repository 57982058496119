import React from "react";

export default function EmailThank({ email }) {
  return (
    <div
      className="flex flex-col items-center bg-white p-10 rounded-2xl"
      // style={{ minHeight: 600, paddingTop: 50 }}
    >
      <div className="text-6xl">
        <svg
          width="100"
          height="101"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M91.6669 46.6668V50.5001C91.6617 59.4852 88.7523 68.2279 83.3724 75.4244C77.9925 82.6208 70.4305 87.8854 61.8142 90.433C53.1978 92.9806 43.9887 92.6747 35.5605 89.5609C27.1322 86.447 19.9362 80.6922 15.0458 73.1545C10.1554 65.6169 7.83263 56.7004 8.42382 47.7347C9.01501 38.7691 12.4885 30.2348 18.3263 23.4046C24.1641 16.5743 32.0534 11.8142 40.8176 9.83403C49.5818 7.85388 58.7513 8.75982 66.9585 12.4168"
            stroke="#2563EB"
            strokeWidth="4.7917"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M91.6667 17.1666L50 58.875L37.5 46.375"
            stroke="#2563EB"
            strokeWidth="4.7917"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="font-medium text-2xl mt-10 mb-4 text-gray-800">
        We’ve sent you an email.
      </div>
      <div className="text-sm text-gray-600 max-w-xs mx-auto text-center">
        Check your email, <span className="font-medium">{email}</span> and click
        the link in the email to activate your account.
      </div>
    </div>
  );
}
