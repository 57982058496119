import * as actionTypes from "./actionTypes";
import axios from "config/axios";
// import { getStats } from "../actions/stats";
import { startLoading, finishLoading } from "./loader";
import { errorsNotification, successNotification } from "./notification";

export const setUser = (payload) => {
  return {
    type: actionTypes.SET_USER,
    payload,
  };
};

export const setLoginErr = (payload) => {
  return {
    type: actionTypes.SET_LOGIN_ERR,
    payload,
  };
};

export const setRegisterErr = (payload) => {
  return {
    type: actionTypes.SET_REGISTER_ERR,
    payload,
  };
};

export const login = (user, history) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/admins/login", {
        email: user.email,
        password: user.password,
      })
      .then((response) => {
        if (response.data) {
          localStorage.setItem("jwToken", response.data.access_token);
          localStorage.setItem("user", JSON.stringify(response.data.admin));
          localStorage.setItem("id", response.data.admin.id);
          dispatch(setLoginErr(null));
          dispatch(setUser(response.data.admin));
        } else {
          history.push(`/auth/welcome/${response.data.admin.id}`);
        }
        dispatch(successNotification(response?.data?.msg));
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.msg));
        dispatch(setLoginErr(error?.response?.data?.msg));
        dispatch(finishLoading());
        return false;
      });
  };
};

export const googleLogin = (history, googleId) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/admins/auth/token", { googleId })
      .then((response) => {
        if (response) {
          localStorage.setItem("jwToken", response.data.admin.accessToken);
          localStorage.setItem("user", JSON.stringify(response.data.admin));
          localStorage.setItem("id", response.data.admin.id);
          dispatch(setLoginErr(null));
          dispatch(setUser(response.data.admin));
        } else {
          history.push(`/auth/welcome/${response.data.admin.id}`);
        }
        dispatch(successNotification("Login successful"));
        dispatch(finishLoading());
      })
      .catch((error) => {
        console.log(error);
        dispatch(errorsNotification(error?.response?.data?.message));
        dispatch(setLoginErr(error?.response?.data?.message));
        dispatch(finishLoading());
        return false;
      });
  };
};

export const register = (data, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/admins/signup", data)
      .then((response) => {
        // localStorage.setItem('jwToken', response.data.token);
        // localStorage.setItem('user', JSON.stringify(response.data.data));
        dispatch(setRegisterErr(null));
        dispatch(successNotification("Successfuly created!"));
        dispatch(finishLoading());
        callback(response.data?.admin.id);
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.msg));
        dispatch(setRegisterErr(error?.response?.data?.message));
        dispatch(finishLoading());
      });
  };
};

export const postWelcome = (data, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("auth/welcome", data)
      .then((response) => {
        localStorage.setItem("jwToken", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.data));
        dispatch(setLoginErr(null));
        dispatch(setUser(response.data.data));

        dispatch(successNotification(response?.data?.message));
        dispatch(finishLoading());
        callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        dispatch(finishLoading());
      });
  };
};

let timeout = null;
export const getCurrentUser = (loader = true) => {
  return (dispatch) => {
    if (loader) dispatch(startLoading());

    axios
      .get(`profile`)
      .then(({ data }) => {
        let downloading = data.data?.selectedCompany?.ratings?.some(
          (rating) => rating.downloading
        );
        dispatch(setUser(data.data));
        dispatch(finishLoading());

        if (downloading) {
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            dispatch(getCurrentUser(false));
          }, 10000);
        }
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        // localStorage.removeItem("user");
        // localStorage.removeItem("jwToken");
        // localStorage.removeItem("id");
        window.location.href = "/";
        if (loader) dispatch(finishLoading());
      });
  };
};

export const updateProfile = (adminId, user, callback) => {
  return (dispatch) => {
    axios
      .put(`/admins/profile/update/${adminId}`, {
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
      })
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data.admin));
        dispatch(setUser(response.data.admin));
        dispatch(successNotification(response.data.msg));
        callback && callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.msg));
        callback && callback();
      });
  };
};

export const deleteProfile = (adminId, callback) => {
  return (dispatch) => {
    axios
      .put(`admins/deactivate-account/${adminId}`)
      .then(({ data }) => {
        dispatch(successNotification(data.message));
        callback && callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        // callback();
      });
  };
};

export const updateEmail = (user, callback, errorCallback) => {
  return (dispatch) => {
    axios
      .put(`auth/update-email`, {
        newEmail: user.email,
        password: user.password,
      })
      .then(({ data }) => {
        dispatch(setUser(data.data));
        dispatch(successNotification(data.message));
        callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        errorCallback();
      });
  };
};

export const updatePassword = (adminId, user, callback, errorCallback) => {
  return (dispatch) => {
    axios
      .put(`/admins/password/update?id=${adminId}`, {
        newPassword: user.newPassword,
        password: user.password,
      })
      .then(({ data }) => {
        dispatch(successNotification(data.msg));
        callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        errorCallback();
      });
  };
};

export const deleteRatingPlatform = (type, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .delete(`rating?type=${type}`)
      .then(({ data }) => {
        dispatch(successNotification(data.message));
        dispatch(getCurrentUser());
        callback && callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        dispatch(finishLoading());
        callback && callback();
      });
  };
};

export const setCompanies = (payload) => {
  return {
    type: actionTypes.SET_COMPANIES,
    payload,
  };
};

export const setSelectedCompany = (payload) => {
  return {
    type: actionTypes.SET_SELECTED_COMPANY,
    payload,
  };
};

export const getCompanies = (adminId) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(`/business/list?id=${adminId}`)
      .then(({ data }) => {
        dispatch(setCompanies(data.business));
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.msg));
        dispatch(finishLoading());
      });
  };
};

export const addCompany = (
  businessName,
  businessUserName,
  websiteURL,
  adminId,
  callback
) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`/business/create`, {
        businessName,
        businessUserName,
        websiteURL,
        adminId,
      })
      .then(({ data }) => {
        dispatch(getCompanies(adminId));
        dispatch(successNotification(data.msg));
        dispatch(finishLoading());
        callback && callback();
        // dispatch(getStats());
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        dispatch(finishLoading());
      });
  };
};

export const changeCompany = (id, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`/business/select`, { id })
      .then(({ data }) => {
        dispatch(successNotification(data.msg));
        // localStorage.setItem("jwToken", data.token);
        // localStorage.setItem("user", JSON.stringify(data.data));
        dispatch(setSelectedCompany(data.business));
        dispatch(finishLoading());
        callback && callback();

        // dispatch(getStats());
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.msg));
        dispatch(finishLoading());
      });
  };
};

export const updateCompany = (businessId, updateCompanyData) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .put(`/business/company-details/update/${businessId}`, updateCompanyData)
      .then(({ data }) => {
        dispatch(setUser(data.data));
        dispatch(successNotification(data.msg));
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.msg));
        dispatch(finishLoading());
      });
  };
};

export const updateCompanyAddress = (address, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .put(`company/address?q=${JSON.stringify(address)}`)
      .then(({ data }) => {
        dispatch(getCurrentUser());
        dispatch(successNotification(data.message));
        callback && callback();
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.error_message));
        dispatch(finishLoading());
      });
  };
};

export const updateAdminSelectedBusiness = (adminId, businessId) => {
  return (dispatch) => {
    axios
      .put(`/admins/selected-business/update/${adminId}`, {
        selectedBusinessId: businessId,
      })
      .then(({ data }) => {
        dispatch(successNotification(data.msg));
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.msg));
      });
  };
};

export const forgotPassword = (email, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`/auth/forgot-password`, { email })
      .then(({ data }) => {
        dispatch(successNotification(data?.message));
        dispatch(finishLoading());
        callback && callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        dispatch(finishLoading());
        // callback && callback();
      });
  };
};

export const resetPassword = (id, password, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`/admins/invite-member/set-password?id=${id}`, { password })
      .then(({ data }) => {
        dispatch(updateConfirmedStatus(id));
        dispatch(successNotification(data?.message));
        dispatch(finishLoading());
        callback && callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        dispatch(finishLoading());
        // callback && callback();
      });
  };
};

export const updateConfirmedStatus = (id) => {
  return (dispatch) => {
    axios
      .put(`/admins/invite-member/set-confirmed/${id}`)
      .then(({ data }) => {
        // Handle success if needed
        dispatch(successNotification(data?.message));
        dispatch(finishLoading());
      })
      .catch((error) => {
        // Handle error if needed
        dispatch(errorsNotification(error?.response?.data?.message));
        dispatch(finishLoading());
      });
  };
};

export const setInvoices = (payload) => {
  return {
    type: actionTypes.SET_INVOICES,
    payload,
  };
};

export const getInvoices = (callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(`/company/invoices`)
      .then(({ data }) => {
        dispatch(setInvoices(data));
        dispatch(finishLoading());
        callback && callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        dispatch(finishLoading());
        // callback && callback();
      });
  };
};

export const sendFeedback = (message, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`/feedback`, { message })
      .then(({ data }) => {
        dispatch(successNotification(data?.message));
        dispatch(finishLoading());
        callback && callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        dispatch(finishLoading());
        // callback && callback();
      });
  };
};

export const updateBillingInfo = (billingInfo, hasLoader) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .put(`company/billing-info`, billingInfo)
      .then(({ data }) => {
        // dispatch(setUser(data.data));
        dispatch(successNotification(data.message));
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        dispatch(finishLoading());
      });
  };
};

export const changeImage = (data, hasLoader) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`company/upload-photo`, data)
      .then(({ data }) => {
        // dispatch(setUser(data.data));
        dispatch(successNotification(data.message));
        dispatch(getCurrentUser());
        // dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(errorsNotification("Size of image is too big."));
        dispatch(finishLoading());
      });
  };
};

export const setTeamMembers = (payload) => {
  return {
    type: actionTypes.SET_TEAM_MEMBERS,
    payload,
  };
};

export const getTeamMembers = (selectedBusinessId) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(`/admins/invite-member/list?id=${selectedBusinessId}`)
      .then(({ data }) => {
        dispatch(setTeamMembers(data.inviteMembers));
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.msg));
        dispatch(finishLoading());
      });
  };
};

export const inviteTeamMember = (
  firstName,
  lastName,
  email,
  selectedBusinessId,
  callback
) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`/admins/invite-member/create`, {
        firstName,
        lastName,
        email,
        selectedBusinessId,
      })
      .then(({ data }) => {
        dispatch(getTeamMembers());
        dispatch(successNotification(data.msg));
        // dispatch(finishLoading());
        callback && callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.msg));
        dispatch(finishLoading());
      });
  };
};

export const setMemberPassword = (id, password) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`auth/set-password/${id}`, { password })
      .then((response) => {
        localStorage.setItem("jwToken", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.data));
        dispatch(setUser(response.data.data));

        dispatch(successNotification(response?.data?.message));
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        dispatch(finishLoading());
      });
  };
};

export const deleteTeamMeber = (id) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .delete(`/admins/invite-member/delete/${id}`)
      .then((response) => {
        dispatch(getTeamMembers());
        dispatch(successNotification("Successfuly deleted!"));
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.msg));
        dispatch(finishLoading());
      });
  };
};
