import { typeObject } from "helpers/getObjectByType";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getTags } from "store/actions/tags";

const ConfiguratorSidebar = ({ widgetStyle, setWidgetStyle }) => {
  const dispatch = useDispatch();
  const [moreSettingsActive, setMoreSettings] = useState(false);
  const { type } = useParams();
  const user = useSelector((state) => state.auth.account);
  const companies = useSelector((state) => state.auth.selectedCompany);
  const tags = useSelector((state) => state.tags.tags);
  const businessId = companies?.id;

  useEffect(() => {
    if (businessId) {
      dispatch(getTags(businessId));
      // eslint-disable-next-line
    }
  }, []);

  const toggleTag = (tag) => {
    if (widgetStyle?.tags.find((el) => el === tag)) {
      let newTags = widgetStyle?.tags.filter((el) => el !== tag);
      setWidgetStyle((p) => ({
        ...p,
        tags: newTags,
      }));
    } else {
      setWidgetStyle((p) => ({
        ...p,
        tags: [...p.tags, tag],
      }));
    }
  };

  return (
    <div className="w-2/12 min-h-screen bg-white relative border-r border-gray-300 overflow-y-scroll">
      <div className="flex items-center px-7 py-3 border-b border-r border-gray-300 fixed top-0 left-0 w-3/12 bg-white z-10">
        <Link
          to="/widget-list"
          className="btn text-black bg-gray-200 hover:opacity-70 transition-opacity"
        >
          Back
        </Link>
        <div className="font-bold text-lg text-black ml-5">Style editor</div>
      </div>
      <div className="px-7 py-4 pt-20">
        {/* <div className="mb-5">
          <label className="block text-sm font-medium text-gray-700 hide">
            Widget name
          </label>
          <div className="mt-1">
            <input
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm"
              placeholder="Widget name"
              value={widgetStyle?.widgetName}
              onChange={(e) =>
                setWidgetStyle((p) => ({ ...p, widgetName: e.target.value }))
              }
            />
            <div className="text-xs mt-1 text-gray-600">
              Just to identify your widget
            </div>
          </div>
        </div> */}

        {(type === "slider" || type === "grid" || type === "list") && (
          <>
            <div className="mb-5">
              <div className="text-blue-600 text-sm font-semibold uppercase pb-1 mb-3 border-b border-gray-200">
                Review filter
              </div>
              <label className="block text-sm font-medium text-gray-700 hide">
                Rating filter
              </label>
              <div className="mt-1">
                <select
                  className="form-select w-full"
                  value={widgetStyle?.ratingFilter}
                  onChange={(e) =>
                    setWidgetStyle((p) => ({
                      ...p,
                      ratingFilter: e.target.value,
                    }))
                  }
                >
                  <option value="all">All</option>
                  <option value={"5"}>Only 5 ★</option>
                  <option value={"4,5"}>4★ - 5★</option>
                  <option value={"3,4,5"}>3★ - 5★</option>
                  <option value={"1,2,3"}>1★ - 3★</option>
                </select>
              </div>
            </div>
            <div className="block text-sm font-medium mb-1 text-gray-700 hide">
              Filter by Tag
            </div>
            <ul className="mb-3">
              {tags &&
                tags.length > 0 &&
                tags.map((item, index) => (
                  <li
                    className="py-1 mb-1 flex items-center justify-between"
                    key={index}
                  >
                    <label
                      className="flex items-center cursor-pointer"
                      key={index}
                    >
                      <input
                        type="checkbox"
                        className="form-checkbox w-6 h-6"
                        readOnly
                        checked={widgetStyle?.tags?.find(
                          (el) => el === item.id
                        )}
                        onClick={() => toggleTag(item.id)}
                      />
                      <span className="text-sm font-medium ml-2">
                        {item.name}
                      </span>
                    </label>
                  </li>
                ))}
            </ul>
          </>
        )}

        <div className="mb-5">
          <div className="text-blue-600 text-sm font-semibold uppercase pb-1 mb-3 border-b border-gray-200">
            Language
          </div>
          <label className="block text-sm font-medium text-gray-700 hide">
            Widget language
          </label>
          <div className="mt-1 mb-2">
            <select
              className="form-select w-full"
              value={widgetStyle?.language}
              onChange={(e) =>
                setWidgetStyle((p) => ({
                  ...p,
                  language: e.target.value,
                }))
              }
            >
              <option value="en">English</option>
              <option value="se">Swedish</option>
              <option value="nl">Dutch</option>
              <option value="de">German</option>
              <option value="fr">French</option>
            </select>
          </div>
        </div>

        {(type === "slider" || type === "grid" || type === "list") && (
          <div className="mb-5">
            <div className="text-blue-600 text-sm font-semibold uppercase pb-1 mb-3 border-b border-gray-200">
              Other
            </div>
            <label className="block text-sm font-medium text-gray-700 hide">
              Number of reviews in widget
            </label>
            <div className="mt-1 mb-2">
              <select
                className="form-select w-full"
                value={widgetStyle?.numberOfReviews}
                onChange={(e) =>
                  setWidgetStyle((p) => ({
                    ...p,
                    numberOfReviews: parseInt(e.target.value),
                  }))
                }
              >
                <option value="6">6</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="12">12</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </div>
            <label className="block text-sm font-medium text-gray-700 hide">
              Verified icon
            </label>
            <div className="mt-1 mb-2">
              <select
                className="form-select w-full"
                value={widgetStyle?.verifiedIcon}
                onChange={(e) =>
                  setWidgetStyle((p) => ({
                    ...p,
                    verifiedIcon: e.target.value,
                  }))
                }
              >
                <option value="show">Show</option>
                <option value="hide">Hide</option>
              </select>
            </div>

            <label className="block text-sm font-medium text-gray-700 hide">
              Platform logos
            </label>
            <div className="mt-1 mb-2">
              <select
                className="form-select w-full"
                value={widgetStyle?.platformLogos}
                onChange={(e) =>
                  setWidgetStyle((p) => ({
                    ...p,
                    platformLogos: e.target.value,
                  }))
                }
              >
                <option value="show">Show</option>
                <option value="hide">Hide</option>
              </select>
            </div>

            <label className="block text-sm font-medium text-gray-700 hide">
              Platform stars
            </label>
            <div className="mt-1 mb-2">
              <select
                className="form-select w-full"
                value={widgetStyle?.platformStars}
                onChange={(e) =>
                  setWidgetStyle((p) => ({
                    ...p,
                    platformStars: e.target.value,
                  }))
                }
              >
                <option value="show">Show</option>
                <option value="hide">Hide</option>
              </select>
            </div>
          </div>
        )}

        {type !== "button" &&
          type !== "badge" &&
          type !== "collectReviewsButton" && (
            <button
              onClick={() => setMoreSettings((p) => !p)}
              className="btn text-blue-600 w-full my-4 bg-gray-200 hover:opacity-80 transition-opacity"
            >
              {moreSettingsActive ? "Less" : "More"} settings
            </button>
          )}

        {(moreSettingsActive ||
          type === "button" ||
          type === "badge" ||
          type === "collectReviewsButton") && (
          <>
            {(type === "slider" || type === "grid" || type === "list") && (
              <div className="mb-5">
                <div className="text-blue-600 text-sm font-semibold uppercase pb-1 mb-3 border-b border-gray-200">
                  Links
                </div>
                <label className="block text-sm font-medium text-gray-700 hide">
                  Footer text
                </label>
                <div className="mt-1">
                  <select
                    className="form-select w-full"
                    value={widgetStyle?.footerText}
                    onChange={(e) =>
                      setWidgetStyle((p) => ({
                        ...p,
                        footerText: e.target.value,
                      }))
                    }
                  >
                    <option value="show">Show</option>
                    <option value="hide">Hide</option>
                  </select>
                </div>
              </div>
            )}
            <div className="mb-5">
              <div className="text-blue-600 text-sm font-semibold uppercase pb-1 mb-3 border-b border-gray-200">
                Sources & Style
              </div>
              {(type === "slider" || type === "grid" || type === "list") && (
                <>
                  <label className="block text-sm font-medium text-gray-700 hide">
                    Review sources(s)
                  </label>
                  <div className="mt-1 mb-2">
                    <select
                      className="form-select w-full"
                      value={widgetStyle?.reviewSources}
                      onChange={(e) =>
                        setWidgetStyle((p) => ({
                          ...p,
                          reviewSources: e.target.value,
                        }))
                      }
                    >
                      <option value="all">All soruces</option>
                      {user?.selectedCompany?.ratings.map(
                        (item, index) =>
                          item.type !== "overall" && (
                            <option key={index} value={item.type}>
                              {typeObject[item.type]?.title}
                            </option>
                          )
                      )}
                    </select>
                  </div>
                </>
              )}

              <label className="block text-sm font-medium text-gray-700 hide">
                Color schema
              </label>
              <div className="mt-1">
                <select
                  className="form-select w-full"
                  value={widgetStyle?.colorSchema}
                  onChange={(e) =>
                    setWidgetStyle((p) => ({
                      ...p,
                      colorSchema: e.target.value,
                    }))
                  }
                >
                  <option value="trustbucket-testimonial">
                    Trustbucket Testimonial
                  </option>
                  <option value="light-background">Light background</option>
                  <option value="drop-shadow">Drop Shadow</option>
                  <option value="trustbucket">Trustbucket Badge</option>
                  <option value="light-background-border">
                    Light background border
                  </option>
                  <option value="light-border">Light border</option>
                  <option value="light-clean">Light clean</option>
                  <option value="light-contrast">Light contrast</option>
                  <option value="minimal">Minimal</option>
                  <option value="minimal-dark">Minimal dark</option>
                  <option value="dark-background">Dark background</option>
                  <option value="dark-border">Dark border</option>
                  <option value="dark-contrast">Dark contrast</option>
                  <option value="soft">Soft</option>
                </select>
              </div>
            </div>

            {type === "slider" && (
              <div className="mb-5">
                <div className="text-blue-600 text-sm font-semibold uppercase pb-1 mb-3 border-b border-gray-200">
                  Navigation
                </div>
                <label className="block text-sm font-medium text-gray-700 hide">
                  Arrows
                </label>
                <div className="mt-1 mb-2">
                  <select
                    className="form-select w-full"
                    value={widgetStyle?.navigationArrows}
                    onChange={(e) =>
                      setWidgetStyle((p) => ({
                        ...p,
                        navigationArrows: e.target.value,
                      }))
                    }
                  >
                    <option value="show">Show</option>
                    <option value="hide">Hide</option>
                  </select>
                </div>

                <label className="block text-sm font-medium text-gray-700 hide">
                  Dots
                </label>
                <div className="mt-1">
                  <select
                    className="form-select w-full"
                    value={widgetStyle?.navigationDots}
                    onChange={(e) =>
                      setWidgetStyle((p) => ({
                        ...p,
                        navigationDots: e.target.value,
                      }))
                    }
                  >
                    <option value="show">Show</option>
                    <option value="mobile-only">Mobile only</option>
                    <option value="hide">Hide</option>
                  </select>
                </div>
              </div>
            )}

            <div className="mb-5">
              <div className="text-blue-600 text-sm font-semibold uppercase pb-1 mb-3 border-b border-gray-200">
                Inner Review Card
              </div>
              {(type === "slider" || type === "grid" || type === "list") && (
                <>
                  <label className="block text-sm font-medium text-gray-700 hide">
                    Text align
                  </label>
                  <div className="mt-1 mb-2">
                    <select
                      className="form-select w-full"
                      value={widgetStyle?.textAlign}
                      onChange={(e) =>
                        setWidgetStyle((p) => ({
                          ...p,
                          textAlign: e.target.value,
                        }))
                      }
                    >
                      <option value="left">Left</option>
                      <option value="center">Center</option>
                      <option value="right">Right</option>
                      <option value="justify">Justify</option>
                    </select>
                  </div>
                </>
              )}

              <div className="">
                <div className="">
                  <label className="block text-sm font-medium text-gray-700 hide">
                    Name Color
                  </label>
                  <div className="mt-1">
                    <input
                      className="appearance-none block w-full px-3 h-9 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm"
                      type="color"
                      value={widgetStyle?.nameColor}
                      onChange={(e) =>
                        setWidgetStyle((p) => ({
                          ...p,
                          nameColor: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="">
                  <label className="block text-sm font-medium text-gray-700 hide">
                    Name size
                  </label>
                  <div className="mt-1 relative">
                    <input
                      className="appearance-none block w-full px-3 pr-11 py-1.5 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm"
                      type="number"
                      value={widgetStyle?.nameSize}
                      onChange={(e) =>
                        setWidgetStyle((p) => ({
                          ...p,
                          nameSize: e.target.value,
                        }))
                      }
                      min={15}
                      max={24}
                    />
                    <div className="absolute inset-0 left-auto flex items-center rounded-r-md rounded-br-md text-sm bg-gray-200 px-3 text-black pointer-events-none">
                      px
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2">
                <div className="">
                  <label className="block text-sm font-medium text-gray-700 hide">
                    Review color
                  </label>
                  <div className="mt-1">
                    <input
                      className="appearance-none block w-full px-3 h-9 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm"
                      type="color"
                      value={widgetStyle?.reviewColor}
                      onChange={(e) =>
                        setWidgetStyle((p) => ({
                          ...p,
                          reviewColor: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="">
                  <label className="block text-sm font-medium text-gray-700 hide">
                    Review size
                  </label>
                  <div className="mt-1 relative">
                    <input
                      className="appearance-none block w-full px-3 pr-11 py-1.5 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm"
                      type="number"
                      value={widgetStyle?.reviewSize}
                      onChange={(e) =>
                        setWidgetStyle((p) => ({
                          ...p,
                          reviewSize: e.target.value,
                        }))
                      }
                      min={12}
                      max={24}
                    />
                    <div className="absolute inset-0 left-auto flex items-center rounded-r-md rounded-br-md text-sm bg-gray-200 px-3 text-black pointer-events-none">
                      px
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2">
                <div className="">
                  <label className="block text-sm font-medium text-gray-700 hide">
                    Background color
                  </label>
                  <div className="mt-1">
                    <input
                      className="appearance-none block w-full px-3 h-9 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm"
                      type="color"
                      value={widgetStyle?.backgroundColor}
                      onChange={(e) =>
                        setWidgetStyle((p) => ({
                          ...p,
                          backgroundColor: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="">
                  <label className="block text-sm font-medium text-gray-700 hide">
                    Border color
                  </label>
                  <div className="mt-1">
                    <input
                      className="appearance-none block w-full px-3 h-9 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm"
                      type="color"
                      value={widgetStyle?.borderColor}
                      onChange={(e) =>
                        setWidgetStyle((p) => ({
                          ...p,
                          borderColor: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>

              <div className=" mt-2">
                <div className="">
                  <label className="block text-sm font-medium text-gray-700 hide">
                    Border weight
                  </label>
                  <div className="mt-1 relative">
                    <input
                      className="appearance-none block w-full px-3 pr-11 py-1.5 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm"
                      type="number"
                      value={widgetStyle?.borderWeight}
                      onChange={(e) =>
                        setWidgetStyle((p) => ({
                          ...p,
                          borderWeight: e.target.value,
                        }))
                      }
                      min={0}
                      max={4}
                    />
                    <div className="absolute inset-0 left-auto flex items-center rounded-r-md rounded-br-md text-sm bg-gray-200 px-3 text-black pointer-events-none">
                      px
                    </div>
                  </div>
                </div>
                {/* <div className="w-1/2"></div> */}
              </div>

              <div className=" mt-2">
                <div className="">
                  <label className="block text-sm font-medium text-gray-700 hide">
                    Padding
                  </label>
                  <div className="mt-1 relative">
                    <input
                      className="appearance-none block w-full px-3 pr-11 py-1.5 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm"
                      type="number"
                      value={widgetStyle?.padding}
                      onChange={(e) =>
                        setWidgetStyle((p) => ({
                          ...p,
                          padding: e.target.value,
                        }))
                      }
                      min={0}
                      max={30}
                    />
                    <div className="absolute inset-0 left-auto flex items-center rounded-r-md rounded-br-md text-sm bg-gray-200 px-3 text-black pointer-events-none">
                      px
                    </div>
                  </div>
                </div>
                <div className="">
                  <label className="block text-sm font-medium text-gray-700 hide">
                    Corder radius
                  </label>
                  <div className="mt-1 relative">
                    <input
                      className="appearance-none block w-full px-3 pr-11 py-1.5 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm"
                      type="number"
                      value={widgetStyle?.borderRadius}
                      onChange={(e) =>
                        setWidgetStyle((p) => ({
                          ...p,
                          borderRadius: e.target.value,
                        }))
                      }
                      min={0}
                      max={30}
                    />
                    <div className="absolute inset-0 left-auto flex items-center rounded-r-md rounded-br-md text-sm bg-gray-200 px-3 text-black pointer-events-none">
                      px
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2">
                <div className="">
                  <label className="block text-sm font-medium text-gray-700 hide">
                    Drop shadow
                  </label>
                  <div className="mt-1 mb-2">
                    <select
                      className="form-select w-full"
                      value={widgetStyle?.dropShadow}
                      onChange={(e) =>
                        setWidgetStyle((p) => ({
                          ...p,
                          dropShadow: e.target.value,
                        }))
                      }
                    >
                      <option value="hide">No</option>
                      <option value="show">Yes</option>
                    </select>
                  </div>
                </div>
                {/* <div className="w-1/2"></div> */}
              </div>

              <div className="flex mt-2">
                {/* <div className="">
                  <label className="block text-sm font-medium text-gray-700 hide">
                    Background opacity
                  </label>
                  <div className="mt-1 mb-2">
                    <input
                      type="range"
                      min="0"
                      max="1"
                      step="0.1"
                      value={widgetStyle?.backgroundOpacity}
                      onChange={(e) =>
                        setWidgetStyle((p) => ({
                          ...p,
                          backgroundOpacity: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div> */}
              </div>

              <div className=" mt-2">
                <div className="">
                  <label className="block text-sm font-medium text-gray-700 hide">
                    Mouseover animation
                  </label>
                  <div className="mt-1 mb-2">
                    <select
                      className="form-select w-full"
                      value={widgetStyle?.mouseAnimation}
                      onChange={(e) =>
                        setWidgetStyle((p) => ({
                          ...p,
                          mouseAnimation: e.target.value,
                        }))
                      }
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {(type === "slider" || type === "grid" || type === "list") && (
              <div className="mb-5">
                <div className="text-blue-600 text-sm font-semibold uppercase pb-1 mb-3 border-b border-gray-200">
                  Outer Review Card
                </div>
                <div className=" mt-2">
                  <div className="">
                    <label className="block text-sm font-medium text-gray-700 hide">
                      Preview Background color
                    </label>
                    <div className="mt-1">
                      <input
                        className="appearance-none block w-full px-3 h-9 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm"
                        type="color"
                        value={widgetStyle?.outerBackgroundColor}
                        onChange={(e) =>
                          setWidgetStyle((p) => ({
                            ...p,
                            outerBackgroundColor: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                  <div className="">
                    <label className="block text-sm font-medium text-gray-700 hide">
                      Text color
                    </label>
                    <div className="mt-1">
                      <input
                        className="appearance-none block w-full px-3 h-9 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm"
                        type="color"
                        value={widgetStyle?.outerTextColor}
                        onChange={(e) =>
                          setWidgetStyle((p) => ({
                            ...p,
                            outerTextColor: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ConfiguratorSidebar;
