import dayjs from "dayjs";
import React from "react";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

const statusColor = {
  responded: "bg-green-100 text-green-600",
  opened: "bg-yellow-200 text-yellow-700",
  delivered: "bg-gray-200 text-gray-700",
  bounced: "bg-gray-300 text-gray-900",
  "hard-bounced": "bg-gray-300 text-gray-900",
  pending: "bg-gray-200 text-gray-700",
};

export default function ReviewersTableItem({ data }) {
  const invitationsData = JSON.parse(data.invitations);
  return (
    <tbody className="text-sm">
      {/* Row */}
      <tr>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          {invitationsData.map((item) => {
            return (
              <div className="font-medium text-gray-800">{item.email}</div>
            );
          })}
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          {invitationsData.map((item) => {
            return (
              <div className="font-medium text-gray-800">{item.firstName}</div>
            );
          })}
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          {invitationsData.map((item) => {
            return (
              <div className="font-medium text-gray-800">{item.lastName}</div>
            );
          })}
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div
            className={
              "inline-flex text-sm font-medium rounded-full text-center px-2.5 py-0.5 " +
              statusColor[data.status]
            }
          >
            {data.status}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          {data.createdAt && (
            <div className="text-left">{dayjs(data.createdAt).fromNow()}</div>
          )}
        </td>
      </tr>
    </tbody>
  );
}
