import dayjs from "dayjs";
import React, { useState } from "react";
import relativeTime from "dayjs/plugin/relativeTime";
import { DeletePopup } from "components/DeletePopup/DeletePopup";
import deleteIcon from "icons/deleteIcon.svg";
import { useDispatch } from "react-redux";
import { deleteTeamMeber } from "store/actions/auth";
dayjs.extend(relativeTime);

const confirmedColor = (confirmed) => {
  if (confirmed) {
    return "bg-green-100 text-green-600";
  } else {
    return "bg-red-100 text-red-500";
  }
};

export default function TeamTableItem({ data, canDelete }) {
  const [deletePopup, setDeletePopup] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <tbody className="text-sm">
        {/* Row */}
        <tr>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="font-medium text-gray-800">{data.firstName}</div>
          </td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="font-medium text-gray-800">{data.lastName}</div>
          </td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="font-medium text-gray-800">{data.email}</div>
          </td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div
              className={`inline-flex text-sm font-medium rounded-full text-center px-2.5 py-0.5 ${confirmedColor(
                data.confirmed
              )}`}
            >
              {data.confirmed ? "YES" : "NO"}
            </div>
          </td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div
              className={`inline-flex text-sm font-medium rounded-full text-center px-2.5 py-0.5 ${confirmedColor(
                !data.deactivated
              )}`}
            >
              {data.deactivated ? "YES" : "NO"}
            </div>
          </td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="text-left">{dayjs(data.createdAt).fromNow()}</div>
          </td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
            <div className="flex items-center">
              {canDelete && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeletePopup(true);
                  }}
                  aria-controls="delete-platform-modal"
                  className="border flex justify-center items-center transition-all font-medium px-3 border-gray-200 hover:border-red-700 border-solid rounded py-1.5 lg:px-5"
                >
                  <img src={deleteIcon} alt="delete" />
                  <span className="text-red-600 ml-1.5">Delete</span>
                </button>
              )}
            </div>
          </td>
        </tr>
      </tbody>
      <DeletePopup
        deletePopup={deletePopup}
        setDeletePopup={setDeletePopup}
        onSubmit={() => dispatch(deleteTeamMeber(data?.id))}
        title={`Delete team member`}
        description={`Are you sure you want to delete team member ${data?.firstName} ${data?.lastName} from your team?`}
      />
    </>
  );
}
