import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
// import React, { useState } from "react";

export default function CompaniesTableItem({ data }) {
  // const [descriptionOpen, setDescriptionOpen] = useState(false);

  return (
    <tbody className="text-sm">
      {/* Row */}
      <tr>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div>{data.name}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="font-medium text-gray-800">{data.email}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left text-gray-800">{data.phone}</div>
        </td>
        {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 ${statusColor(props.status)}`}>{props.status}</div>
      </td> */}
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left capitalize">{data.subscription?.plan}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <a
            href={
              data?.websiteURL?.includes("http")
                ? data?.websiteURL
                : `http://${data?.websiteURL}`
            }
            target="_blank"
            rel="noopener noreferrer"
            className="text-left text-blue-600 underline"
          >
            {data?.websiteURL}
          </a>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <a
            href={`https://reviews.trustbucket.io/${data.slug}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-left text-blue-600 underline"
          >
            reviews.trustbucket.io/{data.slug}
          </a>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left">{data.user?.email}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left">{dayjs(data.createdAt).fromNow()}</div>
        </td>
        {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            <button
              className={`text-gray-400 hover:text-gray-500 transform ${
                descriptionOpen && "rotate-180"
              }`}
              aria-expanded={descriptionOpen}
              onClick={() => setDescriptionOpen(!descriptionOpen)}
              aria-controls={`description-${data._id}`}
            >
              <span className="sr-only">Menu</span>
              <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
              </svg>
            </button>
          </div>
        </td> */}
      </tr>
      {/*
    Example of content revealing when clicking the button on the right side:
    Note that you must set a "colSpan" attribute on the <td> element,
    and it should match the number of columns in your table
    */}
      {/* <tr
        id={`description-${data._id}`}
        role="region"
        className={`${!descriptionOpen && "hidden"}`}
      >
        <td colSpan="10" className="px-2 first:pl-5 last:pr-5 py-3">
          {data?.companies?.map((item, index) => (
            <div className="flex items-center bg-gray-50 p-3 mb-2">
              <td className="px-3">Name: {item.name}</td>
              <td className="px-3">Email: {item.email}</td>
              <td className="px-3">Phone: {item.phone}</td>
              <td className="px-3">Plan: {item.subscription?.plan}</td>
              <td className="px-3">Website: {item.websiteURL}</td>
            </div>
          ))}
        </td>
      </tr> */}
    </tbody>
  );
}
