import React from "react";
import "./RecoStars.scss";

const RecoStars = ({ rating }) => {
  return (
    <div className="flex items-center RatingStars">
      <span className={rating > 0.5 ? "rated" : "not-rated"}></span>
      <span className={rating > 1.5 ? "rated" : "not-rated"}></span>
      <span className={rating > 2.5 ? "rated" : "not-rated"}></span>
      <span className={rating > 3.5 ? "rated" : "not-rated"}></span>
      <span className={rating > 4.5 ? "rated" : "not-rated"}></span>
    </div>
  );
};

export default RecoStars;
