import dayjs from "dayjs";
import React, { useState } from "react";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

const confirmedColor = (confirmed) => {
  if (confirmed) {
    return "bg-green-100 text-green-600";
  } else {
    return "bg-red-100 text-red-500";
  }
};

export default function UsersTableItem({ data }) {
  const [descriptionOpen, setDescriptionOpen] = useState(false);

  return (
    <tbody className="text-sm">
      {/* Row */}
      <tr>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div>{data.firstName}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div>{data.lastName}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="font-medium text-gray-800">{data.email}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left text-gray-800">{data.phone}</div>
        </td>
        {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 ${statusColor(props.status)}`}>{props.status}</div>
      </td> */}
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left">{data.type}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div
            className={`inline-flex text-sm font-medium rounded-full text-center px-2.5 py-0.5 ${confirmedColor(
              data.confirmed
            )}`}
          >
            {data.confirmed ? "YES" : "NO"}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left">{dayjs(data.createdAt).fromNow()}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            <button
              className={`text-gray-400 hover:text-gray-500 transform ${
                descriptionOpen && "rotate-180"
              }`}
              aria-expanded={descriptionOpen}
              onClick={() => setDescriptionOpen(!descriptionOpen)}
              aria-controls={`description-${data._id}`}
            >
              <span className="sr-only">Menu</span>
              <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
              </svg>
            </button>
          </div>
        </td>
      </tr>
      {/*
    Example of content revealing when clicking the button on the right side:
    Note that you must set a "colSpan" attribute on the <td> element,
    and it should match the number of columns in your table
    */}
      <tr
        id={`description-${data._id}`}
        role="region"
        className={`${!descriptionOpen && "hidden"}`}
      >
        <td colSpan="10" className="px-2 first:pl-5 last:pr-5 py-3">
          {data?.companies?.map((item, index) => (
            <div className="flex items-center bg-gray-50 p-3 mb-2">
              <div className="px-3">
                <span className="font-bold">Company Name: </span>
                {item.name}
              </div>
              <div className="px-3">
                <span className="font-bold">Email: </span>
                {item.email}
              </div>
              <div className="px-3">
                <span className="font-bold">Phone: </span>
                {item.phone}
              </div>
              <div className="px-3">
                <span className="font-bold">Plan: </span>
                {item.subscription?.plan}
              </div>
              <div className="px-3">
                <span className="font-bold">Website: </span>
                {item.websiteURL}
              </div>
            </div>
          ))}
        </td>
      </tr>
    </tbody>
  );
}
