import trustbucket from "icons/mini-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import lockMiniSVG from "icons/lock-mini.svg";
import { setUpgradePopup } from "store/actions/upgradePopup";

const ConfiguratorHeader = ({ saveCode }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.account);

  return (
    <div className="shadow-sm bg-white flex items-center justify-between py-3 px-6 border-b border-gray-300 fixed z-20 w-10/12 top-0">
      <div className="text-black text-lg font-semibold">Widget Preview</div>
      <Link to="/dashboard" className="flex items-center">
        <img src={trustbucket} alt="Logo" className="h-8 mr-2" />
        <div className="font-semibold" style={{ color: "#1F2937" }}>
          Trustbucket
        </div>
      </Link>
      {user?.selectedCompany?.subscription?.plan === "free" &&
      user?.type !== "appsumo" ? (
        <div
          onClick={() => dispatch(setUpgradePopup(true))}
          className="btn bg-blue-500 hover:bg-blue-600 cursor-pointer transition-all flex items-center w-max sm:mt-0 mt-2 text-white"
        >
          <img src={lockMiniSVG} alt="lock" className="mr-2" />
          Upgrade to use
        </div>
      ) : (
        <button
          onClick={(e) => {
            e.stopPropagation();
            saveCode();
          }}
          className="btn bg-blue-600 text-white hover:bg-blue-700 transition-colors"
        >
          Save and get code
        </button>
      )}
    </div>
  );
};

export default ConfiguratorHeader;
