import { ErrorBoundary } from "react-error-boundary";
import logo from "icons/logo.svg";

const ErrorFallback = ({ resetErrorBoundary }) => {
  return (
    <div className="pb-20 w-screen h-screen flex flex-col justify-center items-center bg-white text-black">
      <div className="mb-12">
        <img src={logo} alt="logo" />
      </div>
      <h1 className="text-3xl font-bold mb-6">
        Looks like something went wrong
      </h1>

      <p className="mb-6 text-lg">Our team have been informed about this</p>
      <p className="mb-4 text-lg">Until we resolve this issue you can</p>
      <div className="space-x-3">
        <button
          className="btn bg-blue-600 text-white"
          onClick={resetErrorBoundary}
        >
          Go to Dashboard
        </button>
        <span> or </span>
        <button
          className="btn bg-blue-100 text-blue-700"
          onClick={() => window.location.reload()}
        >
          Reload
        </button>
      </div>
    </div>
  );
};

export default function ErrorBoundaryProvider({ children }) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, info) => {
        // we could send some error data to external system like DataDog etc. here
        console.error({ error, info });
      }}
      onReset={() => (window.location.pathname = "/dashboard")}
    >
      {children}
    </ErrorBoundary>
  );
}
