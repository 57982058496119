import { appsumoPlanMessage } from "helpers/calculatePlanPrice";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const checkSVG = () => (
  <svg
    className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2"
    viewBox="0 0 12 12"
  >
    <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
  </svg>
);

export default function AppsumoPlans() {
  const user = useSelector((state) => state.auth.account);
  const [allFeaturesExpanded, setAllFeaturesExpanded] = useState(true);

  return (
    <div className="mb-8">
      <h2 className="text-2xl text-gray-800 font-bold mb-4">Plans</h2>
      <div className="text-sm mb-2 text-gray-800">
        {appsumoPlanMessage(user?.availableProCompanies)}
      </div>
      <div className="my-4">
        <div
          className="text-sm text-gray-800 flex items-center cursor-pointer"
          onClick={() => setAllFeaturesExpanded((p) => !p)}
        >
          <div className="mr-1.5">Features Included in All Plans</div>
          <svg
            className={
              "w-3 h-3 shrink-0 ml-1 fill-current text-gray-600 rotate-180 mt-1.5" +
              (allFeaturesExpanded ? " rotate-0 mt-0" : "")
            }
            viewBox="0 0 12 12"
          >
            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z"></path>
          </svg>
        </div>
        <ul
          className={
            "max-h-80 overflow-hidden transition-all" +
            (allFeaturesExpanded ? "" : " max-h-0")
          }
        >
          <li className="flex items-center py-1 mb-1 mt-2">
            {checkSVG()}
            <div className="">Trustbucket reviews page</div>
          </li>
          <li className="flex items-center py-1 mb-1">
            {checkSVG()}
            <div className="">Use widgets to display reviews</div>
          </li>
          <li className="flex items-center py-1 mb-1">
            {checkSVG()}
            <div className="">Connect Google reviews</div>
          </li>
          <li className="flex items-center py-1 mb-1">
            {checkSVG()}
            <div className="">Collect Google reviews</div>
          </li>
          <li className="flex items-center py-1 mb-1">
            {checkSVG()}
            <div className="">Auto-sync reviews every 24h</div>
          </li>
          <li className="flex items-center py-1 mb-1">
            {checkSVG()}
            <div className="">Create custom invitation templates</div>
          </li>
          <li className="flex items-center py-1 mb-1">
            {checkSVG()}
            <div className="">Connect reviews from other platforms</div>
          </li>
          <li className="flex items-center py-1 mb-1">
            {checkSVG()}
            <div className="">Collect reviews for other platforms</div>
          </li>
        </ul>
      </div>
      <div className="grid grid-cols-12 gap-6">
        <div className="relative col-span-full xl:col-span-6 2xl:col-span-3 bg-white shadow-md rounded-sm border border-gray-200">
          <div
            className="absolute top-0 left-0 right-0 h-0.5 bg-blue-300"
            aria-hidden="true"
          ></div>
          <div className="px-5 pt-5 pb-6 border-b border-gray-200">
            <header className="flex items-center mb-2">
              <h3 className="text-lg text-gray-800 font-semibold flex items-center">
                License Tier 1
                {user?.availableProCompanies === "3" && (
                  <span className="text-sm text-gray-500 ml-1.5">
                    {" "}
                    (current)
                  </span>
                )}
              </h3>
            </header>
            {/* Price */}
            <div className="text-gray-800 font-bold mb-2">
              <span className="text-2xl">$</span>
              <span className="text-2xl">69</span>
            </div>
            <div className="text-gray-400 text-sm font-medium mb-4">
              Lifetime purchase
            </div>
            {/* CTA */}
            <a
              href={
                user?.availableProCompanies === "0"
                  ? "https://appsumo.com/products/trustbucket/"
                  : `https://appsumo.com/account/redemption/${user?.invioceItemUUID}/change-plan/`
              }
              className={
                "btn w-full flex items-center" +
                (user?.availableProCompanies === "3"
                  ? " border border-blue-600 text-blue-600 bg-white transition-opacity hover:opacity-80"
                  : " bg-blue-500 hover:bg-blue-600 text-white")
              }
            >
              <span>
                {user?.availableProCompanies === "3"
                  ? "Manage"
                  : user?.availableProCompanies === "0"
                  ? "Buy plan"
                  : "Switch"}
              </span>
            </a>
          </div>
          <div className="px-5 pt-4 pb-5">
            <div className="text-xs text-gray-800 font-semibold uppercase mb-4">
              What's included
            </div>
            {/* List */}
            <ul>
              <li className="flex items-center py-1">
                {checkSVG()}
                <div className="text-sm">All features above included</div>
              </li>
              <li className="flex items-center py-1">
                {checkSVG()}
                <div className="text-sm">
                  <span className="font-bold">3</span> websites
                </div>
              </li>
              <li className="flex items-center py-1">
                {checkSVG()}
                <div className="text-sm">
                  <span className="font-bold">500</span> total review
                  invitations per month, per site
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="relative col-span-full xl:col-span-6 2xl:col-span-3 bg-white shadow-md rounded-sm border border-gray-200">
          <div
            className="absolute top-0 left-0 right-0 h-0.5 bg-blue-300"
            aria-hidden="true"
          ></div>
          <div className="px-5 pt-5 pb-6 border-b border-gray-200">
            <header className="flex items-center mb-2">
              <h3 className="text-lg text-gray-800 font-semibold flex items-center">
                License Tier 2
                {user?.availableProCompanies === "10" && (
                  <span className="text-sm text-gray-500 ml-1.5">
                    {" "}
                    (current)
                  </span>
                )}
              </h3>
            </header>
            {/* Price */}
            <div className="text-gray-800 font-bold mb-2">
              <span className="text-2xl">$</span>
              <span className="text-2xl">149</span>
            </div>
            <div className="text-gray-400 text-sm font-medium mb-4">
              Lifetime purchase
            </div>
            {/* CTA */}
            <a
              href={
                user?.availableProCompanies === "0"
                  ? "https://appsumo.com/products/trustbucket/"
                  : `https://appsumo.com/account/redemption/${user?.invioceItemUUID}/change-plan/`
              }
              className={
                "btn w-full flex items-center" +
                (user?.availableProCompanies === "10"
                  ? " border border-blue-600 text-blue-600 bg-white transition-opacity hover:opacity-80"
                  : " bg-blue-500 hover:bg-blue-600 text-white")
              }
              onClick={() => {}}
            >
              <span>
                {user?.availableProCompanies === "10"
                  ? "Manage"
                  : user?.availableProCompanies === "0"
                  ? "Buy plan"
                  : "Switch"}
              </span>
            </a>
          </div>
          <div className="px-5 pt-4 pb-5">
            <div className="text-xs text-gray-800 font-semibold uppercase mb-4">
              What's included
            </div>
            {/* List */}
            <ul>
              <li className="flex items-center py-1">
                {checkSVG()}
                <div className="text-sm">All features above included</div>
              </li>
              <li className="flex items-center py-1">
                {checkSVG()}
                <div className="text-sm">
                  <span className="font-bold">10</span> websites
                </div>
              </li>
              <li className="flex items-center py-1">
                {checkSVG()}
                <div className="text-sm">
                  <span className="font-bold">750</span> total review
                  invitations per month, per site
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="relative col-span-full xl:col-span-6 2xl:col-span-3 bg-white shadow-md rounded-sm border border-gray-200">
          <div
            className="absolute top-0 left-0 right-0 h-0.5 bg-blue-300"
            aria-hidden="true"
          ></div>
          <div className="px-5 pt-5 pb-6 border-b border-gray-200">
            <header className="flex items-center mb-2">
              <h3 className="text-lg text-gray-800 font-semibold flex items-center">
                License Tier 3
                {user?.availableProCompanies === "30" && (
                  <span className="text-sm text-gray-500 ml-1.5">
                    {" "}
                    (current)
                  </span>
                )}
              </h3>
            </header>
            {/* Price */}
            <div className="text-gray-800 font-bold mb-2">
              <span className="text-2xl">$</span>
              <span className="text-2xl">299</span>
            </div>
            <div className="text-gray-400 text-sm font-medium mb-4">
              Lifetime purchase
            </div>
            {/* CTA */}
            <a
              href={
                user?.availableProCompanies === "0"
                  ? "https://appsumo.com/products/trustbucket/"
                  : `https://appsumo.com/account/redemption/${user?.invioceItemUUID}/change-plan/`
              }
              className={
                "btn w-full flex items-center" +
                (user?.availableProCompanies === "30"
                  ? " border border-blue-600 text-blue-600 bg-white transition-opacity hover:opacity-80"
                  : " bg-blue-500 hover:bg-blue-600 text-white")
              }
              onClick={() => {}}
            >
              <span>
                {user?.availableProCompanies === "30"
                  ? "Manage"
                  : user?.availableProCompanies === "0"
                  ? "Buy plan"
                  : "Switch"}
              </span>
            </a>
          </div>
          <div className="px-5 pt-4 pb-5">
            <div className="text-xs text-gray-800 font-semibold uppercase mb-4">
              What's included
            </div>
            {/* List */}
            <ul>
              <li className="flex items-center py-1">
                {checkSVG()}
                <div className="text-sm">All features above included</div>
              </li>
              <li className="flex items-center py-1">
                {checkSVG()}
                <div className="text-sm">
                  <span className="font-bold">30</span> websites
                </div>
              </li>
              <li className="flex items-center py-1">
                {checkSVG()}
                <div className="text-sm">
                  <span className="font-bold">1000</span> total review
                  invitations per month, per site
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="relative col-span-full xl:col-span-6 2xl:col-span-3 bg-white shadow-md rounded-sm border border-gray-200">
          <div
            className="absolute top-0 left-0 right-0 h-0.5 bg-blue-300"
            aria-hidden="true"
          ></div>
          <div className="px-5 pt-5 pb-6 border-b border-gray-200">
            <header className="flex items-center mb-2">
              <h3 className="text-lg text-gray-800 font-semibold flex items-center">
                License Tier 4
                {user?.availableProCompanies === "unlimited" && (
                  <span className="text-sm text-gray-500 ml-1.5">
                    {" "}
                    (current)
                  </span>
                )}
              </h3>
            </header>
            {/* Price */}
            <div className="text-gray-800 font-bold mb-2">
              <span className="text-2xl">$</span>
              <span className="text-2xl">499</span>
            </div>
            <div className="text-gray-400 text-sm font-medium mb-4">
              Lifetime purchase
            </div>
            {/* CTA */}
            <a
              href={
                user?.availableProCompanies === "0"
                  ? "https://appsumo.com/products/trustbucket/"
                  : `https://appsumo.com/account/redemption/${user?.invioceItemUUID}/change-plan/`
              }
              className={
                "btn w-full flex items-center" +
                (user?.availableProCompanies === "unlimited"
                  ? " border border-blue-600 text-blue-600 bg-white transition-opacity hover:opacity-80"
                  : " bg-blue-500 hover:bg-blue-600 text-white")
              }
            >
              {user?.availableProCompanies === "unlimited" && (
                <svg
                  className="w-3 h-3 shrink-0 fill-current mr-2"
                  viewBox="0 0 12 12"
                >
                  <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                </svg>
              )}
              <span>
                {user?.availableProCompanies === "unlimited"
                  ? "Manage"
                  : user?.availableProCompanies === "0"
                  ? "Buy plan"
                  : "Switch"}
              </span>
            </a>
          </div>
          <div className="px-5 pt-4 pb-5">
            <div className="text-xs text-gray-800 font-semibold uppercase mb-4">
              What's included
            </div>
            {/* List */}
            <ul>
              <li className="flex items-center py-1">
                {checkSVG()}
                <div className="text-sm">All features above included</div>
              </li>
              <li className="flex items-center py-1">
                {checkSVG()}
                <div className="text-sm">
                  <span className="font-bold">Unlimited</span> websites
                </div>
              </li>
              <li className="flex items-center py-1">
                {checkSVG()}
                <div className="text-sm">
                  <span className="font-bold">Unlimited</span> total review
                  invitations per month, per site
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
