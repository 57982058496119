import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

function SettingsSidebar() {
  const location = useLocation();
  const { pathname } = location;
  const user = useSelector((state) => state.auth.account);

  return (
    <div className="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-gray-200 min-w-60 md:space-y-3">
      {/* PROFILE SETTINGS */}
      <div>
        <div className="text-xs font-semibold text-gray-400 uppercase mb-3 md:block hidden">
          Profile settings
        </div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink
              exact
              to="/settings/account"
              className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                pathname.includes("/settings/account") && "bg-blue-50"
              }`}
            >
              <svg
                className={`w-4 h-4 shrink-0 fill-current text-gray-400 mr-2 ${
                  pathname.includes("/settings/account") && "text-blue-400"
                }`}
                viewBox="0 0 16 16"
              >
                <path d="M12.311 9.527c-1.161-.393-1.85-.825-2.143-1.175A3.991 3.991 0 0012 5V4c0-2.206-1.794-4-4-4S4 1.794 4 4v1c0 1.406.732 2.639 1.832 3.352-.292.35-.981.782-2.142 1.175A3.942 3.942 0 001 13.26V16h14v-2.74c0-1.69-1.081-3.19-2.689-3.733zM6 4c0-1.103.897-2 2-2s2 .897 2 2v1c0 1.103-.897 2-2 2s-2-.897-2-2V4zm7 10H3v-.74c0-.831.534-1.569 1.33-1.838 1.845-.624 3-1.436 3.452-2.422h.436c.452.986 1.607 1.798 3.453 2.422A1.943 1.943 0 0113 13.26V14z" />
              </svg>
              <span
                className={`text-sm font-medium ${
                  pathname.includes("/settings/account")
                    ? "text-blue-500"
                    : "hover:text-gray-700"
                }`}
              >
                My Profile
              </span>
            </NavLink>
          </li>
        </ul>
      </div>
      {/* BUSINESS SETTINGS */}
      <div>
        <div className="text-xs font-semibold text-gray-400 uppercase mb-3 md:block hidden">
          Business settings
        </div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink
              exact
              to="/settings/business-info"
              className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                pathname.includes("/settings/business-info") && "bg-blue-50"
              }`}
            >
              <svg
                width="20"
                height="20"
                className={`w-4 h-4 shrink-0 text-gray-400 mr-2 ${
                  pathname.includes("/settings/account") && "text-blue-400"
                }`}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 14C13.0844 14.0038 16.1405 13.4112 19 12.255L10 14ZM10 14C6.817 14 3.78 13.38 1 12.255L10 14ZM10 14L10.01 11M14 5V3C14 2.46957 13.7893 1.96086 13.4142 1.58579C13.0391 1.21071 12.5304 1 12 1H8C7.46957 1 6.96086 1.21071 6.58579 1.58579C6.21071 1.96086 6 2.46957 6 3V5H14ZM3 19H17C17.5304 19 18.0391 18.7893 18.4142 18.4142C18.7893 18.0391 19 17.5304 19 17V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H3C2.46957 5 1.96086 5.21071 1.58579 5.58579C1.21071 5.96086 1 6.46957 1 7V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19Z"
                  stroke="#94A3B8"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span
                className={`text-sm font-medium ${
                  pathname.includes("/settings/business-info")
                    ? "text-blue-500"
                    : "hover:text-gray-700"
                }`}
              >
                Business info
              </span>
            </NavLink>
          </li>
          {user?._id === user?.selectedCompany?.user && (
            <li className="mr-0.5 md:mr-0 md:mb-0.5">
              <NavLink
                exact
                to="/settings/team"
                className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                  pathname.includes("/settings/team") && "bg-blue-50"
                }`}
              >
                <svg
                  className={`w-4 h-4 shrink-0 fill-current text-gray-400 mr-2 ${
                    pathname.includes("/settings/team") ? "text-blue-400" : ""
                  }`}
                  viewBox="0 0 24 24"
                >
                  <path
                    className={`fill-current ${
                      pathname.includes("/settings/team")
                        ? "text-blue-300"
                        : "text-gray-300"
                    }`}
                    d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"
                  />
                  <path
                    className={`fill-current text-gray-400 ${
                      pathname.includes("/settings/team") && "text-blue-400"
                    }`}
                    d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
                  />
                </svg>
                <span
                  className={`text-sm font-medium ${
                    pathname.includes("/settings/team")
                      ? "text-blue-500"
                      : "hover:text-gray-700"
                  }`}
                >
                  My Team
                </span>
              </NavLink>
            </li>
          )}
          {user?._id === user?.selectedCompany?.user && (
            <li className="mr-0.5 md:mr-0 md:mb-0.5">
              <NavLink
                exact
                to="/settings/plans"
                className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                  pathname.includes("/settings/plans") && "bg-blue-50"
                }`}
              >
                <svg
                  className={`w-4 h-4 shrink-0 fill-current text-gray-400 mr-2 ${
                    pathname.includes("/settings/plans") && "text-blue-400"
                  }`}
                  viewBox="0 0 16 16"
                >
                  <path d="M5 9h11v2H5V9zM0 9h3v2H0V9zm5 4h6v2H5v-2zm-5 0h3v2H0v-2zm5-8h7v2H5V5zM0 5h3v2H0V5zm5-4h11v2H5V1zM0 1h3v2H0V1z" />
                </svg>
                <span
                  className={`text-sm font-medium ${
                    pathname.includes("/settings/plans")
                      ? "text-blue-500"
                      : "hover:text-gray-700"
                  }`}
                >
                  Plans
                </span>
              </NavLink>
            </li>
          )}
          {user &&
            user?.type !== "appsumo" &&
            user?._id === user?.selectedCompany?.user && (
              <li className="mr-0.5 md:mr-0 md:mb-0.5">
                <NavLink
                  exact
                  to="/settings/billing"
                  className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                    pathname.includes("/settings/billing") && "bg-blue-50"
                  }`}
                >
                  <svg
                    className={`w-4 h-4 shrink-0 fill-current text-gray-400 mr-2 ${
                      pathname.includes("/settings/billing") && "text-blue-400"
                    }`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 4c.6 0 1 .4 1 1v10c0 .6-.4 1-1 1H3c-1.7 0-3-1.3-3-3V3c0-1.7 1.3-3 3-3h7c.6 0 1 .4 1 1v3h4zM2 3v1h7V2H3c-.6 0-1 .4-1 1zm12 11V6H2v7c0 .6.4 1 1 1h11zm-3-5h2v2h-2V9z" />
                  </svg>
                  <span
                    className={`text-sm font-medium ${
                      pathname.includes("/settings/billing")
                        ? "text-blue-500"
                        : "hover:text-gray-700"
                    }`}
                  >
                    Billing & Invoices
                  </span>
                </NavLink>
              </li>
            )}
        </ul>
      </div>
      {/* EXPERIENCE */}
      <div>
        <div className="text-xs font-semibold text-gray-400 uppercase mb-3 md:block hidden">
          Other
        </div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <a
              href="https://trustbucket.canny.io/"
              target="_blank"
              rel="noopener noreferrer"
              className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                pathname.includes("/settings/feedback") && "bg-blue-50"
              }`}
            >
              <svg
                width="20"
                height="20"
                className={`w-4 h-5 mr-2 `}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.83333 6.66665H14.1667H5.83333ZM5.83333 9.99998H9.16667H5.83333ZM10 16.6666L6.66667 13.3333H4.16667C3.72464 13.3333 3.30072 13.1577 2.98816 12.8452C2.67559 12.5326 2.5 12.1087 2.5 11.6666V4.99998C2.5 4.55795 2.67559 4.13403 2.98816 3.82147C3.30072 3.50891 3.72464 3.33331 4.16667 3.33331H15.8333C16.2754 3.33331 16.6993 3.50891 17.0118 3.82147C17.3244 4.13403 17.5 4.55795 17.5 4.99998V11.6666C17.5 12.1087 17.3244 12.5326 17.0118 12.8452C16.6993 13.1577 16.2754 13.3333 15.8333 13.3333H13.3333L10 16.6666Z"
                  stroke="#94A3B8"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span
                className={`text-sm font-medium ${
                  pathname.includes("/settings/feedback")
                    ? "text-blue-500"
                    : "hover:text-gray-700"
                }`}
              >
                Give Feedback
              </span>
            </a>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink
              exact
              to="/settings/logs"
              className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                pathname.includes("/settings/logs") && "bg-blue-50"
              }`}
            >
              <svg
                className={`w-4 h-4 shrink-0 fill-current text-gray-400 mr-2 ${
                  pathname.includes("/settings/logs") && "text-blue-400"
                }`}
                viewBox="0 0 16 16"
              >
                <path d="M7.001 3h2v4h-2V3zm1 7a1 1 0 110-2 1 1 0 010 2zM15 16a1 1 0 01-.6-.2L10.667 13H1a1 1 0 01-1-1V1a1 1 0 011-1h14a1 1 0 011 1v14a1 1 0 01-1 1zM2 11h9a1 1 0 01.6.2L14 13V2H2v9z" />
              </svg>
              <span
                className={`text-sm font-medium ${
                  pathname.includes("/settings/logs")
                    ? "text-blue-500"
                    : "hover:text-gray-700"
                }`}
              >
                Activity
              </span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SettingsSidebar;
