import { typeObject } from "helpers/getObjectByType";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import lockMiniSVG from "icons/lock-mini.svg";
import { setUpgradePopup } from "store/actions/upgradePopup";

const UnconnectedPlatform = ({ platformType, isPopular }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.account);

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <div className="px-5 py-5 h-full flex flex-col">
        <header className="flex justify-between items-start mb-2"></header>
        <div className="flex items-center mb-4">
          <img
            src={typeObject[platformType]?.image}
            alt="Platform"
            className="max-h-9 sm:max-h-10"
            style={{ maxWidth: 110 }}
          />
          <h2 className="text-xl font-semibold text-gray-800 ml-3">
            {typeObject[platformType]?.title}
          </h2>
          {isPopular && (
            <div className="font-bold ml-auto rounded-full text-xs text-center px-2.5 py-0.5 bg-green-100 text-green-600">
              <span className="">Popular</span>
            </div>
          )}
        </div>
        <div className="font-medium mb-5">
          Connect your account to start{" "}
          {platformType !== "fresha" &&
            platformType !== "bokadirekt" &&
            "collecting and"}{" "}
          displaying reviews.
        </div>
        {/* TODO: Change after backend fix */}
        {user?.selectedCompany?.subscription?.plan === "free" &&
        user?.type !== "appsumo" ? (
          <div
            onClick={() => dispatch(setUpgradePopup(true))}
            className="cursor-pointer bg-blue-600 hover:bg-blue-800 text-center mt-auto transition-all text-white py-2 px-3 rounded flex items-center justify-center w-full"
          >
            <img src={lockMiniSVG} alt="lock" className="mr-2" />
            Upgrade to connect
          </div>
        ) : (
          <Link
            to={`/connect-platforms/${platformType}`}
            className={
              "bg-blue-600 hover:bg-blue-800 block text-center mt-auto transition-all text-white w-full py-2 px-3 rounded"
            }
          >
            Connect
          </Link>
        )}
      </div>
    </div>
  );
};

export default UnconnectedPlatform;
