import axios from "config/axios";
import * as actionTypes from "./actionTypes";
import { finishLoading, startLoading } from "./loader";
// import { finishLoading, startLoading } from "./loader";
import { errorsNotification, successNotification } from "./notification";

export const setWidgetIframe = (payload) => {
  return {
    type: actionTypes.SET_WIDGET_IFRAME,
    payload,
  };
};

export const postWidget = (object, attributes, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/widget", { object, attributes })
      .then((response) => {
        dispatch(setWidgetIframe(response.data?.link));
        dispatch(successNotification(response?.data?.message));
        dispatch(finishLoading());
        callback && callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        dispatch(finishLoading());
      });
  };
};

export const setWidget = (payload) => {
  return {
    type: actionTypes.SET_WIDGET,
    payload,
  };
};

export const setWidgetRatings = (payload) => {
  return {
    type: actionTypes.SET_WIDGET_RATINGS,
    payload,
  };
};

export const getWidget = (id, callback) => {
  return (dispatch) => {
    axios
      .get(`/widget?id=${id}`)
      .then((response) => {
        dispatch(setWidget(response?.data?.widget));
        dispatch(setWidgetRatings(response?.data?.ratings));
        callback && callback();
      })
      .catch((error) => {
        console.log("WIDGET ERROR", error?.response);
      });
  };
};

export const setPredefinedWidgets = (payload) => {
  return {
    type: actionTypes.SET_PREDEFINED_WIDGETS,
    payload,
  };
};
