import { typeObject } from "./getObjectByType";

export const generateInvitationLinks = (ratings, user) => {
  return ratings?.map(
    (item, index) =>
      isSupportedPlatform(item.type) && (
        <option
          key={index}
          value={
            item.type === "google" && item.placeId
              ? `https://search.google.com/local/writereview?placeid=${item.placeId}`
              : item.type === "trustbucket"
              ? `https://reviews.trustbucket.io/write-review/${user?.selectedCompany?.slug}?campaignId={campaignId}&name={firstName}%20{lastName}&email={email}`
              : item.url
          }
          // value={item.url}
        >
          {item.type === "trustbucket"
            ? user?.selectedCompany?.name
            : item.name}{" "}
          ({typeObject[item.type]?.title})
        </option>
      )
  );
};

const isSupportedPlatform = (platform) =>
  platform === "trustbucket" || platform === "google" || platform === "recose";

//
