import ForgotPassword from "containers/pages/ForgotPassword";
import InviteWelcome from "containers/pages/InviteWelcome";
import Login from "containers/pages/Login";
import Register from "containers/pages/Register";
import ResetPassword from "containers/pages/ResetPassword";
import Welcome from "containers/pages/Welcome";
// import Signin from "containers/pages/Signin";
// import Signup from "containers/pages/Signup";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

export function PublicRoutes() {
  return (
    <Switch>
      <Route path="/auth/login" component={Login} />
      <Route path="/auth/register" component={Register} />
      <Route path="/auth/welcome/:id" component={Welcome} />
      <Route path="/auth/invite-welcome/:id" component={InviteWelcome} />
      <Route path="/auth/forgot-password" component={ForgotPassword} />
      <Route path="/auth/reset-password" component={ResetPassword} />
      <Redirect from="/auth" exact={true} to="/auth/login" />
      <Redirect to="/auth/login" />
    </Switch>
  );
}
