import ModalBasic from "components/ModalBasic";
import React from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { inviteTeamMember } from "store/actions/auth";
// import { useDispatch } from "react-redux";
// import { addCompany } from "store/actions/auth";
// import { useHistory } from "react-router-dom";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
};

const registrationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
});

const InviteMemberModal = ({ modalOpen, setModalOpen }) => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const selectedBusinessId = localStorage.getItem("selectedBusinessId");

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      dispatch(
        inviteTeamMember(
          values.firstName,
          values.lastName,
          values.email,
          selectedBusinessId,
          () => setModalOpen(false)
        )
      );
    },
  });

  return (
    <ModalBasic
      id="add-member-modal"
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title="Invite team member"
    >
      <form noValidate onSubmit={formik.handleSubmit}>
        <div>
          {/* Modal content */}
          <div className="px-5 py-4 space-y-6">
            {/* Business Profile */}
            <section>
              <div className="text-sm mb-4">
                An email will be sent to your team member where they can confirm
                their email and chose password.
              </div>
              <div className="mb-3">
                <div className="">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="name"
                  >
                    First name
                  </label>
                  <input
                    id="name"
                    type="text"
                    {...formik.getFieldProps("firstName")}
                    className={clsx(
                      "form-input w-full",
                      {
                        "is-invalid":
                          formik.touched.firstName && formik.errors.firstName,
                      },
                      {
                        "is-valid":
                          formik.touched.firstName && !formik.errors.firstName,
                      }
                    )}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <span className="text-red-600 text-xs">
                      {formik.errors.firstName}
                    </span>
                  )}
                </div>
              </div>
            </section>

            <div className="mb-3">
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="last-name"
              >
                Last name
              </label>
              <input
                id="last-name"
                type="text"
                {...formik.getFieldProps("lastName")}
                className={clsx(
                  "form-input w-full",
                  {
                    "is-invalid":
                      formik.touched.lastName && formik.errors.lastName,
                  },
                  {
                    "is-valid":
                      formik.touched.lastName && !formik.errors.lastName,
                  }
                )}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <span className="text-red-600 text-xs">
                  {formik.errors.lastName}
                </span>
              )}
            </div>
            <div className="">
              <label className="block text-sm font-medium mb-1" htmlFor="email">
                Email
              </label>
              <input
                id="email"
                type="email"
                {...formik.getFieldProps("email")}
                className={clsx(
                  "form-input w-full",
                  {
                    "is-invalid": formik.touched.email && formik.errors.email,
                  },
                  {
                    "is-valid": formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <span className="text-red-600 text-xs">
                  {formik.errors.email}
                </span>
              )}
            </div>
            <hr className="" />
          </div>
          {/* Modal footer */}
          <div className="px-5 py-4">
            <div className="flex flex-wrap justify-end space-x-2">
              <div
                className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setModalOpen(false);
                }}
              >
                Cancel
              </div>
              <button
                type="submit"
                className="btn-sm bg-blue-500 hover:bg-blue-600 text-white"
                // disabled={!formik.isValid}
              >
                Send invite
              </button>
            </div>
          </div>
        </div>
      </form>
    </ModalBasic>
  );
};

export default React.memo(InviteMemberModal);
