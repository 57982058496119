import AutomaticCollectionSidebar from "components/AutomaticCollection/AutomaticCollectionSidebar";
import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import automatic1SVG from "icons/automatic-col-1.svg";
import automatic2SVG from "icons/automatic-col-2.svg";
import automatic3SVG from "icons/automatic-col-3.svg";
import automatic4SVG from "icons/automatic-col-4.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAutomaticCollectionSettings } from "store/actions/campaign";

const AutomaticCollection = () => {
  const dispatch = useDispatch();
  const adminId = localStorage.getItem("id");
  const flag = true;
  const automaticCollectionSettings = useSelector(
    (state) => state.campaign.automaticCollectionSettings
  );

  useEffect(() => {
    dispatch(getAutomaticCollectionSettings(adminId));
    // eslint-disable-next-line
  }, []);
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {flag ? (
        <div className="w-full bg-white border border-gray-200 px-5 py-5 lg:py-8 lg:px-8">
          <div className="flex md:flex-row flex-col">
            <div className="md:w-2/12 w-full md:mb-0 mb-4">
              <AutomaticCollectionSidebar />
            </div>
            <div className="md:w-10/12 w-full mb-6">
              <div className="text-black font-bold text-xl mb-9">
                This is how automatic invitations work
              </div>
              <div className="pb-9 border-b mb-8 border-gray-300">
                <div className="flex items-center mb-6">
                  <img
                    src={automatic1SVG}
                    style={{ width: 65, height: 65 }}
                    alt="automatic collection"
                  />
                  <div className="text-black ml-6" style={{ lineHeight: 1.9 }}>
                    <span className="font-bold">1.</span> Your customer makes a
                    purchase or has a service experience with your business
                    (e.g. places an order, receives an invoice, or similar)
                  </div>
                </div>
                <div className="flex items-center mb-6">
                  <img
                    src={automatic2SVG}
                    style={{ width: 65, height: 65 }}
                    alt="automatic collection"
                  />
                  <div className="text-black ml-6" style={{ lineHeight: 1.9 }}>
                    <span className="font-bold">2.</span> You send them a
                    transactional email. This will trigger a separate email to
                    be sent to Trustbucket, which notifies us to send a review
                    invitation.
                  </div>
                </div>
                <div className="flex items-center mb-6">
                  <img
                    src={automatic3SVG}
                    style={{ width: 65, height: 65 }}
                    alt="automatic collection"
                  />
                  <div className="text-black ml-6" style={{ lineHeight: 1.9 }}>
                    <span className="font-bold">3.</span> We’ll que an
                    invitation to be sent. It will be delivered in 7 days by
                    default, but you can also choose a custom send time.
                  </div>
                </div>
                <div className="flex items-center">
                  <img
                    src={automatic4SVG}
                    style={{ width: 65, height: 65 }}
                    alt="automatic collection"
                  />
                  <div className="text-black ml-6" style={{ lineHeight: 1.9 }}>
                    <span className="font-bold">4.</span> Your customer will
                    receive the invitation, which allows them to write a review
                    of their experience with your business.
                  </div>
                </div>
              </div>
              <Link
                className="btn bg-blue-600 text-white transition-colors hover:bg-blue-700"
                to="/collect-reviews/automatic-collection-setup"
              >
                {automaticCollectionSettings?.length > 0
                  ? "Update automatic collection"
                  : "Start collecting reviews automatically"}
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="text-black font-bold text-2xl mb-4">
            Automatic review collection
          </div>
          <div>Coming soon...</div>
        </>
      )}
    </div>
  );
};

export default AutomaticCollection;
