import axios from "config/axios";
import * as actionTypes from "./actionTypes";
import { finishLoading, startLoading } from "./loader";
import { errorsNotification, successNotification } from "./notification";
import { setRatings } from "./ratings";

export const setTags = (payload) => {
  return {
    type: actionTypes.SET_TAGS,
    payload,
  };
};

export const getTags = (businessId) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(`/tags/list?id=${businessId}`)
      .then((response) => {
        dispatch(setTags(response.data.tags));
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.message));
      });
  };
};

export const addTag = (name, autoPopulate, businessId, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`/tags/create`, { name, autoPopulate, businessId })
      .then((response) => {
        dispatch(getTags());
        dispatch(finishLoading());
        dispatch(successNotification(response.data.msg));
        callback && callback();
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.msg));
      });
  };
};

export const deleteTag = (id) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .delete(`/tags/delete/${id}`)
      .then((response) => {
        dispatch(getTags());
        dispatch(finishLoading());
        dispatch(successNotification(response.data.message));
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.message));
      });
  };
};

export const assignTag = (tag, reviewId, businessId, callback) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    axios
      .put(`/ratings/tag/assign`, { tagId: tag?.id, reviewId })
      .then((response) => {
        dispatch(getTags(businessId));
        dispatch(finishLoading());
        let previousRatings = getState().ratings?.ratings;
        let foundRating = previousRatings.find((el) => el?.id === reviewId);

        if (foundRating) {
          foundRating = { ...foundRating, tags: tag };
        }
        let newRatings = [];
        previousRatings.forEach((el) => {
          let newEl = el;
          if (el.id === reviewId) {
            newEl = foundRating;
          }
          newRatings.push(newEl);
        });
        dispatch(setRatings(newRatings));
        dispatch(successNotification(response.data.message));
        callback && callback();
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.message));
      });
  };
};
