import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { sendFeedback } from "store/actions/auth";
import { errorsNotification } from "store/actions/notification";

function FeedbackPanel() {
  const dispatch = useDispatch();
  const [feedback, setFeedback] = useState("");

  const handleFeedbackSubmit = () => {
    if (feedback.length > 4) {
      dispatch(sendFeedback(feedback, () => setFeedback("")));
    } else {
      dispatch(errorsNotification("Feedback must have at least 4 letters"));
    }
  };

  return (
    <div className="grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <div>
          <h2 className="text-2xl text-gray-800 font-bold mb-4">
            Give Feedback
          </h2>
          <div className="text-sm">
            Our product depends on customer feedback to improve the overall
            experience!
          </div>
        </div>

        {/* Tell us in words */}
        <section>
          <h3 className="text-xl leading-snug text-gray-800 font-bold mb-5">
            Tell us in words
          </h3>
          {/* Form */}
          <label className="sr-only" htmlFor="feedback">
            Leave a feedback
          </label>
          <textarea
            id="feedback"
            className="form-textarea w-full focus:border-gray-300"
            rows="4"
            placeholder="I really enjoy…"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          ></textarea>
        </section>

        <section className="h-12"></section>
      </div>

      {/* Panel footer */}
      <footer>
        <div className="flex flex-col px-6 py-5 border-t border-gray-200">
          <div className="flex self-end">
            <button
              onClick={handleFeedbackSubmit}
              className="btn bg-blue-500 hover:bg-blue-600 text-white ml-3"
            >
              Save Changes
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default FeedbackPanel;
