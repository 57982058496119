export const createTrustbucketURL = (user) => {
  if (!user?.selectedCompany?.name) return "#!";

  return `https://reviews.trustbucket.io/${user?.selectedCompany?.slug}`;
};

export const getPlatformURL = (user, platform, isCollect = false) => {
  if (platform === "trustbucket" || platform === "all") {
    return createTrustbucketURL(user);
  } else {
    const foundPlatform = user?.selectedCompany?.ratings.find(
      (el) => el.type === platform
    );

    if (foundPlatform) {
      if (isCollect) {
        if (foundPlatform.type === "google") {
          return `https://search.google.com/local/writereview?placeid=${foundPlatform.placeId}`;
        } else if (foundPlatform.type === "trustbucket") {
          return `https://reviews.trustbucket.io/write-review/${user?.selectedCompany?.slug}`;
        } else {
          return foundPlatform.url;
        }
      } else {
        return foundPlatform.url;
      }
    } else {
      return createTrustbucketURL(user);
    }
  }
};
