import React from "react";
import logo from "icons/logo-dark.svg";
import { useFormik } from "formik";
import clsx from "clsx";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMemberPassword } from "store/actions/auth";

const initialValues = {
  password: "",
};

const registrationSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
});

export default function InviteWelcome() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      dispatch(setMemberPassword(id, values.password));
      // dispatch(
      //   postWelcome({
      //     ...values,
      //     password: values.password ? values.password : "google",
      //     id,
      //   })
      // );
    },
  });

  return (
    <div className="bg-white min-h-screen">
      <div className="md:px-10 px-5 py-10 flex items-center">
        <img className="w-auto h-5 object-contain" src={logo} alt="Workflow" />
      </div>
      <div
        style={{ height: "calc(100vh - 100px)" }}
        className="flex flex-col max-w-full sm:max-w-sm mx-auto md:justify-center px-5"
      >
        <div className="font-medium text-2xl mb-4 md:text-center text-black">
          Welcome to Trustbucket 👋🏻
        </div>
        <div className="text-gray-600 md:text-center text-sm mb-10">
          Let’s get your team account up and running.
        </div>
        <div className="pb-10">
          <form noValidate onSubmit={formik.handleSubmit}>
            <div className="">
              <label className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Choose a password"
                  {...formik.getFieldProps("password")}
                  className={clsx(
                    "appearance-none block w-full px-4 py-3 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm",
                    {
                      "is-invalid":
                        formik.touched.password && formik.errors.password,
                    },
                    {
                      "is-valid":
                        formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <span className="text-red-600 text-xs">
                    {formik.errors.password}
                  </span>
                )}
              </div>
            </div>

            <button
              type="submit"
              className="w-full mt-8 flex justify-center py-3 px-4 border border-transparent rounded shadow-sm transition-colors font-medium text-white bg-blue-600 hover:bg-blue-700"
            >
              Start using Trustbucket
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
