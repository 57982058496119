import AutomaticCollectionSidebar from "components/AutomaticCollection/AutomaticCollectionSidebar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getEmailTemplates } from "store/actions/emailTemplates";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createAutomaticCollection,
  getAutomaticCollectionSettings,
} from "store/actions/campaign";

const registrationSchema = Yup.object().shape({
  template: Yup.string().required("Template is required"),
  delay: Yup.string().required("Delay is required"),
});

const AutomaticCollectionSetup = () => {
  const dispatch = useDispatch();
  const adminId = localStorage.getItem("id");

  const templates = useSelector((state) => state.emailTemplates.emailTemplates);
  const automaticCollectionSettings = useSelector(
    (state) => state.campaign.automaticCollectionSettings
  );
  const user = useSelector((state) => state.auth.account);

  const formik = useFormik({
    validationSchema: registrationSchema,
    initialValues: {
      template: automaticCollectionSettings?.template || templates[0]?.id,
      reminder: automaticCollectionSettings?.reminder || true,
      delay: automaticCollectionSettings?.delay || 7,
      adminId,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        createAutomaticCollection(
          values.template,
          values.reminder,
          values.delay,
          values.adminId,
          () => {}
        )
      );
    },
  });

  useEffect(() => {
    dispatch(getEmailTemplates(adminId));
    dispatch(getAutomaticCollectionSettings(adminId));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <div className="text-black font-bold text-2xl mb-4">
        Automatic review collection
      </div>
      <form noValidate onSubmit={formik.handleSubmit}>
        <div className="w-full bg-white border border-gray-200 px-5 py-5 lg:py-8 lg:px-8">
          <div className="flex md:flex-row flex-col">
            <div className="md:w-2/12 w-full md:mb-0 mb-4">
              <AutomaticCollectionSidebar />
            </div>
            <div className="md:w-10/12 w-full mb-6">
              <div className="text-black font-bold text-xl mb-2">
                Copy and add unique Trustbucket email adress to the BCC
              </div>
              <div className="mt-2 text-sm text-gray-600 pb-2 mb-8 border-b border-gray-200">
                Go to your eCommerce, CRM or invoicing system’s email settings.
                Copy and add your unique Trustbucket email adress to the BCC
                field. BCC’ing your Trustbucket email adress to order
                confirmation emails will trigger our system to send automatic
                review invitations to the same customers.{" "}
              </div>
              <div className="flex md:flex-row flex-col mb-9 items-center justify-between py-4 md:px-10 px-5 bg-gray-100">
                <div className="font-bold text-sm text-black text-line md:mb-0 mb-2 md:w-auto w-full">
                  no-reply+{user?.selectedCompany?.slug}
                  @trustbucket.io
                </div>
                <button className="btn bg-white text-black border border-gray-200">
                  Copy email adress
                </button>
              </div>
              <div className="text-black font-bold text-xl mb-2">
                Set up automation to get a continous flow of reviews
              </div>
              <div className="mt-2 text-sm text-gray-600 mb-9">
                Get reviews and grow trust in your brand. To ensure your
                customers are able to write meaningful reviews, we recommend you
                set up automatatic review collections that suits your business
                needs.
              </div>
              <div className="flex items-center justify-between pb-1.5 border-b border-gray-200 mb-6">
                <div className="font-bold text-black">Email template</div>
                <Link
                  className="font-medium text-sm text-blue-600"
                  to="/template-editor"
                >
                  Edit templates
                </Link>
              </div>
              <div className="mb-9">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="template"
                >
                  Default email template for invitations
                </label>
                <select
                  id="template"
                  name="template"
                  className="form-select w-full"
                  {...formik.getFieldProps("template")}
                  // {...formik.getFieldProps("reviewsPageLanguage")}
                >
                  {templates?.length &&
                    templates?.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
                {formik.touched.template && formik.errors.template && (
                  <span className="text-red-600 text-xs">
                    {formik.errors.template}
                  </span>
                )}
              </div>
              <div className="font-bold text-black pb-1.5 mb-6 border-b border-gray-200">
                Reminder
              </div>
              <div className="mb-9 flex items-center justify-between">
                <div className="w-10/12">
                  <div className="font-semibold mb-1 text-black">
                    Send reminder email after 48 hours?
                  </div>
                  <div className="flex items-center">
                    <div className="text-sm text-gray-600">
                      The email will only be sent to your customers who have not
                      written a review. This can increase your chances to get a
                      review.
                    </div>
                  </div>
                </div>
                <div className="form-switch">
                  <input
                    type="checkbox"
                    id="reminder"
                    name="reminder"
                    className="sr-only"
                    {...formik.getFieldProps("reminder")}
                    checked={formik.values.reminder}
                  />
                  <label className="bg-gray-400" htmlFor="reminder">
                    <span
                      className="bg-white shadow-sm"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Switch label</span>
                  </label>
                </div>
              </div>
              <div className="font-bold text-black pb-1.5 mb-6 border-b border-gray-200">
                Delay
              </div>
              <div className="mb-6">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="delay"
                >
                  Days of delay for review invitations
                </label>
                <select
                  name="delay"
                  id="delay"
                  className="form-select w-full"
                  {...formik.getFieldProps("delay")}
                  // {...formik.getFieldProps("reviewsPageLanguage")}
                >
                  <option value="7">7</option>
                  <option value="6">6</option>
                  <option value="5">5</option>
                  <option value="4">4</option>
                  <option value="3">3</option>
                  <option value="2">2</option>
                  <option value="1">1</option>
                </select>
                {formik.touched.delay && formik.errors.delay && (
                  <span className="text-red-600 text-xs">
                    {formik.errors.delay}
                  </span>
                )}
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="btn bg-blue-600 text-white transition-colors hover:bg-blue-700"
                >
                  {automaticCollectionSettings
                    ? "Save Changes"
                    : "Create automatic collection"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AutomaticCollectionSetup;
