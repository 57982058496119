import React, { useState } from "react";
import dayjs from "dayjs";
import verifiedSVG from "icons/verified.svg";
import replySVG from "icons/reply.svg";
import replyLinesSVG from "icons/reply-lines.svg";
// import likeSVG from "icons/like.svg";
import { typeObject } from "helpers/getObjectByType";
import parse from "html-react-parser";
import PlatformStars from "components/PlatformStars/PlatformStars";
import { useDispatch } from "react-redux";
import {
  deleteReplyTrustbucket,
  deleteReplyGoogle,
  postReplyTrustbucket,
  postReplyGoogle,
  postReply,
} from "store/actions/ratings";
import EditTagsModal from "components/EditTagsModal/EditTagsModal";
// import { forEach } from "underscore";

const SingleReview = ({
  data,
  businessName,
  businessId,
  changeReplyCallback,
}) => {
  const dispatch = useDispatch();
  const [openReply, setOpenReply] = useState(false);
  const [replyValue, setReplyValue] = useState(data?.reply);
  const [addTagModal, setAddTagModal] = useState(false);
  const tags = data?.tags;

  // const handleReplyPost = () => {
  //   if (data.type === "trustbucket") {
  //     dispatch(
  //       postReplyTrustbucket(data?.id, replyValue, () => {
  //         changeReplyCallback(data?._id, replyValue);
  //         setOpenReply(false);
  //       })
  //     );
  //   } else if (data.type === "google") {
  //     dispatch(
  //       postReplyGoogle(data?.url, data?._id, replyValue, () => {
  //         changeReplyCallback(data?._id, replyValue);
  //         setOpenReply(false);
  //       })
  //     );
  //   }
  // };

  const handleReplyPost = () => {
    if (businessName) {
      dispatch(
        postReply(data?.id, businessName, replyValue, () => {
          changeReplyCallback(data?.id, replyValue);
          setOpenReply(false);
        })
      );
    }
  };

  const handleReplyDelete = () => {
    if (data.type === "trustbucket") {
      dispatch(
        deleteReplyTrustbucket(data?.id, () => {
          changeReplyCallback(data?.id, "", true);
          setOpenReply(false);
          setReplyValue("");
        })
      );
    } else if (data.type === "google") {
      dispatch(
        deleteReplyGoogle(data?.url, data?._id, () => {
          changeReplyCallback(data?._id, "", true);
          setOpenReply(false);
          setReplyValue("");
        })
      );
    }
  };

  const canEditReply =
    data?.type === "trustbucket" ||
    (data?.type === "google" && !data.url.includes("https://"));

  return (
    <>
      <EditTagsModal
        tagModalOpen={addTagModal}
        setTagModalOpen={setAddTagModal}
        data={data}
        businessId={businessId}
      />
      <div className="px-5 pt-3 pb-6 border border-gray-200 bg-white mb-3 rounded-lg">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center">
            <PlatformStars rating={data?.rating} type={data?.type} />
            <div className="text-sm mx-4 lg:block hidden">
              {data?.createdAt &&
                dayjs(data?.createdAt).format("MMMM DD, YYYY")}
            </div>
            {data?.verified && (
              <div className="flex items-center">
                <img src={verifiedSVG} alt="Verified" />
                <div className="text-xs text-green-600 ml-2">Verified</div>
              </div>
            )}
          </div>
          <div>
            <img
              src={typeObject[data.type]?.image}
              alt="Platform"
              className="h-8 object-contain"
              style={{ maxWidth: 110 }}
            />
          </div>
        </div>
        {tags ? (
          <div className="flex items-center">
            <div className="text-sm mr-2">Tags:</div>
            <div className="text-sm mr-1">{tags?.name}</div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setAddTagModal(true);
              }}
              className="text-blue-600 underline ml-4 text-sm cursor-pointer"
            >
              Edit
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            <div className="text-sm mr-2">Tags:</div>
            <div className="text-sm mr-1">N/A</div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setAddTagModal(true);
              }}
              className="text-blue-600 underline ml-4 text-sm cursor-pointer"
            >
              Edit
            </div>
          </div>
        )}
        <div className="sm:hidden block text-sm text-gray-600 mb-3.5">
          by <span className="font-medium capitalize">{data?.name}</span> on{" "}
          <span className="font-medium">
            {data?.createdAt && dayjs(data?.createdAt).format("MMMM DD, YYYY")}
          </span>
        </div>
        {data?.title && (
          <div className="font-bold text-black mb-2 lg:mt-4 mt-0">
            {data?.title}
          </div>
        )}
        <div className="text-sm mb-6 text-5">
          {parse(data?.description || "")}
        </div>
        <div className="text-sm lg:block hidden">
          by <span className="font-medium capitalize">{data?.name}</span>
        </div>
        <div className="flex items-center mt-6">
          {/* TODO add link here from api */}
          {!data?.reply &&
            !openReply &&
            (canEditReply ? (
              <div
                onClick={() => setOpenReply(true)}
                className="flex items-center cursor-pointer mr-5 text-sm text-blue-600 font-medium py-1.5 px-2.5 rounded border border-gray-300"
              >
                <img src={replySVG} alt="reply" className="mr-2" />
                <span>Reply</span>
              </div>
            ) : (
              <a
                href={data?.url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center mr-5 text-sm text-blue-600 cursor-pointer font-medium py-1.5 px-2.5 rounded border border-gray-300"
              >
                <img src={replySVG} alt="reply" className="mr-2" />
                <span>Reply</span>
              </a>
            ))}
        </div>
        {openReply && (
          <div className="flex justify-between mt-4">
            <div className="w-1/12">
              <img src={replyLinesSVG} alt="reply" />
            </div>

            {/* {parse(data?.reply)} */}
            <div className="p-2 bg-gray-100 w-11/12">
              <textarea
                className="form-textarea p-5 h-36 w-full text-sm"
                value={replyValue}
                placeholder="Reply to this review here..."
                onChange={(e) => setReplyValue(e.target.value)}
              ></textarea>
              <div className="flex items-center mt-2.5">
                <div
                  onClick={handleReplyPost}
                  className="btn bg-blue-600 text-white hover:bg-blue-700 mr-2 cursor-pointer"
                >
                  Post reply
                </div>
                <div
                  onClick={() => setOpenReply(false)}
                  className="btn text-blue-600 cursor-pointer"
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        )}
        {data?.reply && !openReply && (
          <div className="flex justify-between mt-4">
            <div className="w-1/12">
              <img src={replyLinesSVG} alt="reply" />
            </div>
            <div className="bg-gray-100 py-4 ml-3 px-5 w-11/12 text-sm text-5">
              <div className="mb-3.5">{parse(data.reply)}</div>
              {canEditReply && (
                <>
                  <div
                    onClick={() => setOpenReply(true)}
                    className="btn bg-blue-600 text-white hover:bg-blue-700 mr-2 cursor-pointer"
                  >
                    Edit reply
                  </div>
                  <div
                    onClick={handleReplyDelete}
                    className="btn text-blue-600 cursor-pointer"
                  >
                    Delete
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SingleReview;
