import Step1 from "components/CreateCampaign/Step1/Step1";
import Step2 from "components/CreateCampaign/Step2/Step2";
import Step3 from "components/CreateCampaign/Step3/Step3";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCampaign } from "store/actions/campaign";
import { errorsNotification } from "store/actions/notification";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const validateObject = (object) =>
  object.firstName &&
  object.lastName &&
  object.email &&
  validateEmail(object.email);

const CreateCampaign = () => {
  const dispatch = useDispatch();
  const adminId = localStorage.getItem("id");
  const companies = useSelector((state) => state.auth.selectedCompany);
  const name = companies?.businessUserName;
  const businessId = companies?.id;

  const [step, setStep] = useState(1);

  const [firstNames, setFirstNames] = useState("");
  const [lastNames, setLastNames] = useState("");
  const [emails, setEmails] = useState("");

  const [failedList, setFailedList] = useState([]);
  const [successList, setSuccessList] = useState([]);

  const [chosenTemplate, setChosenTemplate] = useState();
  const [reminder, setReminder] = useState(true);

  useEffect(() => {
    const firstNamesArr = firstNames.split("\n");
    const lastNameArr = lastNames.split("\n");
    const emailsArr = emails.split("\n");

    let objects = [];

    let arr = firstNamesArr;

    if (lastNameArr.length > arr.length) {
      arr = lastNameArr;
    }

    if (emailsArr.length > arr.length) {
      arr = emailsArr;
    }

    arr.forEach((item, index) => {
      objects.push({
        firstName: firstNamesArr[index],
        lastName: lastNameArr[index],
        email: emailsArr[index],
      });
    });

    let success = [];
    let fail = [];

    objects.forEach((el) => {
      if (validateObject(el)) {
        success.push(el);
      } else {
        fail.push(el);
      }
    });

    setSuccessList(success);
    setFailedList(fail);
  }, [firstNames, lastNames, emails]);

  const handleCreateCampaign = () => {
    let capitalizedList = [];
    successList.forEach((el) => {
      let newEl = {
        firstName: capitalizeFirstLetter(el.firstName),
        lastName: capitalizeFirstLetter(el.lastName),
        email: el.email,
      };
      capitalizedList = [...capitalizedList, newEl];
    });

    dispatch(
      createCampaign(
        name,
        chosenTemplate,
        reminder,
        capitalizedList,
        adminId,
        businessId,
        () => setStep(3)
      )
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <div className="text-black font-bold text-xl lg:text-2xl mb-4">
        {step === 1 && "Create campaign 🚀"}
        {step === 2 && "Select an email template and send"}
        {step === 3 && "Done! 🙌"}
      </div>
      <div className="w-full bg-white border border-gray-200 px-5 py-5 lg:py-11 lg:px-12">
        <div className="relative sm:w-80 w-72 mx-auto mb-8">
          <div
            className="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-gray-200"
            aria-hidden="true"
          ></div>
          <ul className="relative flex justify-between">
            <li>
              <div className="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-blue-600 text-white">
                {step > 1 ? (
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 7L5 11L15 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  "1"
                )}
              </div>
            </li>
            <li>
              <div
                className={
                  "flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold " +
                  (step >= 2
                    ? "bg-blue-600 text-white"
                    : "bg-gray-100 text-gray-500")
                }
              >
                {step > 2 ? (
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 7L5 11L15 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  "2"
                )}
              </div>
            </li>
            <li>
              <div
                className={
                  "flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold " +
                  (step >= 3
                    ? "bg-blue-600 text-white"
                    : "bg-gray-100 text-gray-500")
                }
              >
                {step > 3 ? (
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 7L5 11L15 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  "3"
                )}
              </div>
            </li>
          </ul>
        </div>
        {step === 1 && (
          <Step1
            firstNames={firstNames}
            lastNames={lastNames}
            emails={emails}
            setFirstNames={setFirstNames}
            setLastNames={setLastNames}
            setEmails={setEmails}
            failedList={failedList}
            successList={successList}
            onSubmit={() => {
              if (successList.length > 50) {
                dispatch(
                  errorsNotification(
                    "Invitations limit per one campaign is 50."
                  )
                );
              } else setStep(2);
            }}
          />
        )}
        {step === 2 && (
          <Step2
            chosenTemplate={chosenTemplate}
            setChosenTempate={setChosenTemplate}
            onSubmit={handleCreateCampaign}
            onBack={() => setStep(1)}
            reminder={reminder}
            setReminder={setReminder}
          />
        )}
        {step === 3 && <Step3 />}
      </div>
    </div>
  );
};

export default CreateCampaign;
