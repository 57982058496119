import React, { useEffect, useState } from "react";

import WelcomeBanner from "partials/dashboard/WelcomeBanner";
import Datepicker from "components/Datepicker";
import OverviewCard from "components/Overview/OverviewCard";
import CardOverall from "components/Dashboard/CardOverall";
import CardPlatform from "components/Dashboard/CardPlatform";
// import PlatformReviews from "components/Dashboard/PlatformReviews";
// import WidgetChart from "components/Dashboard/WidgetChart";
import { useDispatch, useSelector } from "react-redux";
import { getRatingsList } from "store/actions/ratings";
// import { getStats } from "store/actions/stats";
import {
  getCampaignRecievers,
  getSentInvitations,
} from "store/actions/campaign";
import ExpirationBaner from "components/ExpirationBaner/ExpirationBaner";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import relativeTime from "dayjs/plugin/relativeTime";
import AnnouncementBaner from "components/AnnouncementBaner/AnnouncementBaner";
// import { getLastAnnouncement } from "store/actions/announcement";
dayjs.extend(relativeTime);

function Dashboard() {
  const dispatch = useDispatch();
  const adminId = localStorage.getItem("id");
  const companies = useSelector((state) => state.auth.selectedCompany);
  const businessId = companies?.id;
  const { auth, stats } = useSelector((state) => state);
  const { account: user } = auth;
  // const invitationsSent = useSelector(
  //   (state) => state.campaign.invitationsSent
  // );
  const campaignRecievers = useSelector(
    (state) => state.campaign.campaignRecievers
  );
  // const lastAnnouncement = useSelector(
  //   (state) => state.announcement.lastAnnouncement
  // );

  const unrepliedReviews = useSelector((state) => state.ratings.unrepliedTotal);

  const [allTime, setAllTime] = useState(true);

  useEffect(() => {
    if (allTime && user) {
      // dispatch(getStats());
    }
  }, [dispatch, allTime, user]);

  useEffect(() => {
    if (adminId) {
      dispatch(getSentInvitations(adminId));
      dispatch(getCampaignRecievers(adminId));
      // !lastAnnouncement && dispatch(getLastAnnouncement());

      // eslint-disable-next-line
    }
  }, []);

  useEffect(() => {
    if (businessId) {
      dispatch(getRatingsList(businessId));
      // eslint-disable-next-line
    }
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Welcome banner */}
      <div className="lg:hidden block mb-3">
        <AnnouncementBaner />
      </div>
      <div className="lg:hidden block mb-3">
        <ExpirationBaner
          open={user?.selectedCompany?.subscription?.plan === "trial"}
        >
          Your free trial ends in{" "}
          <strong>
            {dayjs(user?.selectedCompany?.subscription?.ends).fromNow(true)}
          </strong>
          .{" "}
          <Link className="underline" to="/settings/plans">
            Upgrade plan
          </Link>
        </ExpirationBaner>
      </div>
      <WelcomeBanner />

      {/* Dashboard actions */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Avatars */}
        {/* <DashboardAvatars /> */}
        <div className="text-xl mb-2 sm:mb-0 sm:text-3xl font-bold text-black">
          Overview ✨
        </div>

        {/* Right: Actions */}
        <div className="flex flex-col-reverse sm:grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          <button
            className={
              "btn bg-white px-5 sm:mt-0 mt-2 w-max border border-transparent hover:border-blue-600 hover:text-blue-600 " +
              (allTime ? "border-blue-600 text-blue-600" : "text-gray-600")
            }
            onClick={() => !allTime && setAllTime(true)}
          >
            <span className="block w-max">All-time</span>
          </button>
          {/* Datepicker built with flatpickr */}
          <Datepicker
            isActive={!allTime}
            onClose={() => setAllTime(false)}
            align="right"
          />
        </div>
      </div>

      {/* Cards */}
      <div className="grid grid-cols-12 gap-6">
        {user?.selectedCompany?.ratings?.map((item, index) => {
          if (item.type === "overall") {
            return (
              <OverviewCard
                key={index}
                title="Total reviews"
                description="in chosen time-period"
                number={allTime ? item.ratingCount : stats.count.overall}
                linkText="Manage reviews ->"
                linkUrl="/manage-reviews"
              />
            );
          } else {
            return null;
          }
        })}
        <OverviewCard
          title="Invitations sent"
          description="in chosen time-period"
          number={campaignRecievers?.length || 0}
          linkText="Send invitations ->"
          linkUrl="/collect-reviews/create-campaign"
        />
        <OverviewCard
          title="Unreplied reviews"
          description="in chosen time-period"
          // number={stats?.countNoReply || 0}
          number={unrepliedReviews || 0}
          linkText="Reply to reviews ->"
          linkUrl="/manage-reviews"
        />

        {user?.selectedCompany?.ratings?.map((item, index) => {
          if (item.type === "overall") {
            let rating = "N/A";

            if (!allTime) {
              rating = stats.rating.overall;
            } else if (item.rating) {
              rating = item.rating;
            }

            return <CardOverall key={index} rating={rating} />;
          } else {
            return (
              <CardPlatform
                key={`${index}-2`}
                platformType={item.type}
                rating={allTime ? item.rating : stats.rating[item.type]}
                ratingsLength={
                  allTime ? item.ratingCount : stats.count[item.type]
                }
              />
            );
          }
        })}

        {/* <WidgetChart title="Widget views" /> */}
        {/* <WidgetChart title="Widget clicks" /> */}
      </div>
      {/* <div className="grid grid-cols-12 gap-6 mt-6">
        <PlatformReviews />
      </div> */}
    </div>
  );
}

export default Dashboard;
