import { predefinedStyles } from "helpers/widgetConfiguratorStyles";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  widgetIframe: null,
  widget: null,
  widgetRatings: null,
  predefinedWidgets: predefinedStyles,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_WIDGET_IFRAME:
      return {
        ...state,
        widgetIframe: action.payload,
      };
    case actionTypes.SET_WIDGET:
      return {
        ...state,
        widget: action.payload,
      };
    case actionTypes.SET_WIDGET_RATINGS:
      return {
        ...state,
        widgetRatings: action.payload,
      };
    case actionTypes.SET_PREDEFINED_WIDGETS:
      return {
        ...state,
        predefinedWidgets: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
