import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createEmailTemplate,
  getEmailTemplates,
  updateEmailTemplate,
} from "store/actions/emailTemplates";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { generateInvitationLinks } from "helpers/generateInvitationLinks";
import checkboxGreen from "icons/checkbox-green.svg";
import { setEmailPreviewContent } from "store/actions/campaign";

let defaultContent = `
<h3>Hi {firstName}</h3>
<p></p>
<p>How was your experience with us at {companyName}?</p>
<p></p>
<p>We truly appreciate every customer’s opinion and would love to hear all about your experience.</p>
<p></p>
<p>Thank you,</p>
<p>{firstName} at {companyName}</p>
<p></p>
<p>{review_link: Click here to submit your review}</p>
`;

const NewTemplate = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const adminId = localStorage.getItem("id");

  const user = useSelector((state) => state.auth.account);
  const templates = useSelector((state) => state.emailTemplates.emailTemplates);

  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState(defaultContent);
  const [linkUrl, setLinkUrl] = useState(`https://reviews.trustbucket.io`);
  const [companyNameCopied, setCompanyNameCopied] = useState(false);
  const [firstNameCopied, setFirstNameCopied] = useState(false);
  const [lastNameCopied, setLastNameCopied] = useState(false);
  const [reviewCopied, setReviewCopied] = useState(false);
  const [template, setTemplate] = useState();

  const [useCustomLink, setUseCustomLink] = useState(false);


  const handleSubmit = () => {
    if (id) {
      dispatch(
        updateEmailTemplate(id, name, subject, linkUrl, content, adminId, () =>
          props.history.push("/template-editor")
        )
      );
    } else {
      dispatch(
        createEmailTemplate(adminId, content, linkUrl, name, subject, () =>
          props.history.push("/template-editor")
        )
      );
    }
  };

  useEffect(() => {
    setLinkUrl(`http://localhost:3000/${user?.selectedCompany?.businessName}`);
  }, [user]);

  useEffect(() => {
    if (id) {
      dispatch(getEmailTemplates());
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (id && templates.length) {
      const foundTemplate = templates.find((el) => el.id === id);
      if (foundTemplate) {
        setTemplate(foundTemplate);
        setLinkUrl(foundTemplate.linkUrl);
        setSubject(foundTemplate.subject);
        setName(foundTemplate.name);
        setContent(foundTemplate.content);
      } else {
        props.history.push("/template-editor");
      }
    }
    // eslint-disable-next-line
  }, [templates]);

  useEffect(() => {
    if (companyNameCopied) {
      let tm = setTimeout(() => setCompanyNameCopied(false), 3000);

      return () => clearTimeout(tm);
    }
  }, [companyNameCopied]);

  useEffect(() => {
    if (firstNameCopied) {
      let tm = setTimeout(() => setFirstNameCopied(false), 3000);

      return () => clearTimeout(tm);
    }
  }, [firstNameCopied]);

  useEffect(() => {
    if (lastNameCopied) {
      let tm = setTimeout(() => setLastNameCopied(false), 3000);

      return () => clearTimeout(tm);
    }
  }, [lastNameCopied]);

  useEffect(() => {
    if (reviewCopied) {
      let tm = setTimeout(() => setReviewCopied(false), 3000);

      return () => clearTimeout(tm);
    }
  }, [reviewCopied]);

  return (
    <div className="px-4 sm:px-6 lg:px-10 py-5 sm:py-8 w-full bg-white">
      <Link to="/template-editor" className="text-blue-600 text-sm mb-2 block">
        {"<-"} Go back
      </Link>
      <div className="flex items-center justify-between mb-4 border-b border-gray-200 pb-4 sm:pb-8 sm:mt-0 mt-4">
        <div className="flex sm:flex-row flex-col-reverse sm:items-center">
          <div className="text-black font-bold text-xl sm:text-3xl  sm:mr-3">
            {template ? "Update template" : "Create new template"}
          </div>
        </div>
        <div className="flex flex-col sm:flex-row sm:items-center justify-between">
          <div className="flex items-center">
            <button
              className="btn border-gray-200 hover:border-gray-300 text-gray-600 cursor-pointer mr-3"
              onClick={() => dispatch(setEmailPreviewContent(content))}
            >
              <svg
                className="h-5 w-5 sm:mr-2"
                width="114"
                height="70"
                viewBox="0 0 114 70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_246_2)">
                  <path
                    d="M110.907 34.795C110.907 34.795 86.724 67.089 56.7 67.089C26.676 67.089 2.5 34.795 2.5 34.795C2.5 34.795 26.683 2.5 56.7 2.5C86.717 2.5 110.907 34.795 110.907 34.795Z"
                    stroke="#64748B"
                    strokeWidth="5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M56.703 50.941C65.914 50.941 73.381 43.7117 73.381 34.794C73.381 25.8762 65.914 18.647 56.703 18.647C47.492 18.647 40.025 25.8762 40.025 34.794C40.025 43.7117 47.492 50.941 56.703 50.941Z"
                    stroke="#64748B"
                    strokeWidth="5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_246_2">
                    <rect width="113.407" height="69.589" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className="sm:block hidden">Preview</span>
            </button>
            <button
              onClick={() => setContent(defaultContent)}
              className="btn border-gray-200 hover:border-gray-300 text-gray-600 cursor-pointer mr-3"
            >
              <svg
                className="w-4 h-4 sm:mr-1"
                width="1000"
                height="1000"
                viewBox="0 0 1000 1000"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M851.1 549.9V550.4C851.1 748.8 694.3 910.3 499.6 910.3C304.9 910.3 148 748.9 148 550.4C148 353.2 303.1 192.7 498.8 190.7V256.4C498.8 294.2 531.9 295.1 550.3 278.3L685.4 175.7C685.4 175.7 700.1 163.2 700.1 149.4C700.1 134.9 685.1 123.1 685.1 123.1L550.4 20.4C532 3.59997 498.9 4.59996 498.9 42.3V110.8C264 112.8 70.5996 309.2 70.5996 550.3C70.5996 792.8 262.2 990 500 990C737.8 990 929.4 792.8 929.4 550.4V549.9C929.4 549.9 929.4 505 889.8 505C852.6 505 851.1 549.9 851.1 549.9Z"
                  fill="#475569"
                />
              </svg>
              <span className="sm:block hidden">Reset</span>
            </button>
            <button
              onClick={handleSubmit}
              className="btn bg-blue-500 hover:bg-blue-600 text-white flex items-center"
            >
              <svg
                className="w-4 h-4 sm:mr-2"
                width="768"
                height="768"
                viewBox="0 0 768 768"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M416 0H384C375.2 0 368 7.2 368 16V160C368 168.8 383.2 176 392 176H416C424.8 176 432 168.8 432 160V16C432 7.2 424.8 0 416 0Z"
                  fill="white"
                />
                <path
                  d="M739.8 132.2L636 28.4C618 10.4 593.4 0 567.8 0H511.8C494.2 0 479.8 14.6 479.8 32.4V192.4C479.8 210 465.4 224.4 447.8 224.4H192C174.4 224.4 160 210 160 192.4V32.4C160 14.8 145.6 0 128 0H64C28.8 0 0 28.8 0 64V704C0 739.2 28.8 768 64 768H704C739.2 768 768 739.2 768 704V200C768 174.6 757.8 150.2 739.8 132.2ZM516 672.2C516 689.8 500 704.2 480.4 704.2H159.6C140 704.2 124 689.8 124 672.2V480.2C124 462.6 140 448.2 159.6 448.2H480.4C500 448.2 516 462.6 516 480.2V672.2V672.2Z"
                  fill="white"
                />
              </svg>
              <span className="sm:block hidden">Save template</span>
            </button>
          </div>
        </div>
      </div>

      <div className="flex lg:flex-row flex-col">
        <div className="lg:w-7/12 w-full">
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1" htmlFor="name">
              Template name
            </label>
            <input
              id="name"
              name="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1" htmlFor="subject">
              Email subject
            </label>
            <input
              id="subject"
              name="subject"
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm"
            />
          </div>
          {user?.type === "admin" && (
            <div className="mb-2">
              <label
                htmlFor="custom-url"
                className="flex items-center cursor-pointer"
              >
                <input
                  id="custom-url"
                  type="checkbox"
                  className="form-checkbox w-6 h-6"
                  readOnly
                  checked={useCustomLink}
                  onChange={() => setUseCustomLink((p) => !p)}
                />
                <span className="text-sm font-medium ml-2">
                  Use custom link
                </span>
              </label>
            </div>
          )}
          <div className="mb-2">
            <label className="block text-sm font-medium mb-1">Link URL</label>
            {useCustomLink ? (
              <input
                value={linkUrl}
                className="form-input w-full"
                onChange={(e) => setLinkUrl(e.target.value)}
              />
            ) : (
              <select
                id="country"
                value={linkUrl}
                onChange={(e) => setLinkUrl(e.target.value)}
                className="form-select w-full"
              >
                {/* <option selected value="" disabled>
            Select platform...
          </option> */}
                {generateInvitationLinks(user?.selectedCompany?.ratings, user)}
              </select>
            )}
          </div>
          <div className="text-sm text-gray-500m mb-6">
            In the review invitation email, the link will send your customers to
            this platform.
          </div>

          <label className="block text-sm font-medium mb-1" htmlFor="content">
            Email content
          </label>
          <div
            className="border border-gray-200 rounded relative"
            style={{
              minHeight: "400px",
            }}
          >
            <CKEditor
              editor={ClassicEditor}
              data={content}
              onChange={(event, editor) => {
                setContent(editor.getData());
              }}
            />
          </div>
        </div>
        {/* SHORTCODES */}
        <div className="text-black bg-gray-50 rounded p-6 h-max lg:w-max w-full lg:ml-10 lg:mt-0 mt-5">
          <div className="font-bold text-xl md:text-2xl mb-1">
            Email Parameters
          </div>
          <div className="mb-6">
            Customize your emails with the dynamic parameters listed below.
          </div>
          <div className="mb-4">
            <div className="font-semibold">Account</div>
            <div className="flex items-center justify-between">
              <div className="text-gray-800">{"{companyName}"}</div>
              {companyNameCopied ? (
                <div className="flex items-center ml-3 h-[34px]">
                  <img src={checkboxGreen} alt="checkbox" />
                  <div className="text-green-600 text-sm ml-1.5">Copied</div>
                </div>
              ) : (
                <button
                  onClick={() => {
                    setCompanyNameCopied(true);
                    navigator.clipboard.writeText("{companyName}");
                  }}
                  className="btn bg-blue-600 text-white transition-colors hover:bg-blue-600 ml-3 py-1.5 px-3.5"
                >
                  Copy
                </button>
              )}
            </div>
          </div>
          <div className="mb-4">
            <div className="font-semibold">First name</div>
            <div className="flex items-center justify-between">
              <div className="text-gray-800">{"{firstName}"}</div>
              {firstNameCopied ? (
                <div className="flex items-center ml-3 h-[34px]">
                  <img src={checkboxGreen} alt="checkbox" />
                  <div className="text-green-600 text-sm ml-1.5">Copied</div>
                </div>
              ) : (
                <button
                  onClick={() => {
                    setFirstNameCopied(true);
                    navigator.clipboard.writeText("{firstName}");
                  }}
                  className="btn bg-blue-600 text-white transition-colors hover:bg-blue-600 ml-3 py-1.5 px-3.5"
                >
                  Copy
                </button>
              )}
            </div>
          </div>
          <div className="mb-4">
            <div className="font-semibold">Last name</div>
            <div className="flex items-center justify-between">
              <div className="text-gray-800">{"{lastName}"}</div>
              {lastNameCopied ? (
                <div className="flex items-center ml-3 h-[34px]">
                  <img src={checkboxGreen} alt="checkbox" />
                  <div className="text-green-600 text-sm ml-1.5">Copied</div>
                </div>
              ) : (
                <button
                  onClick={() => {
                    setLastNameCopied(true);
                    navigator.clipboard.writeText("{lastName}");
                  }}
                  className="btn bg-blue-600 text-white transition-colors hover:bg-blue-600 ml-3 py-1.5 px-3.5"
                >
                  Copy
                </button>
              )}
            </div>
          </div>
          <div>
            <div className="font-semibold">Review Link</div>
            <div className="flex items-center justify-between">
              <div className="text-gray-800">
                {"{review_link: Click here to submit your review}"}
              </div>
              {reviewCopied ? (
                <div className="flex items-center ml-3 h-[34px]">
                  <img src={checkboxGreen} alt="checkbox" />
                  <div className="text-green-600 text-sm ml-1.5">Copied</div>
                </div>
              ) : (
                <button
                  onClick={() => {
                    setReviewCopied(true);
                    navigator.clipboard.writeText(
                      "{review_link: Click here to submit your review}"
                    );
                  }}
                  className="btn bg-blue-600 text-white transition-colors hover:bg-blue-600 ml-3 py-1.5 px-3.5"
                >
                  Copy
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTemplate;
