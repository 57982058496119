import { typeObject } from "helpers/getObjectByType";
import React from "react";

const UpcomingPlatform = ({ platformType }) => {
  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <div className="px-5 py-5">
        <header className="flex justify-between items-start mb-2"></header>
        <div className="flex items-center mb-4">
          <img
            src={typeObject[platformType]?.image}
            alt="Platform"
            className="max-h-10"
          />
          <h2 className="text-xl font-semibold text-gray-800 ml-3">
            {typeObject[platformType]?.title}
          </h2>
          <div className="font-bold ml-auto rounded-full text-xs text-center px-2.5 py-0.5 bg-red-100 text-red-600">
            Upcoming
          </div>
        </div>
        <div className="font-medium mb-5">Upcoming with next releases!</div>
        <div
          className={
            "bg-zinc-200 block text-center transition-all text-white w-full py-2 px-3 rounded"
          }
        >
          Connect
        </div>
      </div>
    </div>
  );
};

export default UpcomingPlatform;
