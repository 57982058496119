import * as actionTypes from "../actions/actionTypes";
import _ from "underscore";

const loadInitialState = () => {
  return {
    account: JSON.parse(localStorage.getItem("user")) || null,
    loginErr: null,
    registerErr: null,
    logs: [],
    invoices: [],
    companies: [],
    selectedCompany: null,
    teamMembers: [],
  };
};

const initialState = loadInitialState();

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      if (
        _.isEqual(state, {
          ...state,
          account: action.payload,
        })
      ) {
        return state;
      }

      return {
        ...state,
        account: action.payload,
      };
    case actionTypes.SET_REGISTER_ERR:
      return {
        ...state,
        registerErr: action.payload,
      };
    case actionTypes.SET_LOGIN_ERR:
      return {
        ...state,
        loginErr: action.payload,
      };
    case actionTypes.SET_LOGS:
      return {
        ...state,
        logs: action.payload,
      };
    case actionTypes.SET_INVOICES:
      return {
        ...state,
        invoices: action.payload,
      };
    case actionTypes.SET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case actionTypes.SET_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case actionTypes.SET_TEAM_MEMBERS:
      return {
        ...state,
        teamMembers: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
