import FreshaStars from "components/FreshaStars/FreshaStars";
import RatingStars from "components/RatingsStars/RatingsStars";
import RecoStars from "components/RecoStars/RecoStars";
import YelpStars from "components/YelpStars/YelpStars";
import React from "react";
import Stars from "react-stars-display";

const PlatformStars = ({ type, rating, googleSize = 20 }) => {
  if (type === "recose") {
    return <RecoStars rating={rating} />;
  } else if (type === "booking" || type === "trustbucket") {
    return (
      <div className="my-2">
        <RatingStars rating={rating} />
      </div>
    );
  } else if (type === "fresha" || type === "bokadirekt") {
    return <FreshaStars rating={rating} />;
  } else if (type === "google") {
    return (
      <Stars stars={rating} fill="#FFDC00" size={googleSize} spacing={0} />
    );
  } else if (type === "hitta") {
    return <Stars stars={rating} fill="#f1bf00" size={16} spacing={0} />;
  } else if (type === "yelp") {
    return <YelpStars rating={Math.round(rating)} />;
  } else {
    return (
      <div className="my-2">
        <RatingStars rating={rating} />
      </div>
    );
  }
};

export default PlatformStars;
