import * as actionTypes from "../actions/actionTypes";

const initialState = {
  ratings: [],
  total: 0,
  lastPage: 10,
  unrepliedTotal: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_RATINGS:
      return {
        ...state,
        ratings: action.payload,
      };
    case actionTypes.SET_RATINGS_TOTAL:
      return {
        ...state,
        total: action.payload,
      };
    case actionTypes.SET_RATINGS_LAST_PAGE:
      return {
        ...state,
        lastPage: action.payload,
      };
    case actionTypes.SET_UNREPLIED_TOTAL:
      return {
        ...state,
        unrepliedTotal: action.payload,
      };
    case actionTypes.SET_CHANGED_REPLY:
      let previousRatings = state.ratings;
      const ind = previousRatings.findIndex((el) => el._id === action.id);
      let newRatings = [...previousRatings];
      if (action.reply) {
        newRatings[ind] = { ...newRatings[ind], reply: { text: action.reply } };
      } else {
        newRatings[ind] = { ...newRatings[ind], reply: null };
      }
      return {
        ...state,
        ratings: newRatings,
      };
    default:
      return state;
  }
};

export default reducer;
