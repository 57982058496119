import React, { useState } from "react";

function NotificationsPanel() {
  const [notifications, setNotifications] = useState(true);

  return (
    <div className="grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-xl sm:text-2xl text-gray-800 font-bold mb-5">
          My Notifications
        </h2>

        {/* General */}
        <section style={{ minHeight: "50vh" }}>
          <h3 className="text-lg lg:text-xl leading-snug text-gray-800 font-bold mb-1">
            General
          </h3>
          <ul>
            <li className="flex justify-between items-center py-3 border-b border-gray-200">
              {/* Left */}
              <div>
                <div className="text-gray-800 font-semibold">New reviews</div>
                <div className="text-sm">
                  Notify me when I get a new review.
                </div>
              </div>
              {/* Right */}
              <div className="flex items-center ml-4">
                <div className="text-sm text-gray-400 italic mr-2">
                  {notifications ? "On" : "Off"}
                </div>
                <div className="form-switch">
                  <input
                    type="checkbox"
                    id="notifications"
                    className="sr-only"
                    checked={notifications}
                    onChange={() => setNotifications(!notifications)}
                  />
                  <label className="bg-gray-400" htmlFor="notifications">
                    <span
                      className="bg-white shadow-sm"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Enable smart sync</span>
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </section>
      </div>

      {/* Panel footer */}
      <footer>
        <div className="flex flex-col px-6 py-5 border-t border-gray-200">
          <div className="flex self-end">
            <button className="btn border-gray-200 hover:border-gray-300 text-gray-600">
              Cancel
            </button>
            <button className="btn bg-blue-500 hover:bg-blue-600 text-white ml-3">
              Save Changes
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default NotificationsPanel;
