export const predefinedStyles = {
  slider: [
    {
      type: "slider",
      widgetName: "Slider",
      widgetType: "slider-1",
      language: "en",
      ratingFilter: "all",
      footerText: "show",
      verifiedIcon: "show",
      platformLogos: "show",
      platformStars: "show",
      reviewSources: "all",
      colorSchema: "trustbucket-testimonial",
      navigationArrows: "show",
      navigationDots: "hide",
      textAlign: "left",
      nameColor: "#1E293B",
      nameSize: 16,
      reviewColor: "#1F2937",
      reviewSize: 15,
      backgroundColor: "#fffffe",
      borderColor: "#D6DAE4",
      borderWeight: 1,
      padding: 24,
      borderRadius: 8,
      dropShadow: "hide",
      backgroundOpacity: 1,
      mouseAnimation: "yes",
      outerBackgroundColor: "#fffffe",
      outerTextColor: "#000009",
      numberOfReviews: 20,
      tags: [],
    },
    {
      type: "slider",
      widgetName: "Carousel",
      widgetType: "carousel",
      language: "en",
      ratingFilter: "all",
      footerText: "hide",
      verifiedIcon: "show",
      platformLogos: "show",
      platformStars: "show",
      reviewSources: "all",
      colorSchema: "trustbucket-testimonial",
      navigationArrows: "show",
      navigationDots: "hide",
      textAlign: "left",
      nameColor: "#1E293B",
      nameSize: 16,
      reviewColor: "#1F2937",
      reviewSize: 15,
      backgroundColor: "#fffffe",
      borderColor: "#D6DAE4",
      borderWeight: 1,
      padding: 24,
      borderRadius: 8,
      dropShadow: "hide",
      backgroundOpacity: 1,
      mouseAnimation: "yes",
      outerBackgroundColor: "#fffffe",
      outerTextColor: "#000009",
      numberOfReviews: 20,
      tags: [],
    },
  ],
  grid: [
    {
      type: "grid",
      widgetName: "Grid",
      widgetType: "grid-1",
      language: "en",
      ratingFilter: "all",
      footerText: "show",
      verifiedIcon: "show",
      platformLogos: "show",
      platformStars: "show",
      reviewSources: "all",
      colorSchema: "trustbucket-testimonial",
      navigationArrows: "show",
      navigationDots: "hide",
      textAlign: "left",
      nameColor: "#1E293B",
      nameSize: 16,
      reviewColor: "#1F2937",
      reviewSize: 15,
      backgroundColor: "#fffffe",
      borderColor: "#D6DAE4",
      borderWeight: 1,
      padding: 24,
      borderRadius: 8,
      dropShadow: "hide",
      backgroundOpacity: 1,
      mouseAnimation: "yes",
      outerBackgroundColor: "#fffffe",
      outerTextColor: "#000009",
      numberOfReviews: 6,
      tags: [],
    },
  ],
  list: [
    {
      type: "list",
      widgetName: "List 1",
      widgetType: "list-1",
      language: "en",
      ratingFilter: "all",
      footerText: "show",
      verifiedIcon: "show",
      platformLogos: "show",
      platformStars: "show",
      reviewSources: "all",
      colorSchema: "light-background",
      navigationArrows: "show",
      navigationDots: "show",
      textAlign: "left",
      nameColor: "#000009",
      nameSize: 18,
      reviewColor: "#000009",
      reviewSize: 15,
      backgroundColor: "#efefef",
      borderColor: "#efefef",
      borderWeight: 0,
      padding: 16,
      borderRadius: 4,
      dropShadow: "hide",
      backgroundOpacity: 1,
      mouseAnimation: "yes",
      outerBackgroundColor: "#fdffff",
      outerTextColor: "#000009",
      numberOfReviews: 20,
      tags: [],
    },
    {
      type: "list",
      widgetName: "List 1 Centered",
      widgetType: "list-1-centered",
      language: "en",
      ratingFilter: "all",
      footerText: "hide",
      verifiedIcon: "show",
      platformLogos: "show",
      platformStars: "show",
      reviewSources: "all",
      colorSchema: "light-background",
      navigationArrows: "show",
      navigationDots: "hide",
      textAlign: "center",
      nameColor: "#000009",
      nameSize: 18,
      reviewColor: "#000009",
      reviewSize: 15,
      backgroundColor: "#efefef",
      borderColor: "#efefef",
      borderWeight: 0,
      padding: 16,
      borderRadius: 4,
      dropShadow: "hide",
      backgroundOpacity: 1,
      mouseAnimation: "yes",
      outerBackgroundColor: "#fdffff",
      outerTextColor: "#000009",
      numberOfReviews: 20,
      tags: [],
    },
  ],
  badge: [
    {
      type: "badge",
      widgetName: "Mini",
      widgetType: "badge-1-html",
      language: "en",
      ratingFilter: "all",
      footerText: "hide",
      verifiedIcon: "show",
      platformLogos: "show",
      platformStars: "show",
      reviewSources: "all",
      colorSchema: "trustbucket",
      navigationArrows: "show",
      navigationDots: "hide",
      textAlign: "center",
      nameColor: "#1E293B",
      nameSize: 24,
      reviewColor: "#1F2937",
      reviewSize: 15,
      backgroundColor: "#fffffe",
      borderColor: "#D6DAE4",
      borderWeight: 1,
      padding: 24,
      borderRadius: 8,
      dropShadow: "hide",
      backgroundOpacity: 1,
      mouseAnimation: "yes",
      outerBackgroundColor: "#fffffe",
      outerTextColor: "#000009",
      tags: [],
    },
  ],
  button: [
    {
      type: "button",
      widgetName: "Horizontal",
      widgetType: "horizontal",
      language: "en",
      ratingFilter: "all",
      footerText: "hide",
      verifiedIcon: "show",
      platformLogos: "show",
      platformStars: "show",
      reviewSources: "all",
      colorSchema: "minimal",
      navigationArrows: "show",
      navigationDots: "hide",
      textAlign: "center",
      nameColor: "#1F2937",
      nameSize: 20,
      reviewColor: "#1F2937",
      reviewSize: 15,
      backgroundColor: "#fffffa",
      borderColor: "#fffffa",
      borderWeight: 0,
      padding: 24,
      borderRadius: 4,
      dropShadow: "hide",
      backgroundOpacity: 1,
      mouseAnimation: "yes",
      outerBackgroundColor: "#fdffff",
      outerTextColor: "#000009",
      tags: [],
    },
    {
      type: "button",
      widgetName: "Micro",
      widgetType: "micro-trustscore",
      language: "en",
      ratingFilter: "all",
      footerText: "hide",
      verifiedIcon: "show",
      platformLogos: "show",
      platformStars: "show",
      reviewSources: "all",
      colorSchema: "minimal",
      navigationArrows: "show",
      navigationDots: "hide",
      textAlign: "center",
      nameColor: "#000009",
      nameSize: 18,
      reviewColor: "#000009",
      reviewSize: 15,
      backgroundColor: "#fffffa",
      borderColor: "#fffffa",
      borderWeight: 0,
      padding: 24,
      borderRadius: 4,
      dropShadow: "hide",
      backgroundOpacity: 1,
      mouseAnimation: "yes",
      outerBackgroundColor: "#fdffff",
      outerTextColor: "#000009",
      tags: [],
    },
    {
      type: "button",
      widgetName: "Micro review count",
      widgetType: "button-3",
      language: "en",
      ratingFilter: "all",
      footerText: "hide",
      verifiedIcon: "show",
      platformLogos: "show",
      platformStars: "show",
      reviewSources: "all",
      colorSchema: "minimal",
      navigationArrows: "show",
      navigationDots: "hide",
      textAlign: "center",
      nameColor: "#000009",
      nameSize: 15,
      reviewColor: "#000009",
      reviewSize: 15,
      backgroundColor: "#fffffd",
      borderColor: "#fffffd",
      borderWeight: 0,
      padding: 24,
      borderRadius: 4,
      dropShadow: "hide",
      backgroundOpacity: 1,
      mouseAnimation: "yes",
      outerBackgroundColor: "#fdffff",
      outerTextColor: "#000009",
      tags: [],
    },
  ],
  collectReviewsButton: [
    {
      type: "collectReviewsButton",
      widgetName: "Mini review collector",
      widgetType: "button-2",
      language: "en",
      ratingFilter: "all",
      footerText: "hide",
      verifiedIcon: "show",
      platformLogos: "show",
      platformStars: "show",
      reviewSources: "all",
      colorSchema: "light-clean",
      navigationArrows: "show",
      navigationDots: "hide",
      textAlign: "center",
      nameColor: "#000009",
      nameSize: 15,
      reviewColor: "#000009",
      reviewSize: 15,
      backgroundColor: "#fafafa",
      borderColor: "#d6dae4",
      borderWeight: 1,
      padding: 14,
      borderRadius: 6,
      dropShadow: "hide",
      backgroundOpacity: 1,
      mouseAnimation: "yes",
      outerBackgroundColor: "#fdffff",
      outerTextColor: "#000009",
      tags: [],
    },
  ],
};
