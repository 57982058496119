import React from "react";

const Step1 = ({
  firstNames,
  setFirstNames,
  lastNames,
  setLastNames,
  emails,
  setEmails,
  failedList,
  successList,
  onSubmit,
}) => {
  return (
    <>
      <div className="text-sm font-medium text-black">
        Fill in the data of customers you’d like to invite. Paste the first
        name, last name and email in the columns to add recipients to your
        campaign.
      </div>
      <div className="text-sm mt-1 text-black">
        <span className="font-semibold">NOTE: </span>
        You can send 50 invitations per one campaign. If you want to invite more
        users you must create more campaigns.
      </div>
      <div className="grid grid-cols-12 gap-6 mt-7 mb-16">
        <div className="sm:col-span-6 xl:col-span-4 flex flex-col col-span-full">
          <label className="font-medium mb-2 text-sm block">
            First name <span className="text-red-700">*</span>
          </label>
          <textarea
            className="form-textarea w-full p-3 h-36"
            value={firstNames}
            placeholder={`ex. John\nex. John\nex. John\n`}
            onChange={(e) => setFirstNames(e.target.value)}
          />
        </div>
        <div className="sm:col-span-6 xl:col-span-4 flex flex-col col-span-full">
          <label className="font-medium mb-2 text-sm block">
            Last name <span className="text-red-700">*</span>
          </label>
          <textarea
            className="form-textarea w-full p-3 h-36"
            value={lastNames}
            placeholder={`ex. Doe\nex. Doe\nex. Doe\n`}
            onChange={(e) => setLastNames(e.target.value)}
          />
        </div>
        <div className="sm:col-span-6 xl:col-span-4 flex flex-col col-span-full">
          <label className="font-medium mb-2 text-sm block">
            Email <span className="text-red-700">*</span>
          </label>
          <textarea
            className="form-textarea w-full p-3 h-36"
            value={emails}
            placeholder={`ex. johndoe@gmail.com\nex. johndoe@gmail.com\nex. johndoe@gmail.com\n`}
            onChange={(e) => setEmails(e.target.value)}
          />
        </div>
      </div>
      <hr />
      <div className="mt-6">
        <div className="text-lg font-bold mb-8 text-black">
          Validation of reviewers
        </div>
        <div className="pb-10 ">
          <div className="text-sm font-bold text-black mb-3">
            Failed reviewers
          </div>
          <div className="overflow-x-scroll">
            <div
              style={{ minWidth: 570 }}
              className="flex items-center justify-between border-t border-gray-200 border-b bg-gray-50 px-4 py-3"
            >
              <div className="font-semibold text-xs text-gray-500 w-4/12">
                First name
              </div>
              <div className="font-semibold text-xs text-gray-500 w-4/12">
                Last name
              </div>
              <div className="font-semibold text-xs text-gray-500 w-4/12">
                Email
              </div>
            </div>

            {failedList.map((elem, index) => (
              <div
                key={index}
                style={{ minHeight: 47, minWidth: 570 }}
                className="flex items-center justify-between border-b border-gray-200 px-4 py-3.5"
              >
                <div className="text-black font-semibold text-xs w-4/12">
                  {elem.firstName}
                </div>
                <div className="text-black font-semibold text-xs w-4/12">
                  {elem.lastName}
                </div>
                <div className="text-black font-semibold text-xs w-4/12">
                  {elem.email}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="pb-10 mt-3 overflow-hidden">
          <div className="text-sm font-bold text-black mb-3">
            Approved reviewers
          </div>
          <div className="overflow-x-scroll">
            <div
              style={{ minWidth: 570 }}
              className="flex items-center justify-between border-t border-gray-200 bg-gray-50 px-4 py-3"
            >
              <div className="font-semibold text-xs text-gray-500 w-4/12">
                First name
              </div>
              <div className="font-semibold text-xs text-gray-500 w-4/12">
                Last name
              </div>
              <div className="font-semibold text-xs text-gray-500 w-4/12">
                Email
              </div>
            </div>

            {successList.map((elem, index) => (
              <div
                key={index}
                className="flex items-center justify-between border-b border-gray-200 px-4 py-3.5"
                style={{ minWidth: 570 }}
              >
                <div className="text-black font-semibold text-xs w-4/12">
                  {elem.firstName}
                </div>
                <div className="text-black font-semibold text-xs w-4/12">
                  {elem.lastName}
                </div>
                <div className="text-black font-semibold text-xs w-4/12">
                  {elem.email}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-6">
        <button
          className={
            "btn bg-blue-600 hover:bg-blue-700 transition-all text-white" +
            (failedList.length > 0 ? " opacity-70 pointer-events-none" : "")
          }
          disabled={failedList.length > 0}
          onClick={onSubmit}
        >
          Select email template
        </button>
      </div>
    </>
  );
};

export default Step1;
