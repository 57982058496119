import * as actionTypes from "../actions/actionTypes";
import _ from "underscore";

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_STATS:
      if (_.isEqual(state, action.payload)) {
        return state;
      }
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
