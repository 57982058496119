import ModalBasic from "components/ModalBasic";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveGoogleRating } from "store/actions/google";
import { useHistory } from "react-router-dom";

const GooglePlatformModal = ({
  platformModal,
  setPlatformModalOpen,
  code,
}) => {
  const dispatch = useDispatch();
  const [selectedLocation, setSelectedLocation] = useState();

  // const platformData = useSelector((state) => state.google.googleProfile);
  const googleLocations = useSelector((state) => state.google.googleLocations);
  const history = useHistory();

  const handleConnect = () => {
    dispatch(
      saveGoogleRating({ ...selectedLocation, code }, submitCallback)
    );
  };

  const submitCallback = () => {
    setPlatformModalOpen(false);
    history.push("/connect-platforms");
  };

  return (
    <ModalBasic
      id="google-platform-modal"
      modalOpen={platformModal}
      setModalOpen={setPlatformModalOpen}
      title="Choose Google location"
    >
      <div>
        <div className="p-4">
          {googleLocations?.map((item, index) => (
            <div
              key={index}
              className={
                "rounded w-full p-3 flex items-center cursor-pointer overflow-hidden mb-2 " +
                (item === selectedLocation
                  ? "border-blue-500 border-2"
                  : "border-2 border-gray-300")
              }
              onClick={() => setSelectedLocation(item)}
            >
              <span
                className={
                  "block w-4 h-4 rounded-full mr-5 " +
                  (item === selectedLocation
                    ? "border-4 border-blue-500"
                    : "border-2 border-gray-500")
                }
                style={{ minWidth: 16 }}
              ></span>
              <div>
                <div className="flex items-center">
                  <div className="font-semibold text-sm mr-4 text-black">
                    {item.name}
                  </div>
                  <div className="italic text-gray-500 text-xs font-medium">
                    {item?.website}
                  </div>
                </div>

                {/* <div className="text-xs text-gray-600 truncate w-full pr-3">
                  {item?.address?.name}
                </div> */}
              </div>
            </div>
          ))}
        </div>

        <div className="px-4 py-2 text-sm">
          By clicking “Connect”, I take full responsibility for how the data
          that is loaded are used.
        </div>
        {/* Modal footer */}
        <div className="px-5 py-4 border-t border-gray-200">
          <div className="flex flex-wrap justify-end space-x-2">
            <div
              className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setPlatformModalOpen(false);
              }}
            >
              Cancel
            </div>
            <button
              type="submit"
              className="btn-sm bg-blue-500 hover:bg-blue-600 text-white"
              onClick={handleConnect}
            >
              Connect
            </button>
          </div>
        </div>
      </div>
    </ModalBasic>
  );
};

export default GooglePlatformModal;
