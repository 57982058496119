import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteEmailTemplate } from "store/actions/emailTemplates";
import lockMiniSVG from "icons/lock-mini.svg";
import { setUpgradePopup } from "store/actions/upgradePopup";
import { DeletePopup } from "components/DeletePopup/DeletePopup";
import { setEmailPreviewContent } from "store/actions/campaign";
import { useHistory } from "react-router-dom";

const SingleTemplate = ({ data }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.auth.account);

  const [deletePopup, setDeletePopup] = useState(false);

  const handleDelete = () => {
    dispatch(deleteEmailTemplate(data?.id));
  };

  return (
    <>
      <DeletePopup
        deletePopup={deletePopup}
        setDeletePopup={setDeletePopup}
        onSubmit={handleDelete}
        title={`Delete email template`}
        description={`<div><p>Are you sure you want to delete selected email template?</p></div>`}
      />
      <div
        className={
          "rounded-sm border border-gray-200 px-5 mb-4 transition-all overflow-hidden" +
          (!data?.default &&
          user?.selectedCompany?.subscription?.plan === "free"
            ? " bg-gray-100"
            : "")
        }
        // style={{ maxHeight: updateActive ? 3000 : 72 }}
      >
        <div
          className={
            "flex items-center justify-between py-4"
            // + (updateActive ? " hidden" : "")
          }
        >
          <div className="text-sm font-medium text-black">{data?.name}</div>
          <div className="flex items-center">
            <div className="flex items-center">
              <button
                className="btn text-gray-600 border border-gray-200"
                onClick={() => dispatch(setEmailPreviewContent(data.content))}
              >
                <svg
                  className="h-5 w-5 sm:mr-2"
                  width="114"
                  height="70"
                  viewBox="0 0 114 70"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_246_2)">
                    <path
                      d="M110.907 34.795C110.907 34.795 86.724 67.089 56.7 67.089C26.676 67.089 2.5 34.795 2.5 34.795C2.5 34.795 26.683 2.5 56.7 2.5C86.717 2.5 110.907 34.795 110.907 34.795Z"
                      stroke="#64748B"
                      strokeWidth="8"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M56.703 50.941C65.914 50.941 73.381 43.7117 73.381 34.794C73.381 25.8762 65.914 18.647 56.703 18.647C47.492 18.647 40.025 25.8762 40.025 34.794C40.025 43.7117 47.492 50.941 56.703 50.941Z"
                      stroke="#64748B"
                      strokeWidth="8"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_246_2">
                      <rect width="113.407" height="69.589" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <span className="sm:block hidden">Preview</span>
              </button>
            </div>
            {!data?.default &&
              (user?.selectedCompany?.subscription?.plan === "free" &&
              user?.type !== "appsumo" ? (
                <div
                  onClick={() => dispatch(setUpgradePopup(true))}
                  className="btn bg-blue-500 hover:bg-blue-600 cursor-pointer transition-all flex items-center w-max sm:mt-0 mt-2 text-white"
                >
                  <img src={lockMiniSVG} alt="lock" className="mr-2" />
                  Upgrade to use
                </div>
              ) : (
                <div className="flex items-center ml-3">
                  <button
                    className="btn text-gray-600 border border-gray-200"
                    onClick={() =>
                      history.push(`/template-editor/new-template/${data.id}`)
                    }
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      className="sm:mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.7 0.3C11.3 -0.1 10.7 -0.1 10.3 0.3L0.3 10.3C0.1 10.5 0 10.7 0 11V15C0 15.6 0.4 16 1 16H5C5.3 16 5.5 15.9 5.7 15.7L15.7 5.7C16.1 5.3 16.1 4.7 15.7 4.3L11.7 0.3ZM4.6 14H2V11.4L8 5.4L10.6 8L4.6 14ZM9.4 4L12 6.6L13.6 5L11 2.4L9.4 4Z"
                        fill="#64748B"
                      />
                    </svg>
                    <span className="sm:block hidden">Edit Content</span>
                  </button>
                  <button
                    className="btn text-red-500 border border-gray-200 ml-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeletePopup(true);
                    }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      className="sm:mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect x="5" y="7" width="2" height="6" fill="#F43F5E" />
                      <rect x="9" y="7" width="2" height="6" fill="#F43F5E" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 1C12 0.4 11.6 0 11 0H5C4.4 0 4 0.4 4 1V3H0V5H1V15C1 15.6 1.4 16 2 16H14C14.6 16 15 15.6 15 15V5H16V3H12V1ZM6 2H10V3H6V2ZM13 14V5H3V14H13Z"
                        fill="#F43F5E"
                      />
                    </svg>
                    <span className="sm:block hidden">Delete</span>
                  </button>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleTemplate;
