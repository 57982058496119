import React, { useEffect } from "react";
import logo from "icons/logo-dark.svg";
import googleLoginSVG from "icons/google-login.svg";
import { Link, useHistory } from "react-router-dom";
// import GoogleLogin from "react-google-login";
import { useDispatch } from "react-redux";
import { googleLogin, login } from "store/actions/auth";
import clsx from "clsx";
import { useFormik } from "formik";
import * as Yup from "yup";

const initialValues = {
  companyEmail: "",
  password: "",
};

const registrationSchema = Yup.object().shape({
  companyEmail: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const apiBaseURL = process.env.REACT_APP_API_URL;

  const handleGoogleLogin = () => {
    window.open(`${apiBaseURL}/admins/auth/google-login`, "_self");
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const googleId = params.get("id");

    if (googleId) {
      dispatch(googleLogin(history, googleId));
    }
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      dispatch(
        login(
          { email: values.companyEmail, password: values.password },
          history
        )
      );
    },
  });

  return (
    <div className="bg-white min-h-screen">
      <div className="px-5 pt-8 pb-6 mb-10 md:p-10 md:mb-0 flex items-center justify-between">
        <a href="https://trustbucket.io/">
          <img
            className="w-auto h-5 object-contain"
            src={logo}
            alt="Workflow"
          />
        </a>
        <div className="text-gray-600 text-sm">
          <span className="md:inline hidden">Need a Trustbucket account? </span>
          <Link to="/auth/register" className="text-blue-600 underline">
            Sign up
          </Link>
        </div>
      </div>
      <div className="h-full flex flex-col max-w-sm mx-auto justify-center px-5">
        <div className="font-medium text-2xl mb-10 md:text-center text-black">
          Log in
        </div>
        <button
          type="submit"
          onClick={handleGoogleLogin}
          className="flex items-center w-full mb-8 text-center transition-colors hover:bg-blue-700 rounded-md px-0 pr-6 py-0 bg-blue-600 text-white font-medium text-base shadow sm:pr-6"
        >
          <img src={googleLoginSVG} alt="Google" />
          <span className="w-full">Log in with Google</span>
        </button>
        {/* <GoogleLogin
          className="w-full justify-center"
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText="Log in with Google"
          onSuccess={handleGoogleLogin}
          onFailure={() => {}}
          cookiePolicy={"single_host_origin"}
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              className="flex items-center w-full mb-8 text-center transition-colors hover:bg-blue-700 rounded-md px-0 pr-6 py-0 bg-blue-600 text-white font-medium text-base shadow sm:pr-6"
            >
              <img src={googleLoginSVG} alt="Google" />
              <span className="w-full">Log in with Google</span>
            </button>
          )}
        /> */}
        <div className="flex items-center justify-between text-center mb-8 text-gray-400 text-sm">
          <div className="bg-gray-400" style={{ height: 1, width: 92 }}></div>
          <div className="w-max mx-2">Or, log in with email</div>
          <div
            className="bg-gray-400 w-1/2"
            style={{ height: 1, width: 92 }}
          ></div>
        </div>
        <div>
          <form noValidate onSubmit={formik.handleSubmit}>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email adress
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="eg. jane.jackson@nasa.com"
                  {...formik.getFieldProps("companyEmail")}
                  className={clsx(
                    "appearance-none block w-full px-4 py-3 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm",
                    {
                      "is-invalid":
                        formik.touched.companyEmail &&
                        formik.errors.companyEmail,
                    },
                    {
                      "is-valid":
                        formik.touched.companyEmail &&
                        !formik.errors.companyEmail,
                    }
                  )}
                />
                {formik.touched.companyEmail && formik.errors.companyEmail && (
                  <span className="text-red-600 text-xs">
                    {formik.errors.companyEmail}
                  </span>
                )}
              </div>
            </div>
            <div className="mt-8">
              <label className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                  {...formik.getFieldProps("password")}
                  className={clsx(
                    "appearance-none block w-full px-4 py-3 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm",
                    {
                      "is-invalid":
                        formik.touched.password && formik.errors.password,
                    },
                    {
                      "is-valid":
                        formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <span className="text-red-600 text-xs">
                    {formik.errors.password}
                  </span>
                )}
              </div>
            </div>
            <Link
              to="/auth/forgot-password"
              className="mt-4 text-blue-600 block text-sm underline mb-8"
            >
              Forgot password?
            </Link>
            <button
              type="submit"
              className="w-full flex justify-center py-3 px-4 border border-transparent rounded shadow-sm transition-colors font-medium text-white bg-blue-600 hover:bg-blue-700"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
