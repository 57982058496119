import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import {
  getInvitationSettings,
  setEmailPreviewContent,
} from "store/actions/campaign";
import "./EmailPreviewPopup.scss";

const EmailPreviewPopup = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.account);
  const emailContent = useSelector(
    (state) => state.campaign.emailPreviewContent
  );
  const invitationSettings = useSelector(
    (state) => state.campaign.invitationSettings
  );
  const [btnText, setBtnText] = useState("");

  useEffect(() => {
    const start = emailContent.indexOf("{review_link");
    const end = emailContent.indexOf("}", start);
    const btnTextStart = emailContent.indexOf(":", start);
    const chars = emailContent.split("");
    let newContent = "";
    let btnNewText = "";
    for (let i = 0; i < chars.length; i++) {
      if (i < start || i > end) {
        newContent += chars[i];
      } else {
        if (i > btnTextStart && i < end) {
          btnNewText += chars[i];
        }
      }
    }
    dispatch(setEmailPreviewContent(newContent));
    setBtnText(btnNewText);

    dispatch(getInvitationSettings());
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-60"
      onClick={() => dispatch(setEmailPreviewContent(null))}
    >
      <div onClick={(e) => e.stopPropagation()} className="relative">
        <button
          className="opacity-70 hover:opacity-80 absolute top-1.5 right-2.5"
          onClick={() => dispatch(setEmailPreviewContent(null))}
        >
          <div className="sr-only">Close</div>
          <svg className="w-4 h-4 fill-current">
            <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
          </svg>
        </button>
        <table
          role="presentation"
          cellpadding="0"
          cellspacing="0"
          style={{
            borderSpacing: 0,
            borderCollapse: "collapse",
            margin: "0 auto",
            padding: 0,
            width: "100%",
            minWidth: 320,
            height: "100%",
            maxWidth: 600,
          }}
          width="100%"
          height="100%"
        >
          <tbody>
            <tr>
              <td
                valign="top"
                style={{
                  borderCollapse: "collapse",
                  fontSize: 15,
                  color: "#222d38",
                  wordBreak: "break-word",
                }}
              >
                <div
                  id="m_-5072298247928332435hs_cos_wrapper_main"
                  style={{
                    height: "max-content",
                    background: "#fff",
                    borderRadius: 4,
                  }}
                  // style="color: inherit; font-size: inherit; line-height: inherit"
                >
                  <div
                    id="m_-5072298247928332435section-0"
                    class="m_-5072298247928332435hse-section"
                    style={{
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingTop: 10,
                    }}
                  >
                    <div
                      class="m_-5072298247928332435hse-column-container"
                      style={{
                        minWidth: 280,
                        maxWidth: 600,
                        width: "100%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        borderCollapse: "collapse",
                        borderSpacing: "0",
                        backgroundColor: "#fff",
                        padding: "20px 0",
                      }}
                      bgcolor="#ffffff"
                    >
                      <div
                        id="m_-5072298247928332435column-0-0"
                        class="m_-5072298247928332435hse-column m_-5072298247928332435hse-size-12"
                      >
                        <div
                          id="m_-5072298247928332435hs_cos_wrapper_module-0-0-0"
                          //     style="
                          //   color: inherit;
                          //   font-size: inherit;
                          //   line-height: inherit;
                          // "
                        >
                          {user?.selectedCompany?.subscription?.plan ===
                            "free" && (
                            <table
                              role="presentation"
                              width="100%"
                              cellpadding="0"
                              cellspacing="0"
                              //     style="
                              //   border-spacing: 0 !important;
                              //   border-collapse: collapse;
                              // "
                            >
                              <tbody>
                                <tr>
                                  <td
                                    align="left"
                                    valign="top"
                                    style={{
                                      borderCollapse: "collapse",
                                      fontWeight: 400,
                                      fontSize: 12,
                                      lineHeight: "15px",
                                      padding: "0 20px",
                                    }}
                                  >
                                    <a
                                      style={{
                                        padding: "10px 0 10px",
                                        borderTop: "1px solid #d6dae4",
                                        borderBottom: "1px solid #d6dae4",
                                        color: "#99adc1",
                                        textDecoration: "none",
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        height: 35,
                                      }}
                                      href="#!"
                                      data-saferedirecturl="#!"
                                      rel="noreferrer"
                                    >
                                      Powered by
                                      <img
                                        alt="Trustbucket"
                                        src="http://trustbucket.io/wp-content/uploads/2022/06/Group-58-2.png"
                                        style={{
                                          marginLeft: 8,
                                          width: "100px",
                                        }}
                                        align="middle"
                                        class="CToWUd"
                                      />
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="m_-5072298247928332435section-1"
                    class="m_-5072298247928332435hse-section"
                    style={{ padding: "0 10px" }}
                  >
                    <div
                      class="m_-5072298247928332435hse-column-container"
                      style={{
                        minWidth: 280,
                        maxWidth: 600,
                        width: "100%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        borderCollapse: "collapse",
                        borderSpacing: "0",
                        backgroundColor: "#fff",
                        paddingBottom: 15,
                      }}
                      bgcolor="#ffffff"
                    >
                      <div
                        id="m_-5072298247928332435column-1-0"
                        class="m_-5072298247928332435hse-column m_-5072298247928332435hse-size-12"
                      >
                        <table
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          //     style="
                          //   border-spacing: 0 !important;
                          //   border-collapse: collapse;
                          // "
                        >
                          <tbody>
                            {invitationSettings?.logo && (
                              <tr>
                                <td
                                  style={{
                                    padding: "0px 20px 30px",
                                  }}
                                >
                                  <img
                                    style={{
                                      width: 124,
                                      heigh: 32,
                                      objectFit: "contain",
                                    }}
                                    alt="Logo"
                                    src={invitationSettings?.logo}
                                  />
                                </td>
                              </tr>
                            )}
                            <tr>
                              <td
                                class="m_-5072298247928332435hs_padded"
                                style={{
                                  fontSize: 15,
                                  color: "#222d38",
                                  wordBreak: "break-word",
                                  padding: "0 20px 10px",
                                }}
                              >
                                <div
                                  id="m_-5072298247928332435hs_cos_wrapper_module-1-0-0"
                                  //     style="
                                  //   color: inherit;
                                  //   font-size: inherit;
                                  //   line-height: inherit;
                                  // "
                                >
                                  <div
                                    id="m_-5072298247928332435hs_cos_wrapper_module-1-0-0_"
                                    //     style="
                                    //   color: inherit;
                                    //   font-size: inherit;
                                    //   line-height: inherit;
                                    // "
                                  >
                                    <div
                                      style={{ lineHeight: "175%" }}
                                      // style="line-height: 175%; text-align: left"
                                      align="left"
                                      className="email-content"
                                    >
                                      {parse(emailContent)}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {btnText && (
                    <div
                      id="m_-5072298247928332435section-2"
                      class="m_-5072298247928332435hse-section"
                    >
                      <div
                        class="m_-5072298247928332435hse-column-container"
                        style={{
                          minWidth: 280,
                          maxWidth: 600,
                          width: "100%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          borderCollapse: "collapse",
                          borderSpacing: "0",
                          backgroundColor: "#fff",
                          paddingBottom: 15,
                        }}
                        bgcolor="#ffffff"
                      >
                        <div
                          id="m_-5072298247928332435column-2-0"
                          class="m_-5072298247928332435hse-column m_-5072298247928332435hse-size-12"
                        >
                          <table
                            role="presentation"
                            cellpadding="0"
                            cellspacing="0"
                            width="100%"
                            style={{
                              borderSpacing: 0,
                              borderCollapse: "collapse",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  class="m_-5072298247928332435hs_padded"
                                  style={{
                                    fontSize: 15,
                                    color: "#222d38",
                                    wordBreak: "breakWord",
                                    padding: "10px 30px",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  <div
                                    id="m_-5072298247928332435hs_cos_wrapper_module-2-0-0"
                                    // style="
                                    //   color: inherit;
                                    //   font-size: inherit;
                                    //   line-height: inherit;
                                    // "
                                  >
                                    <table
                                      width="100%"
                                      align="center"
                                      border="0"
                                      cellpadding="0"
                                      cellspacing="0"
                                      role="presentation"
                                      style={{
                                        borderSpacing: 0,
                                        borderCollapse: "separate",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            align="center"
                                            valign="middle"
                                            role="presentation"
                                            style={{
                                              borderCollapse: "collapse",
                                              fontSize: 15,
                                              color: "#222d38",
                                              wordBreak: "break-word",
                                              borderRadius: 3,
                                              backgroundColor:
                                                invitationSettings?.color ||
                                                "#2563eb",
                                            }}
                                          >
                                            <a
                                              href="#!"
                                              // style="
                                              //   color: #00a4bd;
                                              //   font-size: 16px;
                                              //   font-family: Tahoma, sans-serif;
                                              //   margin: 0;
                                              //   text-transform: none;
                                              //   text-decoration: none;
                                              //   padding: 16px;
                                              //   display: block;
                                              // "
                                              style={{
                                                fontSize: 16,
                                                padding: 16,
                                                textTransform: "none",
                                                textDecoration: "none",
                                                margin: 0,
                                                display: "block",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: "#fff",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {btnText}
                                              </span>
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      paddingBottom: 20,
                      paddingTop: 10,
                      margin: "0 40px",
                      borderTop: "1px solid #99adc1",
                    }}
                    //     style="
                    //   text-align: left;
                    //   padding-bottom: 20px;
                    //   padding-top: 10px;
                    //   margin: 0 40px;
                    //   border-top: 1px solid #99adc1;
                    // "
                  >
                    <p
                      style={{
                        fontSize: 13,
                        lineHeight: "32px",
                        color: "#99adc1",
                      }}
                    >
                      Note: If you want to stop receiving review invitation
                      emails, please click
                      <a
                        style={{
                          color: "#99adc1",
                          marginLeft: 4,
                          textDecoration: "underline",
                        }}
                        href="#!"
                      >
                        unsubscribe.
                      </a>
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmailPreviewPopup;
