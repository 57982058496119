import React from "react";

const LEFT_DOTS = "LEFT_DOTS";
const RIGHT_DOTS = "RIGHT_DOTS";
const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const pageNeighbours = 1;

function CustomPagination({ lastPage, page, setPage, total, pageSize }) {
  const generatePagination = () => {
    const totalPages = lastPage;
    const currentPage = page;

    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_DOTS;
      const rightSpillPage = RIGHT_DOTS;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  return (
    <div>
      <nav
        className="flex justify-between"
        role="navigation"
        aria-label="Navigation"
      >
        <div className="flex-1 mr-2">
          <a
            // className="btn bg-white border-gray-200 text-gray-300 cursor-not-allowed"
            className={
              "btn bg-white border-gray-200 hover:border-gray-300 " +
              (page === 1
                ? "text-gray-300 cursor-not-allowed"
                : "text-blue-500")
            }
            href="#0"
            disabled={page === 1}
            onClick={() => page !== 1 && setPage((p) => p - 1)}
          >
            &lt;-<span className="hidden sm:inline">&nbsp;Previous</span>
          </a>
        </div>
        <div className="grow text-center">
          <ul className="inline-flex text-sm font-medium -space-x-px">
            {lastPage > 1 &&
              generatePagination().map((elem, index) => {
                if (elem === LEFT_DOTS) {
                  return (
                    <li key={index}>
                      <span className="inline-flex items-center justify-center leading-5 px-2 py-2 text-gray-400">
                        …
                      </span>
                    </li>
                  );
                } else if (elem === RIGHT_DOTS) {
                  return (
                    <li key={index}>
                      <span className="inline-flex items-center justify-center leading-5 px-2 py-2 text-gray-400">
                        …
                      </span>
                    </li>
                  );
                } else {
                  return (
                    <li key={index} onClick={() => setPage(elem)}>
                      <a
                        className={
                          "inline-flex items-center justify-center leading-5 px-2 py-2  " +
                          (page === elem
                            ? "bg-white border rounded-full border-gray-200 text-blue-500 shadow-sm"
                            : "text-gray-600 hover:text-blue-500 border border-transparent")
                        }
                        href="#0"
                      >
                        <span className="w-5">{elem}</span>
                      </a>
                    </li>
                  );
                }
              })}
          </ul>
        </div>
        {/* {page < lastPage && ( */}
        <div className="flex-1 text-right ml-2">
          <a
            className={
              "btn bg-white border-gray-200 hover:border-gray-300 " +
              (page === lastPage
                ? "text-gray-300 cursor-not-allowed"
                : "text-blue-500")
            }
            href="#0"
            disabled={page === lastPage}
            onClick={() => page !== lastPage && setPage((p) => p + 1)}
          >
            <span className="hidden sm:inline">Next&nbsp;</span>-&gt;
          </a>
        </div>
        {/* )} */}
      </nav>
    </div>
  );
}

export default CustomPagination;
