import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logoWhite from "icons/logo-white.svg";
import miniLogo from "icons/mini-logo.svg";
import SidebarLinkGroup from "./SidebarLinkGroup";
import ChangeAccountModal from "components/ChangeAccountModal/ChangeAccountModal";
import { useDispatch, useSelector } from "react-redux";
import AddBusinessModal from "components/AddBusinessModal/AddBusinessModal";
import { changeCompany } from "store/actions/auth";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.account);
  const selectCompany = useSelector((state) => state.auth.selectedCompany);

  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage?.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );
  const [changeAccOpen, setChangeAccOpen] = useState(false);
  const [businessModalOpen, setBusinessModalOpen] = useState(false);
  const selectedBusinessId = localStorage.getItem("selectedBusinessId");

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document?.addEventListener("click", clickHandler);
    return () => document?.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document?.addEventListener("keydown", keyHandler);
    return () => document?.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage?.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document?.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document?.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  useEffect(() => {
    dispatch(
      changeCompany(selectedBusinessId))
  },[])

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <>
      <div>
        {/* Sidebar backdrop (mobile only) */}
        <div
          className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
            sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
          aria-hidden="true"
        ></div>

        {/* Sidebar */}
        <div
          id="sidebar"
          ref={sidebar}
          className={`flex flex-col fixed z-40 left-0 top-0 lg:sticky lg:left-auto lg:top-0 lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-gray-800 p-4 transition-all duration-200 ease-in-out ${
            sidebarOpen ? "translate-x-0" : "-translate-x-64"
          }`}
        >
          {/* Sidebar header */}
          <div className="flex sm:mb-10 mb-8 pr-3 sm:px-2 mt-3">
            {/* Close button */}
            <button
              ref={trigger}
              className="lg:hidden text-gray-500 hover:text-gray-400"
              onClick={() => setSidebarOpen(!sidebarOpen)}
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
            >
              <span className="sr-only">Close sidebar</span>
              {/* <img src={logoWhite} alt="Logo" /> */}
            </button>
            {/* Logo */}
            <NavLink exact to="/" className="block">
              <img
                src={miniLogo}
                alt="Logo"
                className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden"
              />
              <img
                src={logoWhite}
                alt="Logo"
                className="lg:hidden lg:sidebar-expanded:block 2xl:block pl-2"
                style={{ width: 180 }}
              />
            </NavLink>
          </div>

          {/* Links */}
          <div className="h-full flex flex-col">
            {/* Pages group */}
            <div>
              {/* <h3 className="text-xs uppercase text-gray-500 font-semibold pl-3">
                <span
                  className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                  aria-hidden="true"
                >
                  •••
                </span>
                <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                  Pages
                </span>
              </h3> */}
              <ul className="mt-3">
                {/* Overview */}
                <li
                  className={`px-3 py-2 rounded-sm mb-2 last:mb-0 ${
                    pathname === "/dashboard" && "bg-gray-900"
                  }`}
                >
                  <NavLink
                    exact
                    to="/dashboard"
                    className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                      pathname === "/dashboard" && "hover:text-gray-200"
                    }`}
                    onClick={closeSidebar}
                  >
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
                        />
                      </svg>
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Overview
                      </span>
                    </div>
                  </NavLink>
                </li>
                <li
                  className={`px-3 py-2 rounded-sm mb-2 last:mb-0 ${
                    pathname.includes("/connect-platforms") && "bg-gray-900"
                  }`}
                >
                  <NavLink
                    exact
                    to="/connect-platforms"
                    className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                      pathname.includes("/connect-platforms") &&
                      "hover:text-gray-200"
                    }`}
                    onClick={closeSidebar}
                  >
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
                        />
                      </svg>
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Connect platforms
                      </span>
                    </div>
                  </NavLink>
                </li>
                <li
                  className={`px-3 py-2 rounded-sm mb-2 last:mb-0 ${
                    pathname === "/manage-reviews" && "bg-gray-900"
                  }`}
                >
                  <NavLink
                    exact
                    to="/manage-reviews"
                    className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                      pathname === "/manage-reviews" && "hover:text-gray-200"
                    }`}
                    onClick={closeSidebar}
                  >
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                        />
                      </svg>
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Manage reviews
                      </span>
                    </div>
                  </NavLink>
                </li>
                <SidebarLinkGroup
                  activecondition={pathname.includes("collect-reviews")}
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                            pathname.includes("collect-reviews") &&
                            "hover:text-gray-200"
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            sidebarExpanded
                              ? handleClick()
                              : setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <svg
                                width="24"
                                height="25"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 19.043V11.043M12 19.043L21 21.043L12 3.04297L3 21.043L12 19.043Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>

                              <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Collect Reviews
                              </span>
                            </div>
                            {/* Icon */}
                            <div className="flex shrink-0 ml-2">
                              <svg
                                className={`w-3 h-3 shrink-0 ml-1 fill-current text-gray-400 ${
                                  open && "transform rotate-180"
                                }`}
                                viewBox="0 0 12 12"
                              >
                                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                              </svg>
                            </div>
                          </div>
                        </a>
                        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            <li className="mb-3 mt-4">
                              <NavLink
                                exact
                                to="/collect-reviews/overview"
                                className={`block text-gray-400 hover:text-gray-200 transition duration-150 truncate" +
                                  ${
                                    pathname === "/collect-reviews/overview" &&
                                    " text-blue-600"
                                  }`}
                                activeClassName="text-blue-500"
                                onClick={closeSidebar}
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Overview
                                </span>
                              </NavLink>
                            </li>
                            <li className="mb-3">
                              <NavLink
                                exact
                                to="/collect-reviews/create-campaign"
                                className={`block text-gray-400 hover:text-gray-200 transition duration-150 truncate" +
                                  ${
                                    pathname ===
                                      "/collect-reviews/create-campaign" &&
                                    " text-blue-600"
                                  }`}
                                activeClassName="text-blue-500"
                                onClick={closeSidebar}
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Create campaign
                                </span>
                              </NavLink>
                            </li>
                            <li className="mb-3">
                              <NavLink
                                exact
                                to="/collect-reviews/automatic-collection"
                                className={`block text-gray-400 hover:text-gray-200 transition duration-150 truncate" +
                                ${
                                  pathname.includes(
                                    "/collect-reviews/automatic-collection"
                                  ) && " text-blue-600"
                                }`}
                                activeClassName="text-blue-500"
                                onClick={closeSidebar}
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Automatic collection
                                </span>
                              </NavLink>
                            </li>

                            <li className="mb-3">
                              <NavLink
                                exact
                                to="/collect-reviews/invitation-settings"
                                className={`block text-gray-400 hover:text-gray-200 transition duration-150 truncate" +
                                  ${
                                    pathname ===
                                      "/collect-reviews/invitation-settings" &&
                                    " text-blue-600"
                                  }`}
                                activeClassName="text-blue-500"
                                onClick={closeSidebar}
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Invitation settings
                                </span>
                              </NavLink>
                            </li>
                            <li className="mb-2">
                              <NavLink
                                exact
                                to="/collect-reviews/sent-invitations"
                                className={`block text-gray-400 hover:text-gray-200 transition duration-150 truncate" +
                                ${
                                  pathname.includes(
                                    "/collect-reviews/sent-invitations"
                                  ) && " text-blue-600"
                                }`}
                                activeClassName="text-blue-500"
                                onClick={closeSidebar}
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Sent invitations
                                </span>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
                <li
                  className={`px-3 py-2 rounded-sm mb-2 mt-2 last:mb-0 ${
                    pathname.includes("/template-editor") && "bg-gray-900"
                  }`}
                >
                  <NavLink
                    exact
                    to="/template-editor"
                    className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                      pathname === "/template-editor" && "hover:text-gray-200"
                    }`}
                    onClick={closeSidebar}
                  >
                    <div className="flex items-center">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ minWidth: 24 }}
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.279667 13L0.051 13.686C0.017 13.787 0 13.893 0 14V22C0 22.552 0.448 23 1 23H23C23.552 23 24 22.552 24 22V14C24 13.893 23.983 13.787 23.949 13.686L23.7203 13H21.612H16V17H8V13H2.387H0.279667Z"
                          fill="#94A3B8"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M21 4H3L0 13H8V17H16V13H24L21 4Z"
                          fill="#475569"
                        />
                        <path
                          d="M13 1V8H16L12 13L8 8H11V1H13Z"
                          fill="#94A3B8"
                        />
                      </svg>
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Template Editor
                      </span>
                    </div>
                  </NavLink>
                </li>

                <li
                  className={`px-3 py-2 rounded-sm mb-2 mt-2 last:mb-0 ${
                    pathname === "/widget-list" && "bg-gray-900"
                  }`}
                >
                  <NavLink
                    exact
                    to="/widget-list"
                    className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                      pathname === "/widget-list" && "hover:text-gray-200"
                    }`}
                    onClick={closeSidebar}
                  >
                    <div className="flex items-center">
                      <svg
                        className="shrink-0 h-6 w-6"
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 2.04297C1 1.77775 1.10536 1.5234 1.29289 1.33586C1.48043 1.14833 1.73478 1.04297 2 1.04297H16C16.2652 1.04297 16.5196 1.14833 16.7071 1.33586C16.8946 1.5234 17 1.77775 17 2.04297V4.04297C17 4.30819 16.8946 4.56254 16.7071 4.75008C16.5196 4.93761 16.2652 5.04297 16 5.04297H2C1.73478 5.04297 1.48043 4.93761 1.29289 4.75008C1.10536 4.56254 1 4.30819 1 4.04297V2.04297ZM1 10.043C1 9.77775 1.10536 9.5234 1.29289 9.33586C1.48043 9.14833 1.73478 9.04297 2 9.04297H8C8.26522 9.04297 8.51957 9.14833 8.70711 9.33586C8.89464 9.5234 9 9.77775 9 10.043V16.043C9 16.3082 8.89464 16.5625 8.70711 16.7501C8.51957 16.9376 8.26522 17.043 8 17.043H2C1.73478 17.043 1.48043 16.9376 1.29289 16.7501C1.10536 16.5625 1 16.3082 1 16.043V10.043ZM13 10.043C13 9.77775 13.1054 9.5234 13.2929 9.33586C13.4804 9.14833 13.7348 9.04297 14 9.04297H16C16.2652 9.04297 16.5196 9.14833 16.7071 9.33586C16.8946 9.5234 17 9.77775 17 10.043V16.043C17 16.3082 16.8946 16.5625 16.7071 16.7501C16.5196 16.9376 16.2652 17.043 16 17.043H14C13.7348 17.043 13.4804 16.9376 13.2929 16.7501C13.1054 16.5625 13 16.3082 13 16.043V10.043Z"
                          stroke="#e2e8f0"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Widget configurator
                      </span>
                    </div>
                  </NavLink>
                </li>
                <li
                  className={`px-3 py-2 rounded-sm mb-2 last:mb-0 ${
                    pathname.includes("/settings") && "bg-gray-900"
                  }`}
                >
                  <NavLink
                    exact
                    to="/settings/account"
                    className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                      pathname.includes("/settings") && "hover:text-gray-200"
                    }`}
                    onClick={closeSidebar}
                  >
                    <div className="flex items-center">
                      <svg
                        className="shrink-0 h-6 w-6"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.325 2.35997C8.751 0.603969 11.249 0.603969 11.675 2.35997C11.7389 2.62377 11.8642 2.86875 12.0407 3.07497C12.2172 3.28119 12.4399 3.44282 12.6907 3.54671C12.9414 3.65061 13.2132 3.69382 13.4838 3.67284C13.7544 3.65186 14.0162 3.56727 14.248 3.42597C15.791 2.48597 17.558 4.25197 16.618 5.79597C16.4769 6.02762 16.3924 6.28931 16.3715 6.55974C16.3506 6.83018 16.3938 7.10174 16.4975 7.35235C16.6013 7.60296 16.7627 7.82555 16.9687 8.00202C17.1747 8.17849 17.4194 8.30387 17.683 8.36797C19.439 8.79397 19.439 11.292 17.683 11.718C17.4192 11.7819 17.1742 11.9072 16.968 12.0837C16.7618 12.2602 16.6001 12.4829 16.4963 12.7336C16.3924 12.9844 16.3491 13.2561 16.3701 13.5268C16.3911 13.7974 16.4757 14.0592 16.617 14.291C17.557 15.834 15.791 17.601 14.247 16.661C14.0153 16.5199 13.7537 16.4354 13.4832 16.4145C13.2128 16.3936 12.9412 16.4367 12.6906 16.5405C12.44 16.6443 12.2174 16.8057 12.0409 17.0117C11.8645 17.2177 11.7391 17.4624 11.675 17.726C11.249 19.482 8.751 19.482 8.325 17.726C8.26108 17.4622 8.13578 17.2172 7.95929 17.011C7.7828 16.8047 7.56011 16.6431 7.30935 16.5392C7.05859 16.4353 6.78683 16.3921 6.51621 16.4131C6.24559 16.4341 5.98375 16.5187 5.752 16.66C4.209 17.6 2.442 15.834 3.382 14.29C3.5231 14.0583 3.60755 13.7966 3.62848 13.5262C3.64942 13.2558 3.60624 12.9842 3.50247 12.7336C3.3987 12.483 3.23726 12.2604 3.03127 12.0839C2.82529 11.9074 2.58056 11.7821 2.317 11.718C0.561 11.292 0.561 8.79397 2.317 8.36797C2.5808 8.30405 2.82578 8.17875 3.032 8.00226C3.23822 7.82577 3.39985 7.60308 3.50375 7.35232C3.60764 7.10155 3.65085 6.8298 3.62987 6.55918C3.60889 6.28856 3.5243 6.02672 3.383 5.79497C2.443 4.25197 4.209 2.48497 5.753 3.42497C6.749 4.03297 8.049 3.49497 8.325 2.35997Z"
                          stroke="#e2e8f0"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13 10.043C13 10.8386 12.6839 11.6017 12.1213 12.1643C11.5587 12.7269 10.7956 13.043 10 13.043C9.20435 13.043 8.44129 12.7269 7.87868 12.1643C7.31607 11.6017 7 10.8386 7 10.043C7 9.24732 7.31607 8.48426 7.87868 7.92165C8.44129 7.35904 9.20435 7.04297 10 7.04297C10.7956 7.04297 11.5587 7.35904 12.1213 7.92165C12.6839 8.48426 13 9.24732 13 10.043V10.043Z"
                          stroke="#e2e8f0"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Settings
                      </span>
                    </div>
                  </NavLink>
                </li>
                {user?.type === "admin" && (
                  <SidebarLinkGroup
                    activecondition={
                      pathname.includes("users") ||
                      pathname.includes("companies")
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                              pathname.includes("collect-reviews") &&
                              "hover:text-gray-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <svg
                                  className="shrink-0 h-6 w-6"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className={`fill-current text-gray-400`}
                                    cx="18.5"
                                    cy="5.5"
                                    r="4.5"
                                  />
                                  <circle
                                    className={`fill-current text-gray-600`}
                                    cx="5.5"
                                    cy="5.5"
                                    r="4.5"
                                  />
                                  <circle
                                    className={`fill-current text-gray-600`}
                                    cx="18.5"
                                    cy="18.5"
                                    r="4.5"
                                  />
                                  <circle
                                    className={`fill-current text-gray-400`}
                                    cx="5.5"
                                    cy="18.5"
                                    r="4.5"
                                  />
                                </svg>

                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Admin
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-gray-400 ${
                                    open && "transform rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-2 mt-4">
                                <NavLink
                                  exact
                                  to="/users"
                                  className={`block text-gray-400 hover:text-gray-200 transition duration-150 truncate" +
                                ${
                                  pathname.includes("/users") &&
                                  " text-blue-600"
                                }`}
                                  activeClassName="text-blue-500"
                                  onClick={closeSidebar}
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Users
                                  </span>
                                </NavLink>
                              </li>
                              <li className="mb-2">
                                <NavLink
                                  exact
                                  to="/companies"
                                  className={`block text-gray-400 hover:text-gray-200 transition duration-150 truncate" +
                                ${
                                  pathname.includes("/companies") &&
                                  " text-blue-600"
                                }`}
                                  activeClassName="text-blue-500"
                                  onClick={closeSidebar}
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Companies
                                  </span>
                                </NavLink>
                              </li>
                              <li className="mb-2">
                                <NavLink
                                  exact
                                  to="/announcement"
                                  className={`block text-gray-400 hover:text-gray-200 transition duration-150 truncate" +
                                ${
                                  pathname.includes("/announcement") &&
                                  " text-blue-600"
                                }`}
                                  activeClassName="text-blue-500"
                                  onClick={closeSidebar}
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Announcement
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                )}
              </ul>
            </div>
            <div className="h-4"></div>
            <div
              className="truncate sm:mt-auto sm:mb-4 mb-14 h-11 border border-gray-400 rounded-sm px-3 py-2 cursor-pointer flex items-center justify-between"
              aria-controls="change-acc-modal"
              style={{ minHeight: 42, marginBottom: 70 }}
              onClick={(e) => {
                e.stopPropagation();
                setChangeAccOpen(true);
              }}
            >
              <span
                className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                aria-hidden="true"
              >
                ••
              </span>
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block text-gray-200 font-medium truncate">
                {selectCompany?.businessName}
              </div>
              <svg
                className="truncate lg:hidden lg:sidebar-expanded:block 2xl:block"
                width="11"
                height="7"
                viewBox="0 0 11 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.4 6.8L0 1.4L1.4 0L5.4 4L9.4 0L10.8 1.4L5.4 6.8Z"
                  fill="#e2e8f0"
                />
              </svg>
            </div>
          </div>

          {/* Expand / collapse button */}
          <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
            <div className="px-3 py-2">
              <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
                <span className="sr-only">Expand / collapse sidebar</span>
                <svg
                  className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                  viewBox="0 0 24 24"
                >
                  <path
                    className="text-gray-400"
                    d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                  />
                  <path className="text-gray-600" d="M3 23H1V1h2z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <ChangeAccountModal
          changeAccOpen={changeAccOpen}
          setChangeAccOpen={setChangeAccOpen}
          setBusinessModalOpen={setBusinessModalOpen}
        />
        <AddBusinessModal
          businessModalOpen={businessModalOpen}
          setBusinessModalOpen={setBusinessModalOpen}
        />
      </div>
    </>
  );
}

export default Sidebar;
