// import WidgetCard from "components/WidgetConfigurator/WidgetCard";
import { typeObject } from "helpers/getObjectByType";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import checkSVG from "icons/check.svg";
import uncheckSVG from "icons/uncheck.svg";
import desktopLaptopSVG from "icons/desktop-laptop-blue.svg";
import { Link } from "react-router-dom";
import WidgetSlider from "components/WidgetConfigurator/WidgetTypes/Slider/WidgetSlider";
import { predefinedStyles } from "helpers/widgetConfiguratorStyles";
import WidgetHtmlBadge from "components/WidgetConfigurator/WidgetTypes/Badge/WidgetHtmlBadge/WidgetHtmlBagde";
import WidgetGrid from "components/WidgetConfigurator/WidgetTypes/Grid/WidgetGrid";
import WidgetList from "components/WidgetConfigurator/WidgetTypes/List/WidgetList";
import WidgetButton from "components/WidgetConfigurator/WidgetTypes/Button/WidgetButton";
import { setPredefinedWidgets } from "store/actions/widget";
import { getRatingsList } from "store/actions/ratings";

const layoutTypes = [
  "All",
  "Essentials",
  "Collect Reviews",
  "Testimonials",
  // "List",
  // "Button",
  // "Floating",
  // "Popup",
];

const WidgetConfiguratorList = () => {
  const dispatch = useDispatch();
  const [layoutType, setLayoutType] = useState("All");
  const [source, setSource] = useState("All");
  const user = useSelector((state) => state.auth.account);
  const ratings = useSelector((state) => state.ratings.ratings);
  const companies = useSelector((state) => state.auth.selectedCompany);
  const businessId = companies?.id;

  const allPredefined = useSelector((state) => state.widget.predefinedWidgets);

  const setAllPredefined = (val) => {
    dispatch(setPredefinedWidgets(val));
  };

  useEffect(() => {
    if (businessId) {
      dispatch(getRatingsList(businessId));
      // eslint-disable-next-line
    }
  }, []);

  useEffect(() => {
    if (source === "All") {
      setAllPredefined(predefinedStyles);
    } else {
      const newSliders = allPredefined.slider.map((item) => ({
        ...item,
        reviewSources: source,
      }));
      const newGrids = allPredefined.grid.map((item) => ({
        ...item,
        reviewSources: source,
      }));
      const newBadges = allPredefined.badge.map((item) => ({
        ...item,
        reviewSources: source,
      }));

      const newButtons = allPredefined.button.map((item) => ({
        ...item,
        reviewSources: source,
      }));

      const newCollectButtons = allPredefined.collectReviewsButton.map(
        (item) => ({
          ...item,
          reviewSources: source,
        })
      );

      setAllPredefined({
        ...allPredefined,
        slider: newSliders,
        grid: newGrids,
        badge: newBadges,
        button: newButtons,
        collectReviewsButton: newCollectButtons,
      });
    }
    // eslint-disable-next-line
  }, [source]);

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <div className="text-black font-bold text-2xl mb-4">
        Widget Configurator
      </div>
      <div className="lg:hidden text-xl mt-5 text-black flex flex-col items-center text-center px-5">
        <img
          src={desktopLaptopSVG}
          alt="Desktop Laptop"
          className="mb-3 max-w-full"
        />
        For configuring your widget and generating iframe code for it, please
        use your desktop, thanks.
      </div>
      <div className="w-full lg:block hidden bg-white border border-gray-200 py-5 pl-10 xl:pl-14 mb-4">
        <div className="flex items-center mb-3 pb-2 overflow-x-scroll">
          <div className="text-lg font-semibold text-black w-16 min-w-[16] mr-5">
            Source
          </div>
          <div
            className={
              "py-2 px-4 mr-2 bg-blue bg-white border rounded-md cursor-pointer flex items-center " +
              (source === "All" ? "border-blue-600" : "border-gray-300")
            }
            onClick={() => setSource("All")}
            style={{ minWidth: "max-content" }}
          >
            {source === "All" ? (
              <img src={checkSVG} className="mr-2 w-5 h-5" alt="Check" />
            ) : (
              <img src={uncheckSVG} className="mr-2 w-5 h-5" alt="Check" />
            )}
            <p>All</p>
          </div>
          {ratings?.map(
            (item, index) =>
              index > 0 && (
                <div
                  className={
                    "py-2 px-4 mr-2 bg-blue bg-white border w-max rounded-md cursor-pointer flex items-center " +
                    (item?.type === source
                      ? "border-blue-600"
                      : "border-gray-300")
                  }
                  key={index}
                  onClick={() => setSource(item.type)}
                  style={{ minWidth: "max-content" }}
                >
                  {item?.type === source ? (
                    <img src={checkSVG} className="mr-2 w-5 h-5" alt="Check" />
                  ) : (
                    <img
                      src={uncheckSVG}
                      className="mr-2 w-5 h-5"
                      alt="Check"
                    />
                  )}
                  <img
                    className="h-6 mr-2 object-contain"
                    src={typeObject[item.type]?.image}
                    alt={typeObject[item.type]?.title}
                    style={{ maxWidth: 90 }}
                  />
                  {typeObject[item.type]?.title}
                </div>
              )
          )}
        </div>

        <div className="flex items-center mb-2 pb-2 overflow-x-scroll">
          <div className="text-lg font-semibold text-black w-16 mr-5">
            Layout
          </div>
          {layoutTypes.map((item, index) => (
            <div
              className={
                "py-2 px-4 mr-2 bg-blue bg-white border rounded-md cursor-pointer flex items-center shrink-0 " +
                (item === layoutType ? "border-blue-600" : "border-gray-300")
              }
              key={index}
              onClick={() => setLayoutType(item)}
              style={{ minWidth: "max-content" }}
            >
              {item === layoutType ? (
                <img src={checkSVG} className="mr-2 w-5 h-5" alt="Check" />
              ) : (
                <img src={uncheckSVG} className="mr-2 w-5 h-5" alt="Check" />
              )}
              <p>{item}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="lg:block hidden">
        {(layoutType === "Essentials" || layoutType === "All") &&
          allPredefined.badge.map((item, index) => (
            <div
              key={index}
              className="w-full bg-white border border-gray-200 py-5 px-14 mb-7"
            >
              <div className="max-w-7xl mx-auto">
                <div className="flex justify-between items-center pb-3">
                  <div className="text-black">
                    Widget: <span className="font-bold">{item.widgetName}</span>
                  </div>
                  <Link
                    to={`/widget-configurator/badge/${item.widgetType}`}
                    className="btn bg-blue-600 hover:bg-blue-700 transition-all text-white"
                  >
                    Select
                  </Link>
                </div>
                <hr className="mb-5" />

                <WidgetHtmlBadge widgetStyle={item} />
              </div>
            </div>
          ))}

        {(layoutType === "Essentials" || layoutType === "All") &&
          allPredefined.button.map((item, index) => (
            <div
              key={index}
              className="w-full bg-white border border-gray-200 py-5 px-8 xl:px-14 mb-7"
            >
              <div className="max-w-7xl mx-auto">
                <div className="flex justify-between items-center pb-3">
                  <div className="text-black">
                    Widget: <span className="font-bold">{item.widgetName}</span>
                  </div>
                  <Link
                    to={`/widget-configurator/button/${item.widgetType}`}
                    className="btn bg-blue-600 hover:bg-blue-700 transition-all text-white"
                  >
                    Select
                  </Link>
                </div>
                <hr className="mb-5" />

                <WidgetButton widgetStyle={item} />
              </div>
            </div>
          ))}

        {(layoutType === "Collect Reviews" || layoutType === "All") &&
          allPredefined.collectReviewsButton.map((item, index) => (
            <div
              key={index}
              className="w-full bg-white border border-gray-200 py-5 px-8 xl:px-14 mb-7"
            >
              <div className="max-w-7xl mx-auto">
                <div className="flex justify-between items-center pb-3">
                  <div className="text-black">
                    Widget: <span className="font-bold">{item.widgetName}</span>
                  </div>
                  <Link
                    to={`/widget-configurator/collectReviewsButton/${item.widgetType}`}
                    className="btn bg-blue-600 hover:bg-blue-700 transition-all text-white"
                  >
                    Select
                  </Link>
                </div>
                <hr className="mb-5" />

                <WidgetButton widgetStyle={item} />
              </div>
            </div>
          ))}

        {(layoutType === "Testimonials" || layoutType === "All") &&
          allPredefined.slider.map((item, index) => (
            <div
              key={index}
              className="w-full bg-white border border-gray-200 py-5 px-8 xl:px-14 mb-7"
            >
              <div className="max-w-7xl mx-auto">
                <div className="flex justify-between items-center pb-3">
                  <div className="text-black">
                    Widget: <span className="font-bold">{item.widgetName}</span>
                  </div>
                  <Link
                    to={`/widget-configurator/slider/${item.widgetType}`}
                    className="btn bg-blue-600 hover:bg-blue-700 transition-all text-white"
                  >
                    Select
                  </Link>
                </div>
                <hr className="mb-5" />

                <WidgetSlider widgetStyle={item} />
              </div>
            </div>
          ))}

        {(layoutType === "Testimonials" || layoutType === "All") &&
          allPredefined.grid.map((item, index) => (
            <div
              key={index}
              className="w-full bg-white border border-gray-200 py-5 px-8 xl:px-14 mb-7"
            >
              <div className="max-w-7xl mx-auto">
                <div className="flex justify-between items-center pb-3">
                  <div className="text-black">
                    Widget: <span className="font-bold">{item.widgetName}</span>
                  </div>
                  <Link
                    to={`/widget-configurator/grid/${item.widgetType}`}
                    className="btn bg-blue-600 hover:bg-blue-700 transition-all text-white"
                  >
                    Select
                  </Link>
                </div>
                <hr className="mb-5" />

                <WidgetGrid widgetStyle={item} />
              </div>
            </div>
          ))}
      </div>

      {(layoutType === "List" || layoutType === "All") &&
        allPredefined.list.map((item, index) => (
          <div
            key={index}
            className="w-full bg-white border border-gray-200 py-5 px-14 mb-7"
          >
            <div className="max-w-7xl mx-auto">
              <div className="flex justify-between items-center pb-3">
                <div className="text-black">
                  Widget: <span className="font-bold">{item.widgetName}</span>
                </div>
                <Link
                  to={`/widget-configurator/list/${item.widgetType}`}
                  className="btn bg-blue-600 hover:bg-blue-700 transition-all text-white"
                >
                  Select
                </Link>
              </div>
              <hr className="mb-5" />

              <WidgetList widgetStyle={item} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default WidgetConfiguratorList;
