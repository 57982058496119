import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export const calculatePlanPrice = (plan, interval) => {
  if (interval === "month") {
    switch (plan) {
      case "trial":
      case "free":
        return "€ 0";
      case "start":
        return "€ 20";
      case "pro":
        return "€ 40";
      default:
        return "€ 0";
    }
  } else {
    switch (plan) {
      case "trial":
      case "free":
        return "€ 0";
      case "start":
        return "€ 144";
      case "pro":
        return "€ 288";
      default:
        return "€ 0";
    }
  }
};

export const planMessage = (user) => {
  if (user) {
    if (user?.selectedCompany?.subscription?.plan === "trial") {
      return (
        <div className="text-sm">
          You have a <span className="font-bold">free trial</span> of{" "}
          <span className="font-bold">
            {dayjs(user?.selectedCompany?.subscription?.ends).fromNow(true)}{" "}
          </span>
          remaining. Your account will be downgraded to Free on{" "}
          {dayjs(user?.selectedCompany?.subscription?.ends).format(
            "MMM DD, YYYY"
          )}
          . Don’t miss out on anything - upgrade your account to keep access to
          all functions.
        </div>
      );
    } else if (user?.selectedCompany?.subscription?.plan === "free") {
      return (
        <div className="text-sm">
          You have a <span className="font-bold">Free plan. </span>
          Don’t miss out on anything - upgrade your account to keep access to
          all functions.
        </div>
      );
    } else {
      if (
        user?.selectedCompany?.subscription?.plan ===
        user?.selectedCompany?.subscription?.nextPlan
      ) {
        return (
          <div className="text-sm">
            You are on the{" "}
            <span className="capitalize font-bold">
              {user?.selectedCompany?.subscription?.plan}
            </span>{" "}
            plan with a {user?.selectedCompany?.billingInfo?.interval}ly payment
            of{" "}
            <span className="capitalize font-bold">
              {calculatePlanPrice(
                user?.selectedCompany?.subscription?.plan,
                user?.selectedCompany?.billingInfo?.interval
              )}{" "}
              per {user?.selectedCompany?.billingInfo?.interval}
            </span>
            . Your plan is set to renew on{" "}
            {dayjs(user?.selectedCompany?.subscription?.ends).format(
              "MMM DD, YYYY"
            )}
            .
          </div>
        );
      } else {
        return (
          <div className="text-sm">
            You are on the{" "}
            <span className="capitalize font-bold">
              {user?.selectedCompany?.subscription?.plan}
            </span>{" "}
            plan. Your account will be downgraded to{" "}
            <span className="capitalize font-bold">
              {user?.selectedCompany?.subscription?.nextPlan}{" "}
            </span>
            on{" "}
            <span className="font-bold">
              {dayjs(user?.selectedCompany?.subscription?.ends).format(
                "MMM DD, YYYY"
              )}
            </span>{" "}
            with new {user?.selectedCompany?.billingInfo?.nextPlanInterval}ly{" "}
            payment of{" "}
            <span className="font-bold">
              {calculatePlanPrice(
                user?.selectedCompany?.subscription?.nextPlan,
                user?.selectedCompany?.billingInfo?.nextPlanInterval
              )}{" "}
              per {user?.selectedCompany?.billingInfo?.nextPlanInterval}.
            </span>
          </div>
        );
      }
    }
  } else {
    return null;
  }
};

export const appsumoPlanMessage = (availableProCompanies) => {
  switch (availableProCompanies) {
    case "3":
      return (
        <div>
          You have <strong>License Tier 1</strong> lifetime plan.
        </div>
      );
    case "10":
      return (
        <div>
          You have <strong>License Tier 2</strong> lifetime plan.
        </div>
      );
    case "30":
      return (
        <div>
          You have <strong>License Tier 3</strong> lifetime plan.
        </div>
      );
    case "unlimited":
      return (
        <div>
          You have <strong>License Tier 4</strong> lifetime plan.
        </div>
      );
    case "0":
      return (
        <div>
          You have <strong>Free plan</strong>.
        </div>
      );
    default:
      return null;
  }
};
