import React, { useEffect, useState } from "react";
import axios from "config/axios";
import { useDispatch, useSelector } from "react-redux";
import { planMessage } from "helpers/calculatePlanPrice";
import { finishLoading, startLoading } from "store/actions/loader";
import { changePlan } from "store/actions/billing";
import AppsumoPlans from "components/AppsumoPlans/AppsumoPlans";

const checkSVG = () => (
  <svg
    className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2"
    viewBox="0 0 12 12"
  >
    <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
  </svg>
);

const btnType = (user, type, interval) => {
  if (!user) return null;
  if (
    user.selectedCompany?.subscription?.plan === type &&
    !user.selectedCompany?.billingInfo
  ) {
    return "current";
  }
  if (
    user.selectedCompany?.subscription?.plan === type &&
    user.selectedCompany?.billingInfo?.interval === interval
  ) {
    return "current";
  }
  if (
    user.selectedCompany?.subscription?.nextPlan === type &&
    user.selectedCompany?.billingInfo?.nextPlanInterval === `${interval}`
  ) {
    return "next";
  }

  return null;
};

function PlansPanel() {
  const dispatch = useDispatch();
  const [annual, setAnnual] = useState("year");
  const user = useSelector((state) => state.auth.account);

  const payForPlan = async (type, plan) => {
    if (user?.selectedCompany?.subscription?.id) {
      dispatch(changePlan(type, plan));
    } else {
      dispatch(startLoading());
      let subscribeSession;
      try {
        subscribeSession = await axios.post("/company/subscribe-session", {
          type,
          plan,
        });
      } catch {
      } finally {
        dispatch(finishLoading());
      }
      if (subscribeSession) {
        window.location.href = subscribeSession.data.url;
      }
    }
  };

  useEffect(() => {
    if (user) {
      if (user?.selectedCompany?.billingInfo?.interval) {
        setAnnual(user?.selectedCompany?.billingInfo?.interval);
      } else {
        setAnnual("year");
      }
    }
  }, [user]);

  return (
    <div className="grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        {/* Plans */}
        {user && (
          <section>
            {user?.type === "appsumo" ? (
              <>
                <AppsumoPlans />
              </>
            ) : (
              <>
                <div className="mb-8">
                  <h2 className="text-2xl text-gray-800 font-bold mb-4">
                    Plans
                  </h2>
                  {planMessage(user)}
                </div>
                {/* Pricing */}
                <div>
                  {/* Toggle switch */}
                  <div className="flex items-center space-x-3 mb-6">
                    <div className="text-sm text-gray-500 font-medium">
                      Monthly
                    </div>
                    <div className="form-switch">
                      <input
                        type="checkbox"
                        id="toggle"
                        className="sr-only"
                        checked={annual === "year"}
                        onChange={() => {
                          if (annual === "year") {
                            setAnnual("month");
                          } else {
                            setAnnual("year");
                          }
                        }}
                      />
                      <label className="bg-gray-400" htmlFor="toggle">
                        <span
                          className="bg-white shadow-sm"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Pay annually</span>
                      </label>
                    </div>
                    <div className="text-sm text-gray-500 font-medium">
                      Yearly <span className="text-green-500">(-40%)</span>
                    </div>
                  </div>
                  {/* Pricing tabs */}
                  <div className="grid grid-cols-12 gap-6">
                    {/* Tab 2 */}
                    <div className="relative col-span-full xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200">
                      <div
                        className="absolute top-0 left-0 right-0 h-0.5 bg-blue-300"
                        aria-hidden="true"
                      ></div>
                      <div className="px-5 pt-5 pb-6 border-b border-gray-200">
                        <header className="flex items-center mb-2">
                          <h3 className="text-lg text-gray-800 font-semibold">
                            Free
                          </h3>
                        </header>
                        <div className="text-sm mb-2">
                          The free access that never expires.
                        </div>
                        {/* Price */}
                        <div className="text-gray-800 font-bold mb-2">
                          <span className="text-2xl">€</span>
                          <span className="text-3xl">0</span>
                          <span className="text-gray-500 font-medium text-sm">
                            /mo
                          </span>
                        </div>
                        <div className="text-gray-400 text-sm font-medium mb-4">
                          free forever
                        </div>
                        {/* CTA */}
                        <button
                          className={
                            "btn w-full flex items-center" +
                            (btnType(user, "free", annual) === "next" ||
                            btnType(user, "free", annual) === "current" ||
                            user?.selectedCompany?.subscription.plan === "trial"
                              ? " cursor-not-allowed border-gray-200 bg-gray-100 text-gray-400"
                              : " bg-blue-500 hover:bg-blue-600 text-white")
                          }
                          disabled={
                            btnType(user, "free", annual) === "next" ||
                            btnType(user, "free", annual) === "current" ||
                            user?.selectedCompany?.subscription.plan === "trial"
                          }
                          onClick={() =>
                            payForPlan(
                              annual === "year" ? "yearly" : "monthly",
                              "free"
                            )
                          }
                        >
                          {(btnType(user, "free", annual) === "next" ||
                            btnType(user, "free", annual) === "current" ||
                            user?.selectedCompany?.subscription.plan ===
                              "trial") && (
                            <svg
                              className="w-3 h-3 shrink-0 fill-current mr-2"
                              viewBox="0 0 12 12"
                            >
                              <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                            </svg>
                          )}
                          <span>
                            {btnType(user, "free", annual) === "next" &&
                              "Next "}
                            {btnType(user, "free", annual) === "current" &&
                              "Current "}
                            {btnType(user, "free", annual) === null &&
                              "Choose "}
                            Plan
                          </span>
                        </button>
                      </div>
                      <div className="px-5 pt-4 pb-5">
                        <div className="text-xs text-gray-800 font-semibold uppercase mb-4">
                          What's included
                        </div>
                        {/* List */}
                        <ul>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Trustbucket reviews page
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm line-through text-gray-400">
                              Use widgets to display reviews
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm line-through text-gray-400">
                              Connect Google reviews
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm line-through text-gray-400">
                              Collect Google reviews
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Send 3 review invitations / month
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Auto-sync reviews every 30 days
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm line-through text-gray-400">
                              Create custom invitation templates
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm line-through text-gray-400">
                              Connect reviews from other platforms
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm line-through text-gray-400">
                              Collect reviews for other platforms
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="relative col-span-full xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200">
                      <div
                        className="absolute top-0 left-0 right-0 h-0.5 bg-blue-500"
                        aria-hidden="true"
                      ></div>
                      <div className="px-5 pt-5 pb-6 border-b border-gray-200">
                        <header className="flex items-center mb-2">
                          <h3 className="text-lg text-gray-800 font-semibold">
                            Start
                          </h3>
                        </header>
                        <div className="text-sm mb-2">
                          A simple way to start growing.
                        </div>
                        {/* Price */}
                        <div className="text-gray-800 font-bold mb-2">
                          <span className="text-2xl">€</span>
                          <span className="text-3xl">
                            {annual === "year" ? "12" : "20"}
                          </span>
                          <span className="text-gray-500 font-medium text-sm">
                            /mo
                          </span>
                        </div>
                        <div className="text-gray-400 text-sm font-medium mb-4">
                          *billed {annual === "year" ? "yearly" : "monthly"}
                        </div>
                        {/* CTA */}
                        <button
                          className={
                            "btn w-full flex items-center" +
                            (btnType(user, "start", annual) === "next" ||
                            btnType(user, "start", annual) === "current"
                              ? " cursor-not-allowed border-gray-200 bg-gray-100 text-gray-400"
                              : " bg-blue-500 hover:bg-blue-600 text-white")
                          }
                          disabled={
                            btnType(user, "start", annual) === "next" ||
                            btnType(user, "start", annual) === "current"
                          }
                          onClick={() =>
                            payForPlan(
                              annual === "year" ? "yearly" : "monthly",
                              "start"
                            )
                          }
                        >
                          {(btnType(user, "start", annual) === "next" ||
                            btnType(user, "start", annual) === "current") && (
                            <svg
                              className="w-3 h-3 shrink-0 fill-current mr-2"
                              viewBox="0 0 12 12"
                            >
                              <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                            </svg>
                          )}
                          <span>
                            {btnType(user, "start", annual) === "next" &&
                              "Next "}
                            {btnType(user, "start", annual) === "current" &&
                              "Current "}
                            {btnType(user, "start", annual) === null &&
                              "Choose "}
                            Plan
                          </span>
                        </button>
                      </div>
                      <div className="px-5 pt-4 pb-5">
                        <div className="text-xs text-gray-800 font-semibold uppercase mb-4">
                          What's included
                        </div>
                        {/* List */}
                        <ul>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Trustbucket reviews page
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Use widgets to display reviews
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Connect Google reviews
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Collect Google reviews
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Send <strong>300 review invitations</strong> /
                              month
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Auto-sync reviews every 24h
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Create custom invitation templates
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Connect reviews from other platforms
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Collect reviews for other platforms
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* Tab 3 */}
                    <div className="relative col-span-full xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200">
                      <div
                        className="absolute top-0 left-0 right-0 h-0.5 bg-blue-700"
                        aria-hidden="true"
                      ></div>
                      <div className="px-5 pt-5 pb-6 border-b border-gray-200">
                        <header className="flex items-center mb-2">
                          <h3 className="text-lg text-gray-800 font-semibold">
                            Pro
                          </h3>
                        </header>
                        <div className="text-sm mb-2">
                          For the bigger company.
                        </div>
                        {/* Price */}
                        <div className="text-gray-800 font-bold mb-2">
                          <span className="text-2xl">€</span>
                          <span className="text-3xl">
                            {annual === "year" ? "24" : "40"}
                          </span>
                          <span className="text-gray-500 font-medium text-sm">
                            /mo
                          </span>
                        </div>
                        <div className="text-gray-400 text-sm font-medium mb-4">
                          *billed {annual === "year" ? "yearly" : "monthly"}
                        </div>
                        {/* CTA */}
                        <button
                          className={
                            "btn w-full flex items-center" +
                            (btnType(user, "pro", annual) === "next" ||
                            btnType(user, "pro", annual) === "current"
                              ? " cursor-not-allowed border-gray-200 bg-gray-100 text-gray-400"
                              : " bg-blue-500 hover:bg-blue-600 text-white")
                          }
                          disabled={
                            btnType(user, "pro", annual) === "next" ||
                            btnType(user, "pro", annual) === "current"
                          }
                          onClick={() =>
                            payForPlan(
                              annual === "year" ? "yearly" : "monthly",
                              "pro"
                            )
                          }
                        >
                          {(btnType(user, "pro", annual) === "next" ||
                            btnType(user, "pro", annual) === "current") && (
                            <svg
                              className="w-3 h-3 shrink-0 fill-current mr-2"
                              viewBox="0 0 12 12"
                            >
                              <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                            </svg>
                          )}
                          <span>
                            {btnType(user, "pro", annual) === "next" && "Next "}
                            {btnType(user, "pro", annual) === "current" &&
                              "Current "}
                            {btnType(user, "pro", annual) === null && "Choose "}
                            Plan
                          </span>
                        </button>
                      </div>
                      <div className="px-5 pt-4 pb-5">
                        <div className="text-xs text-gray-800 font-semibold uppercase mb-4">
                          What's included
                        </div>
                        {/* List */}
                        <ul>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Trustbucket reviews page
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Use widgets to display reviews
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Connect Google reviews
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Collect Google reviews
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Send <strong>unlimited review invitations</strong>
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Auto-sync reviews every 24h
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Create custom invitation templates
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Connect reviews from other platforms
                            </div>
                          </li>
                          <li className="flex items-center py-1">
                            {checkSVG()}
                            <div className="text-sm">
                              Collect reviews for other platforms
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </>
            )}
          </section>
        )}

        {/* Contact Sales */}
        {/* <section>
          <div className="px-5 py-3 bg-blue-50 border border-blue-100 rounded-sm text-center xl:text-left xl:flex xl:flex-wrap xl:justify-between xl:items-center">
            <div className="text-gray-800 font-semibold mb-2 xl:mb-0">
              Looking for different configurations?
            </div>
            <button className="btn bg-blue-500 hover:bg-blue-600 text-white">
              Contact Sales
            </button>
          </div>
        </section> */}
      </div>
    </div>
  );
}

export default PlansPanel;
