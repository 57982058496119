import axios from "config/axios";
import * as actionTypes from "./actionTypes";
import { finishLoading, startLoading } from "./loader";
import { errorsNotification, successNotification } from "./notification";

export const setEmailTemplates = (payload) => {
  return {
    type: actionTypes.SET_EMAIL_TEMPLATES,
    payload,
  };
};

export const getEmailTemplates = (adminId) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(`/templates/list?id=${adminId}`)
      .then((response) => {
        dispatch(setEmailTemplates(response.data.templates));
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.msg));
      });
  };
};

export const createEmailTemplate = (
  adminId,
  content,
  linkUrl,
  name,
  subject,
  callback
) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`/templates/create`, { adminId, content, linkUrl, name, subject })
      .then((response) => {
        console.log(response);
        dispatch(getEmailTemplates());
        callback && callback();
        dispatch(successNotification(response.data.msg));
        // dispatch(finishLoading());
      })
      .catch((error) => {
        console.log(error);
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response.data.msg));
      });
  };
};

export const updateEmailTemplate = (
  id,
  name,
  subject,
  linkUrl,
  content,
  adminId,
  callback
) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .put(`/templates/update/${id}`, {
        name,
        subject,
        linkUrl,
        content,
        adminId,
      })
      .then((response) => {
        dispatch(getEmailTemplates());
        callback && callback();
        dispatch(successNotification("Successfuly updated!"));
        // dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.msg));
      });
  };
};

export const deleteEmailTemplate = (id, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .delete(`/templates/delete/${id}`)
      .then((response) => {
        dispatch(getEmailTemplates());
        callback && callback();
        dispatch(successNotification("Successfuly deleted!"));
        // dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.msg));
      });
  };
};
