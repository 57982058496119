import React from "react";
import "./FreshaStars.scss";

const FreshaStars = ({ rating }) => {
  return (
    <div className="flex items-center FreshaStars">
      <span className={rating > 0.5 ? "rated" : "not-rated"}></span>
      <span className={rating > 1.5 ? "rated" : "not-rated"}></span>
      <span className={rating > 2.5 ? "rated" : "not-rated"}></span>
      <span className={rating > 3.5 ? "rated" : "not-rated"}></span>
      <span className={rating > 4.5 ? "rated" : "not-rated"}></span>
    </div>
  );
};

export default FreshaStars;
