import React, { Suspense } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import "./css/style.scss";

import "./charts/ChartjsConfig";

// import { Routes } from "routing/Routes";
// import Notifications from "hoc/Notifications/Notifications";
import "react-toastify/dist/ReactToastify.css";
// import { useDispatch } from "react-redux";
// import { getCurrentUser } from "store/actions/auth";
import RoutesHolder from "routing/RoutesHolder";
// import Loader from "hoc/Loader/Loader";
// import ScrollTop from "hoc/ScrollTop/ScrollTop";

export const history = createBrowserHistory();

function App() {
  return (
    <>
      <Suspense
      // fallback={<Loader />}
      >
        {/* {isLoading && <Loader />} */}
        <Router history={history}>
          <RoutesHolder />
        </Router>
      </Suspense>
    </>
  );
}

export default App;
