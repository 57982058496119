import axios from "config/axios";
import { getCurrentUser } from "./auth";
// import * as actionTypes from "./actionTypes";
import { finishLoading, startLoading } from "./loader";
import { errorsNotification, successNotification } from "./notification";

export const changePlan = (type, plan) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`company/change-plan`, { type, plan })
      .then((response) => {
        // dispatch(setCampaignsStats(response.data?.stats));
        dispatch(successNotification(response?.data?.message));
        dispatch(getCurrentUser());
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.message));
      });
  };
};
