import React, { useEffect, useState } from "react";
import logo from "icons/logo-dark.svg";
import { useFormik } from "formik";
import clsx from "clsx";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postWelcome } from "store/actions/auth";
import axios from "config/axios";

const initialValues = {
  companyName: "",
  websiteURL: "",
};
const websiteRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const registrationGoogleSchema = Yup.object().shape({
  companyName: Yup.string().required("Company name is required"),
  websiteURL: Yup.string()
    .required("Company website is required")
    .matches(websiteRegex, "Website URL is not valid"),
});

const registrationSchema = Yup.object().shape({
  companyName: Yup.string().required("Company name is required"),
  websiteURL: Yup.string()
    .required("Company website is required")
    .matches(websiteRegex, "Website URL is not valid"),
  password: Yup.string().required("Password is required"),
});

const registrationAppsumoSchema = Yup.object().shape({
  companyName: Yup.string().required("Company name is required"),
  websiteURL: Yup.string()
    .required("Company website is required")
    .matches(websiteRegex, "Website URL is not valid"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  password: Yup.string().required("Password is required"),
});

export default function Welcome() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [hasCredentials, setHasCredentials] = useState(false);
  const [hasPassword, setHasPassword] = useState(false);
  const [appsumoEmail, setAppsumoEmail] = useState("");

  useEffect(() => {
    if (id) {
      axios.get(`auth/welcome/${id}`).then((response) => {
        setHasPassword(response.data.type !== "google");
        setHasCredentials(response.data.type === "appsumo");
        if (response.data.type === "appsumo") {
          setAppsumoEmail(response.data?.email);
        }
      });
    }
  }, [id]);

  const formik = useFormik({
    initialValues,
    validationSchema: hasPassword
      ? hasCredentials
        ? registrationAppsumoSchema
        : registrationSchema
      : registrationGoogleSchema,
    onSubmit: (values) => {
      dispatch(
        postWelcome({
          ...values,
          password: values.password ? values.password : "google",
          id,
        })
      );
    },
  });

  return (
    <div className="bg-white min-h-screen">
      <div className="px-5 py-10 flex items-center justify-center">
        <img className="w-auto h-5 object-contain" src={logo} alt="Workflow" />
      </div>
      <div className="h-full flex flex-col max-w-full sm:max-w-sm mx-auto justify-center mt-10 px-5">
        <div className="font-medium text-2xl mb-4 md:text-center text-black">
          Welcome to Trustbucket 👋🏻
        </div>
        {hasCredentials ? (
          <div className="h-7"></div>
        ) : (
          <div className="text-gray-600 md:text-center text-sm mb-10">
            Let’s get your free account up and running. You will also get 7 days
            of Pro plan to try for free.
          </div>
        )}
        <div className="pb-10">
          <form noValidate onSubmit={formik.handleSubmit}>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Company name
              </label>
              <div className="mt-2">
                <input
                  id="companyName"
                  name="companyName"
                  type="text"
                  placeholder="Enter your company name"
                  {...formik.getFieldProps("companyName")}
                  className={clsx(
                    "appearance-none block w-full px-4 py-3 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm",
                    {
                      "is-invalid":
                        formik.touched.companyName && formik.errors.companyName,
                    },
                    {
                      "is-valid":
                        formik.touched.companyName &&
                        !formik.errors.companyName,
                    }
                  )}
                />
                {formik.touched.companyName && formik.errors.companyName && (
                  <span className="text-red-600 text-xs">
                    {formik.errors.companyName}
                  </span>
                )}
              </div>
            </div>

            <div className="mt-8">
              <label className="block text-sm font-medium text-gray-700">
                Company website
              </label>
              <div className="mt-2">
                <input
                  id="websiteURL"
                  name="websiteURL"
                  type="text"
                  placeholder="eg. trustbucket.io"
                  {...formik.getFieldProps("websiteURL")}
                  className={clsx(
                    "appearance-none block w-full px-4 py-3 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm",
                    {
                      "is-invalid":
                        formik.touched.websiteURL && formik.errors.websiteURL,
                    },
                    {
                      "is-valid":
                        formik.touched.websiteURL && !formik.errors.websiteURL,
                    }
                  )}
                />
                {formik.touched.websiteURL && formik.errors.websiteURL && (
                  <span className="text-red-600 text-xs">
                    {formik.errors.websiteURL}
                  </span>
                )}
              </div>
            </div>

            {hasCredentials && (
              <>
                <div className="mt-8">
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      type="email"
                      className={clsx(
                        "appearance-none block w-full px-4 py-3 border border-gray-300 rounded shadow-sm bg-gray-200 placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm"
                      )}
                      disabled
                      value={appsumoEmail}
                    />
                  </div>
                </div>
                <div className="mt-8">
                  <label className="block text-sm font-medium text-gray-700">
                    First name
                  </label>
                  <div className="mt-2">
                    <input
                      id="firstName"
                      name="firstName"
                      type="text"
                      placeholder="Enter your first name"
                      {...formik.getFieldProps("firstName")}
                      className={clsx(
                        "appearance-none block w-full px-4 py-3 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm",
                        {
                          "is-invalid":
                            formik.touched.firstName && formik.errors.firstName,
                        },
                        {
                          "is-valid":
                            formik.touched.firstName &&
                            !formik.errors.firstName,
                        }
                      )}
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <span className="text-red-600 text-xs">
                        {formik.errors.firstName}
                      </span>
                    )}
                  </div>
                </div>
                <div className="mt-8">
                  <label className="block text-sm font-medium text-gray-700">
                    Last name
                  </label>
                  <div className="mt-2">
                    <input
                      id="lastName"
                      name="lastName"
                      type="text"
                      placeholder="Enter your last name"
                      {...formik.getFieldProps("lastName")}
                      className={clsx(
                        "appearance-none block w-full px-4 py-3 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm",
                        {
                          "is-invalid":
                            formik.touched.lastName && formik.errors.lastName,
                        },
                        {
                          "is-valid":
                            formik.touched.lastName && !formik.errors.lastName,
                        }
                      )}
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <span className="text-red-600 text-xs">
                        {formik.errors.lastName}
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}

            {hasPassword && (
              <div className="mt-8">
                <label className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Choose a password"
                    {...formik.getFieldProps("password")}
                    className={clsx(
                      "appearance-none block w-full px-4 py-3 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-1 sm:text-sm",
                      {
                        "is-invalid":
                          formik.touched.password && formik.errors.password,
                      },
                      {
                        "is-valid":
                          formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <span className="text-red-600 text-xs">
                      {formik.errors.password}
                    </span>
                  )}
                </div>
              </div>
            )}

            <button
              type="submit"
              className="w-full mt-8 flex justify-center py-3 px-4 border border-transparent rounded shadow-sm transition-colors font-medium text-white bg-blue-600 hover:bg-blue-700"
            >
              Start using Trustbucket
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
