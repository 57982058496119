import CompaniesTable from "components/CompaniesTable/CompaniesTable";
import CustomPagination from "components/Pagination/CustomPagination";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompaniesFilter } from "store/actions/users";

// TODO
let pageSize = 20;

export default function Companies() {
  const dispatch = useDispatch();

  const companies = useSelector((state) => state.users.companies);
  const companiesTotal = useSelector((state) => state.users.companiesTotal);
  const companiesLastPage = useSelector(
    (state) => state.users.companiesLastPage
  );
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  React.useEffect(() => {
    setLoading(true);
    scrollTop();
    dispatch(
      getCompaniesFilter(
        {
          pageSize,
          pageNumber: page,
          sortField: "createdAt",
          sortOrder: "desc",
        },
        () => setLoading(false)
      )
    );
    // eslint-disable-next-line
  }, [page]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Title */}
        <div className="mb-4 sm:mb-0">
          <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
            Companies ✨
          </h1>
        </div>

        {/* Right: Actions */}
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          {/* Delete button */}
          {/* <DeleteButton selectedItems={selectedItems} />
          <DateSelect />
          <FilterButton /> */}
          {/* Add customer button */}
          {/* <button className="btn bg-blue-500 hover:bg-blue-600 text-white">
            <svg
              className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
              viewBox="0 0 16 16"
            >
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">Add Order</span>
          </button> */}
        </div>
      </div>

      {/* Table */}
      {/* <UsersTable total={usersTotal} loading={loading} users={users} /> */}
      <CompaniesTable
        total={companiesTotal}
        loading={loading}
        companies={companies}
      />

      {/* Pagination */}
      <div className="mt-8">
        {!loading && companies.length > 0 && (
          <CustomPagination
            lastPage={companiesLastPage}
            page={page}
            setPage={setPage}
            total={companiesTotal}
            pageSize={pageSize}
          />
        )}
      </div>
    </div>
  );
}
