import { combineReducers } from "redux";

// reducers
import loader from "./loader";
import auth from "./auth";
import notification from "./notification";
import google from "./google";
import platform from "./platform";
import ratings from "./ratings";
import stats from "./stats";
import emailTemplates from "./emailTemplates";
import campaign from "./campaign";
import logs from "./logs";
import widget from "./widget";
import users from "./users";
import upgradePopup from "./upgradePopup";
import announcement from "./announcement";
import tags from "./tags";

export const rootReducer = combineReducers({
  loader,
  auth,
  notification,
  google,
  platform,
  ratings,
  stats,
  emailTemplates,
  campaign,
  logs,
  widget,
  users,
  upgradePopup,
  announcement,
  tags,
});
