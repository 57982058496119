import dayjs from "dayjs";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import relativeTime from "dayjs/plugin/relativeTime";
import { getLogsFilter } from "store/actions/logs";
import CustomPagination from "components/Pagination/CustomPagination";
import ContentLoader from "components/Shared/ContentLoader";

let pageSize = 30;

dayjs.extend(relativeTime);

function LogsPanel() {
  const dispatch = useDispatch();
  const logs = useSelector((state) => state.logs.logs);
  const logsTotal = useSelector((state) => state.logs.logsTotal);
  const logsLastPage = useSelector((state) => state.logs.logsLastPage);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  React.useEffect(() => {
    setLoading(true);
    scrollTop();
    dispatch(
      getLogsFilter(
        {
          pageSize,
          pageNumber: page,
          sortField: "createdAt",
          sortOrder: "desc",
        },
        () => setLoading(false)
      )
    );
    // eslint-disable-next-line
  }, [page]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const statusColor = (status) => {
    const statusFirstNum = Math.floor(status / 100);
    switch (statusFirstNum) {
      case 2:
        return "bg-green-100 text-green-600";
      case 4:
        return "bg-yellow-100 text-yellow-600";
      case 5:
        return "bg-red-100 text-red-500";
      case 3:
        return "bg-light-blue-100 text-light-blue-700";
      default:
        return "bg-gray-100 text-gray-500";
    }
  };

  const sufixStatus = (status) => {
    const statusFirstNum = Math.floor(status / 100);
    if (statusFirstNum === 2) {
      return "OK";
    } else if (statusFirstNum === 5) {
      return "ERR";
    } else if (statusFirstNum === 4) {
      return "WRN";
    } else if (statusFirstNum === 3) {
      return "RED";
    } else return "UNK";
  };

  return (
    <div className="grow relative">
      {/* Panel body */}
      <div className="space-y-6">
        <div className="px-6 pt-6">
          <h2 className="text-2xl text-gray-800 font-bold mb-4">Logs</h2>
        </div>
        <div className="overflow-x-auto">
          {loading && <ContentLoader />}
          {!loading && logs?.length && (
            <table className="table-auto w-full divide-y divide-gray-200">
              {/* Table header */}
              <thead className="text-xs uppercase text-gray-500 bg-gray-50 border-t border-gray-200">
                <tr>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">STATUS</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">TYPE</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">IP ADDRESS</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">AUTHENTICATED</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">STATUS</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="px-6">
                {logs.map((log, index) => (
                  <tr key={index}>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div
                        className={`inline-flex text-sm font-medium rounded-full text-center px-2.5 py-0.5 ${statusColor(
                          log.status
                        )}`}
                      >
                        {log.status} {sufixStatus(log.status)}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className={`font-medium`}>
                        {log.method} {log.endpoint}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div
                        className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 `}
                      >
                        {log.ip}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-gray-800">
                        {log.authenticated ? "Yes" : "No"}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div>{dayjs(log.createdAt).fromNow()}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div className="p-6">
            {!loading && logs.length > 0 && (
              <CustomPagination
                lastPage={logsLastPage}
                page={page}
                setPage={setPage}
                total={logsTotal}
                pageSize={pageSize}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogsPanel;
