import axios from "config/axios";
import * as actionTypes from "./actionTypes";
import { finishLoading, startLoading } from "./loader";
import { errorsNotification, successNotification } from "./notification";

export const setCampaignsStats = (payload) => {
  return {
    type: actionTypes.SET_CAMPAIGNS_STATS,
    payload,
  };
};

export const getCampaignsStats = (adminId) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(`/campaigns/list?id=${adminId}`)
      .then((response) => {
        dispatch(setCampaignsStats(response.data.campaigns));
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.message));
      });
  };
};

export const createCampaign = (
  name,
  templateId,
  reminder,
  invitations,
  adminId,
  businessId,
  callback
) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`/campaigns/create?n=${name}`, {
        templateId,
        reminder,
        invitations,
        adminId,
        businessId,
      })
      .then((response) => {
        dispatch(getCampaignsStats());
        dispatch(finishLoading());
        dispatch(successNotification(response.data.msg));
        callback && callback();
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data.msg));
      });
  };
};

export const setInvitationSettings = (payload) => {
  return {
    type: actionTypes.SET_INVITATION_SETTINGS,
    payload,
  };
};

export const getInvitationSettings = (businessId) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(`/business/invitation-settings?id=${businessId}`)
      .then((response) => {
        const invitationSettings = response.data;
        dispatch(setInvitationSettings(invitationSettings));
        // dispatch(setInvitationSettings(response.data));
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.message));
      });
  };
};

export const updateInvitationSettings = (businessId, bodyFormData) => {
  for (let [key, value] of bodyFormData.entries()) {
  }
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .put(
        `/business/invitation-settings/update?id=${businessId}`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        dispatch(setInvitationSettings(response.data.data));
        dispatch(finishLoading());
        dispatch(successNotification("Successfuly updated!"));
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.msg));
      });
  };
};

export const setSentInvitations = (payload) => {
  return {
    type: actionTypes.SET_INVITATION_SENT,
    payload,
  };
};

export const getSentInvitations = (adminId) => {
  return (dispatch) => {
    axios
      .get(`/campaigns/list?id=${adminId}`)
      .then((response) => {
        dispatch(setSentInvitations(response.data.campaigns));
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.msg));
      });
  };
};

export const setAutomaticCollectionSettings = (payload) => {
  return {
    type: actionTypes.SET_AUTOMATIC_COLLECTION_SETTINGS,
    payload,
  };
};

export const getAutomaticCollectionSettings = (adminId) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(`/automaticreviews/list?id=${adminId}`)
      .then((response) => {
        dispatch(setAutomaticCollectionSettings(response.data.collections));
        dispatch(finishLoading());
        dispatch(successNotification(response?.data?.message));
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.msg));
      });
  };
};

export const createAutomaticCollection = (
  template,
  reminder,
  delay,
  adminId,
  callback
) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`/automaticreviews/create`, { template, reminder, delay, adminId })
      .then((response) => {
        dispatch(getAutomaticCollectionSettings());
        dispatch(finishLoading());
        dispatch(successNotification(response?.data?.msg));
        callback && callback();
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.msg));
      });
  };
};

export const setCampaignRecievers = (payload) => {
  return {
    type: actionTypes.SET_CAMPAIGN_RECIEVERS,
    payload,
  };
};

export const getCampaignRecievers = (adminId) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(`/campaigns/list?id=${adminId}`)
      .then((response) => {
        dispatch(setCampaignRecievers(response.data.campaigns));
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.msg));
      });
  };
};

export const setEmailPreviewContent = (payload) => {
  return {
    type: actionTypes.SET_EMAIL_PREVIEW_CONTENT,
    payload,
  };
};
