import SingleTemplate from "components/SingleTemplate/SingleTemplate";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getEmailTemplates } from "store/actions/emailTemplates";
import lockMiniSVG from "icons/lock-mini.svg";
import { setUpgradePopup } from "store/actions/upgradePopup";

const TemplateEditor = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loader);
  const templates = useSelector((state) => state.emailTemplates.emailTemplates);
  const user = useSelector((state) => state.auth.account);
  const adminId = localStorage.getItem("id");

  useEffect(() => {
    dispatch(getEmailTemplates(adminId));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-10 py-5 sm:py-8 w-full bg-white">
      <div className="flex sm:flex-row flex-row items-center justify-between mb-4 border-b border-gray-200 pb-5 sm:pb-8">
        <div className="text-black font-bold text-xl sm:text-3xl">
          Template editor
        </div>
        {/* TODO CHANGE */}
        {user?.selectedCompany?.subscription?.plan === "free" &&
        user?.type !== "appsumo" ? (
          <div
            onClick={() => dispatch(setUpgradePopup(true))}
            className="btn bg-blue-500 hover:bg-blue-600 cursor-pointer transition-all flex items-center w-max sm:mt-0 mt-2 text-white"
          >
            <img src={lockMiniSVG} alt="lock" className="mr-2" />
            Create new template
          </div>
        ) : (
          <Link
            to="/template-editor/new-template"
            className="btn bg-blue-500 hover:bg-blue-600 transition-all w-max sm:mt-0 mt-2 text-white block"
          >
            Create new template
          </Link>
        )}
      </div>
      {!loading && (
        <div className="mt-8">
          {templates.length > 0 ? (
            <>
              <div className="font-bold text-lg sm:text-xl text-black mb-6">
                Select review invitation template
              </div>
              {templates.map((item, index) => (
                <SingleTemplate data={item} key={index} />
              ))}
            </>
          ) : (
            <div className="font-bold text-lg italic text-gray-800 mb-6">
              You have no templates created...
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TemplateEditor;
