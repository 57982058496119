import React from "react";
import { Link } from "react-router-dom";

const OverviewCard = ({ title, description, number, linkUrl, linkText }) => {
  return (
    <div className="flex flex-col col-span-full px-5 py-6 text-black sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <div className="flex justify-between items-center">
        <div>
          <div className="text-xl mb-1 font-semibold">{title}</div>
          <div className="text-sm text-gray-500 italic">{description}</div>
        </div>
        <div className="text-5xl font-extrabold">{number}</div>
      </div>
      <Link className="text-blue-600 font-semibold text-sm mt-5" to={linkUrl}>
        {linkText}
      </Link>
    </div>
  );
};

export default OverviewCard;
